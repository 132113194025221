import type { CSSProperties } from "react";
import cx from "classnames";

import Play from "./icons/Play";
import Lock from "./icons/Lock";

import styles from "./styles.module.scss";
import { useAppSelector } from "../../../../../hooks/reduxHooks";
import { useLocation } from "react-router-dom";

type PadlockProps = {
  isUnlock?: boolean;
  isRight?: boolean
}

const Padlock = ({ isUnlock, isRight }: PadlockProps) => {
  const location = useLocation();
  const { pathname } = location;
  const firstVoted = useAppSelector((state) => state.player.firstVoted);
  return (
    <>
      {
        !firstVoted || !localStorage.getItem('isAuth') ?
          <div className={cx(styles.padlock, { [styles['padlock_is-unlock']]: isUnlock, [styles['padlock_is-right']]: isRight })}>
            <div>
              <div className={styles['padlock__play']}>
                <Play />
              </div>
              <div className={styles['padlock__lock']}>
                <div className={cx(styles['padlock__lock-down'], { [styles['padlock__lock-down_is-down']]: isUnlock })}>
                  <Lock />
                </div>
              </div>
            </div>
          </div>
          :
          <>
            {
              location.pathname === "/hall-of-fame" ?
                <></>
                :
                <div className={cx(styles.notVoted)}>
                  <div>
                    <div className={styles['notVoted__play']}>
                      <Play />
                    </div>
                  </div>
                </div>

            }
          </>
      }
    </>
  );
}

export default Padlock;
