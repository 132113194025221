export type StateType = {
    loading: boolean;
    
    playing: boolean;
    muted: boolean;
    pip: boolean;
    ready: boolean;
    
    actionsBarVisible: boolean;
    volumeVisible: boolean;
    
    duration: number;
    played: number;
    seek: number;
    volume: number;
    playbackRate: number;
    quality: number;
}

export const enum BooleanActionKind {
    LOADING = 'LOADING',
    PLAYING = 'PLAYING',
    MUTED = 'MUTED',
    PIP = 'PIP',
    READY = 'READY',
    ACTIONS_BAR_VISIBLE = 'ACTIONS_BAR_VISIBLE',
    VOLUME_VISIBLE = 'VOLUME_VISIBLE',
}

export const enum NumberActionKind {
    DURATION = 'DURATION',
    PLAYED = 'PLAYED',
    SEEK = 'SEEK',
    VOLUME = 'VOLUME',
    PLAYBACK_RATE = 'PLAYBACK_RATE',
    QUALITY = 'QUALITY'
}

export type BooleanActionType = {
    type: BooleanActionKind;
    payload?: boolean;
}

type NumberActionType = {
    type: NumberActionKind
    payload: number
}

export type ActionType = BooleanActionType | NumberActionType