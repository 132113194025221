import { ProfileLayout } from '../ProfileLayout/ProfileLayout';

import { Button, Col, Form, Input, Row } from 'antd';
import DetailsProfile from '../Profile/DetailsProfile';
import { useAppSelector } from '../../hooks/reduxHooks';

import s from './ContactUs.module.scss';
import { ChangeEvent, useEffect, useState } from 'react';
import { contactAPI } from '../../api/api';
import { toast } from 'react-toastify';
import { NotificationT } from '../ToastifyNot/NotificationToastify';
import { useMediaQuery } from 'react-responsive';


interface IValue {
  message: string;
}

const ContactUs = () => {

  const [text, setText] = useState<string>("")
  const [disabled, setDisabled] = useState<boolean>(true)

  const [form] = Form.useForm();

  const { nickname, player_status, giver_status, sponsor_status } = useAppSelector((state) => state.player);
  function sendSupport(value: IValue) {
    setDisabled(true)
    contactAPI
      .contactSupport(value.message)
      .then((res) => {
        toast.success('Success');
        form.resetFields();
      })
      .catch((error) => {
        toast.error("Something wrong");
      });
  }

  const sendMessage = (value: any) => {
    sendSupport(value)
    setDisabled(true)
  }

  useEffect(() => {
    if (text.length === 0) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [text.length, setText])

  const isDesktop = useMediaQuery({
    query: "(min-width: 992px)",
  });

  const status = (status: any) => {

		if (status === "bronze") {
			return (<span style={{ color: "#D2B48C" }}>Bronze</span>)
		} else if (status === "silver") {
			return (<span style={{ color: "#C0C0C0" }}>Silver</span>)
		} else if (status === "gold") {
			return (<span style={{ color: "#FFD700" }}>Gold</span>)
		} else if (status === "diamond") {
			return (<span style={{ color: "#7851A9" }}>Diamond</span>)
		}
	}

  return (
    <ProfileLayout>
      <NotificationT />
      <Row gutter={[20, 20]}>
        <Col span={24}>
        {

isDesktop ?

  <div>
    <h2 className={s.accountName}>{nickname}</h2>
    {player_status ? <h3>{status(player_status)} Player</h3> : <></>}
    {sponsor_status ? <h3>{status(sponsor_status)} Sponsor</h3> : <></>}
    {giver_status ? <h3>{status(giver_status)} Producer</h3> : <></>}
  </div> : <></>
}

        </Col>
        <Col span={24}>
          <DetailsProfile />
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col xs={24} lg={12} style={{ marginTop: 40 }}>
          <Form
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="vertical"
            onFinish={sendMessage}
          >

            <Form.Item
              required
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
              name={'message'}
            >
              <Input.TextArea
                showCount
                maxLength={256}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) => setText(event.target.value)}
                style={{ height: 150, resize: 'none' }}
                value={text}
                allowClear
              />
            </Form.Item>

            <Form.Item wrapperCol={{ span: 24 }}>
              <Row gutter={[20, 20]} justify={"center"}>
                <Col>
                  <Button type="default" htmlType="submit" disabled={disabled}>
                    Send
                  </Button>
                </Col>
              </Row>
            </Form.Item>

          </Form>
        </Col>
      </Row>
    </ProfileLayout>
  )
};

export default ContactUs;
