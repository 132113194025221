import React, { useEffect, useState } from "react";
import styles from './StockModel.module.scss'
import { profileAPI, stockApi } from "../../../api/api";
import { Button, Col, Input, InputNumber, Popover, Row, notification } from "antd";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import { setUserData } from "../../../store/playerSlice";
import {sprintf} from 'sprintf-js'

const StockModel = (id) => {
 const regex = /\D*(\d+)/;
 const finId = id.id.replace(regex, '$1');
 const [amount, setAmount] = useState(null);
 const dispatch = useAppDispatch();

 const handleChange = (value) => {
  setAmount(value);
 };

 const [data, setData] = useState<any>()
 const [isLoading, setLoading] = useState(true)
 const [openPopIndex, setOpenPopIndex] = useState(null);
 const handlePopoverOpenChange = (index, open) => {
  setOpenPopIndex(open ? index : null);
 };

 const mock = {
  id: 1,
  name: "Amy Winehouse",
  stock: {
   current: 10.3,
   change: +0.34,
   percentage: +3
  },
  fanbase: 2032404,
  engagement: 3.2,
  global: 1000,
  image: "https://hips.hearstapps.com/hmg-prod/images/amy-winehouse-662f6e6678bdf.jpg?crop=0.822xw:0.548xh;0.178xw,0.142xh&resize=980:*"
 }
 useEffect(() => {
  stockApi
   .getStockById(finId)
   .then((res) => {
    setData(res?.data?.data)
   })
   .then(() => {
    setLoading(false)
   })

 }, [])

 console.log('---', data)

 const Participate = () => {
  const elements = [];

  if (data.participate_in_games.crowdfunding === 1) {
    elements.push(<h4 key="crowdfunding">PRIVATE FILM (INVEST PRODUCTION)</h4>);
  }
  if (data.participate_in_games.lucky_spin === 1) {
    elements.push(<h4 key="lucky_spin">LUCKY SPIN</h4>);
  }
  // if (data.participate_in_games.superwet === 1) {
  //   elements.push(<h4 key="superwet">SUPERWET</h4>);
  // }
  if (data.participate_in_games.premium_spin === 1) {
    elements.push(<h4 key="premium_spin">PREMIUM SPIN</h4>);
  }

  return <div>{elements}</div>;
};


 const changes = (percentage: any, change: any, current) => {
  if (change < 0) {
   return (
    <span>{current} <span style={{ color: "#BC1632" }}>-{sprintf('%.2f', change)}tkn. ({sprintf('%.2f',percentage)}%)</span></span>
   )
  }
  else {
   return (
    <span>{current} <span style={{ color: "#23418B" }}>+{sprintf('%.2f', change)}tkn. ({sprintf('%.2f',percentage)}%)</span></span>
   )
  }
 }

 if (isLoading) {
  return <></>
 }

 const handleBuyStocks = (quantity) => {
  stockApi
   .buyStocks(finId, quantity)
   .then((res) => {
    notification.success({ message: "Success" })
   })
   .then((res) => {
    stockApi
     .getStockById(finId)
     .then((res) => {
      setData(res?.data?.data)
     })
   })
   .then((res) => {
    profileAPI
     .getProfile()
     .then((res) => {
      dispatch(
       setUserData({
        first_name: res.first_name,
        last_name: res.last_name,
        nickname: res.nickname,
        email: res.email,
        gender: res.gender,
        phone: res.phone,
        id: res.id,
        balance: res.amount,
        avatar: res?.avatar,
        first_voted: res?.data?.first_voted,
        income: res?.transaction_sums.income,
        expense: res?.transaction_sums.expense,
        transactions: res?.videobet_transactions,
        stocks: res?.stocks

       })
      )
     });
   })
 }

 return (
  <div className={styles.wrapper}>

   <div>
    <h3 className={styles.name}>{data.name}</h3>
    
    <div className={styles.content_wrapper}>
     <div className={styles.wrappers}>
      <div className={styles.image_wrapper}>
       <img src={data.image} alt={data.name} className={styles.avatar} />
      </div>
      {/* <div className={styles.index}>
       <p>{data.id}</p>
      </div> */}
     </div>
     <div className={styles.info}>
      <p className={styles.info_stock_mock}><strong>Stock Value: </strong>{changes(data.stock.percentage, data.stock.change, data.stock.current)}</p>
      <p className={styles.info_stock_mock}><strong>Fan Base: </strong>{data.fan_base}</p>
      <p className={styles.info_stock_mock}><strong>Engagement Avg Rank: </strong>{data.avg_rate}</p>
      <p className={styles.info_stock_mock}><strong>Global Rank: </strong>{mock.global}</p>

      <div className={styles.more_info}>
       {Participate()}
      </div>
      <div className={styles.more_info}>
       <h4>ENERGY (ADVERTISING FUTURES)</h4>
       {data.post_price_instagram ? <h4>INSTAGRAM POST: ${data.post_price_instagram}</h4> : <></>}
       {data.post_price_twitter ? <h4>TWITTER POST: ${data.post_price_twitter}</h4> : <></>}
      </div>
      <div className={styles.more_info}>
       <h4>FAN BASE</h4>
       <h4>Instagram</h4>
       <h4>Telegram Channel</h4>
       <h4>Onlyfans</h4>
      </div>
     </div>
    </div>

   </div>
   <Popover

    style={{ padding: 0, height: 'min-content', width: "min-content" }}
    content={
     <Row className={styles.popover} justify={"center"} gutter={[20, 20]}>
      <Col span={24}>
       <span style={{ width: 'fit-content' }}>Stock Left: {data.stock_left}</span>
      </Col>
      <Col span={24}>
       <span style={{ width: 'fit-content' }}>Sell Price: {data.sell_price}</span>
      </Col>
      <Col span={24}>
       <InputNumber
        onChange={handleChange}
        min={1}
        style={{ width: "100%" }}
        placeholder="Amount" />
      </Col>
      <Col span={24}>
       <Button onClick={() => handleBuyStocks(amount)} className={styles.buy_btn} type="primary">
        Buy
       </Button>
      </Col>
     </Row>
    }
    title={"Buy"}
    trigger="click"
   >
    <Button className={styles.invest_btn}>INVEST</Button>
   </Popover>
  </div>
 )
}

export default StockModel;
