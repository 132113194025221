import React, { useEffect, useState } from "react";
import styles from './Stock.module.scss';
import { Button, Col, Input, InputNumber, Popover, Row, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { profileAPI, stockApi } from "../../api/api";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { setUserData } from "../../store/playerSlice";
import {sprintf} from 'sprintf-js'

const Stock = () => {
      const [data, setData] = useState<any>()
      const [isLoading, setLoading] = useState(true)
      const [openPopIndex, setOpenPopIndex] = useState(null);
      const dispatch = useAppDispatch();
      const navigate = useNavigate();
      const [amount, setAmount] = useState(null);
      const handleChange = (value) => {
            setAmount(value);
      };


      useEffect(() => {
            stockApi
                  .getStocks()
                  .then((res) => {
                        setData(res?.data?.data)
                  })
                  .then(() => {
                        setLoading(false)
                  })

      }, [])

      if (isLoading) {
            return <></>
      }

      console.log(data)

      const changes = (percentage: any, change: any, current) => {
            if (change < 0) {
             return (
              <span>{current} <span style={{ color: "#BC1632" }}>-{sprintf('%.2f', change)}tkn. ({sprintf('%.2f',percentage)}%)</span></span>
             )
            }
            else {
             return (
              <span>{current} <span style={{ color: "#23418B" }}>+{sprintf('%.2f', change)}tkn. ({sprintf('%.2f',percentage)}%)</span></span>
             )
            }
           }

      const handlePopoverOpenChange = (index, open) => {
            setOpenPopIndex(open ? index : null);
      };

      const handleBuyStocks = (id, quantity) => {
            stockApi
                  .buyStocks(id, quantity)
                  .then((res) => {
                        notification.success({ message: "Success" })
                  })
                  .then((res) => {
                        stockApi
                              .getStocks()
                              .then((res) => {
                                    setData(res?.data?.data)
                              })
                  })
                  .then((res) => {
                        profileAPI
                              .getProfile()
                              .then((res) => {
                                    dispatch(
                                          setUserData({
                                                first_name: res.first_name,
                                                last_name: res.last_name,
                                                nickname: res.nickname,
                                                email: res.email,
                                                gender: res.gender,
                                                phone: res.phone,
                                                id: res.id,
                                                balance: res.amount,
                                                avatar: res?.avatar,
                                                first_voted: res?.data?.first_voted,
                                                income: res?.transaction_sums.income,
                                                expense: res?.transaction_sums.expense,
                                                transactions: res?.videobet_transactions,
                                                stocks: res?.stocks
                                          })
                                    )
                              });
                  })
                  .catch(() => {
                        notification.error({message: "Uh! Something is wrong"})
                  })
      }

      return (
            <>
                  {data.map((item, index) => (
                        <div key={index} className={styles.content_container}>
                              <a onClick={() => navigate(`/stock-exchange?id=${item.id}`)}>


                                    <div className={styles.img_info}>
                                          <div className={styles.wrapper}>
                                                <div className={styles.image_wrapper}>

                                                      <img className={styles.avatar} src={item.image} alt="avatar" />
                                                </div>
                                                <div className={styles.index}>
                                                      <p>{index + 1}</p>
                                                </div>
                                          </div>

                                          <div className={styles.info_stock}>
                                                <h3>{item.name}</h3>
                                                <p className={styles.info_stock_item}><strong>Stock Value: </strong>{changes(item.stock.percentage, item.stock.change, item.stock.current)}</p>
                                                <p className={styles.info_stock_item}><strong>Fan Base: </strong>{item.fan_base}</p>
                                                <p className={styles.info_stock_item}><strong>Engagement Avg Rank: </strong>{item.avg_rate}</p>
                                                {/* <p className={styles.info_stock_item}><strong>Global Rank: </strong>{item.global}</p> */}
                                          </div>
                                    </div>
                              </a>
                              <div>
                                    <Popover

                                          style={{ padding: 0, height: 'min-content', width: "min-content" }}
                                          content={
                                                <Row className={styles.popover} justify={"center"} gutter={[20, 20]}>
                                                      <Col span={24}>
                                                            <span style={{ width: 'fit-content' }}>Stock Left: {item.stock_left}</span>
                                                      </Col>
                                                      <Col span={24}>
                                                            <span style={{ width: 'fit-content' }}>Sell Price: {item.sell_price}</span>
                                                      </Col>
                                                      <Col span={24}>
                                                            <InputNumber
                                                                  style={{ width: "100%" }}
                                                                  min={1}
                                                                  max={item.stock_left}
                                                                  onChange={handleChange}

                                                                  placeholder="Amount" />
                                                      </Col>
                                                      <Col span={24}>
                                                            <Button onClick={() => handleBuyStocks(item.id, amount)} className={styles.buy_btn} type="primary">
                                                                  Buy
                                                            </Button>
                                                      </Col>
                                                </Row>
                                          }
                                          title={"Buy"}
                                          trigger="click"
                                          open={openPopIndex === index}
                                          onOpenChange={(open) => handlePopoverOpenChange(index, open)}
                                    // getPopupContainer={getPopupContainer ?? null}
                                    >
                                          <Button className={styles.invest_btn}>INVEST</Button>
                                    </Popover>
                              </div>
                        </div>
                  ))}
            </>
      );
};

export default Stock;
