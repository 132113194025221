import { useEffect, useState } from 'react';

import { statisticsAPI } from '../../api/api';
import { HeaderGeneral } from '../../components/HeaderGeneral/HeaderGeneral';
import { TopWinnersList } from '../../components/TopWinnersList/TopWinnersList';
import { TopPlayers } from '../../components/WinnersPages/TopPlayers/TopPlayers';

import styled from './Winners.module.scss';
import { ProfileLayout } from '../../components/ProfileLayout/ProfileLayout';
import { Button, Col, Row } from 'antd';
import DetailsProfile from '../../components/Profile/DetailsProfile';
import { useAppSelector } from '../../hooks/reduxHooks';
import TopProducers from '../../components/WinnersPages/TopProducers/TopProducers';
import TopSponsors from '../../components/WinnersPages/TopSponsors/TopSponsors';
import { useMediaQuery } from 'react-responsive';
import { HeaderTournament } from '../../components/HeaderTournament/HeaderTournament';

const tops = [
  { title: 'Battle results' },
  { title: 'Top Players' },
  { title: "Top Producers" },
  { title: "Top Sponsors" }
  // { title: 'Top Players' },
];

const Winners = ({ onlyResults }: { onlyResults?: boolean }) => {

  const [activeBtn, setActiveBtn] = useState(0);
  const [userStatistic, setUserStatistic] = useState<any>([]);



  const [per, setPer] = useState<number>(3)
  const [page, setPage] = useState<number>(1)
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);

  const { firstName, lastName, nickname, sponsor_status, giver_status, player_status } = useAppSelector((state) => state.player);

  function toggleTop(index: number): void {
    setActiveBtn(index);

  }

  const loadMoreData = (per: number, page: number) => {

    if (loading) {
      return;
    }

    setPage(page + 1)
    setLoading(true);

    statisticsAPI
      .getTopNFTStatistic(onlyResults, per, page)
      .then((body) => {
        setCount(body.count)
        setUserStatistic([...userStatistic, ...body.data.battles]);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  const isDesktop = useMediaQuery({
    query: "(min-width: 992px)",
  });

  useEffect(() => {

    loadMoreData(per, page);
  }, [page, per]);

  const status = (status: any) => {

    if (status === "bronze") {
      return (<span style={{ color: "#D2B48C" }}>Bronze</span>)
    } else if (status === "silver") {
      return (<span style={{ color: "#C0C0C0" }}>Silver</span>)
    } else if (status === "gold") {
      return (<span style={{ color: "#FFD700" }}>Gold</span>)
    } else if (status === "diamond") {
      return (<span style={{ color: "#7851A9" }}>Diamond</span>)
    }
  }

  if (onlyResults) {

    return (
      <ProfileLayout>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            {/* <h2 className={styled.accountName}>{firstName} {lastName}</h2> */}
            {

              isDesktop ?

                <div>
                  <h2 className={styled.accountName}>{nickname}</h2>
                  {player_status ? <h3>{status(player_status)} Player</h3> : <></>}
                  {sponsor_status ? <h3>{status(sponsor_status)} Sponsor</h3> : <></>}
                  {giver_status ? <h3>{status(giver_status)} Producer</h3> : <></>}
                </div> : <></>
            }

          </Col>
          <Col span={24}>
            <DetailsProfile />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className={styled.container}>
              {activeBtn === 0 && (
                <TopWinnersList
                  loadMoreData={loadMoreData}
                  per={per}
                  page={page}
                  setPage={setPage}
                  count={count}
                  userStatistic={userStatistic}
                  coverStyle={{ height: 100, width: 200, fontSize: 15 }}
                  isArchive={false}
                />
              )}
            </div>
          </Col>
        </Row>
      </ProfileLayout>
    )
  }

  return (
    <>
      <HeaderGeneral title='Hall of Fame' />
      <div className={styled.container}>
        <div className={styled.btnsWrapper}>
          {tops.map((el, index) => {
            return (
              <Button
                onClick={() => toggleTop(index)}
                key={el.title}
                className={activeBtn === index ? styled.activeBtn : styled.notActiveBtn}>
                {el.title}
              </Button>
            );
          })}
        </div>
        {activeBtn === 0 && (
          <TopWinnersList
            page={page}
            setPage={setPage}
            per={per}
            count={count}
            loadMoreData={loadMoreData}
            userStatistic={userStatistic}
            coverStyle={{ height: 130 }}
            isArchive={true}
            type='archive'
          />
        )}
        {activeBtn === 1 && <TopPlayers />}
        {activeBtn === 2 && <TopProducers />}
        {activeBtn === 3 && <TopSponsors />}
      </div>
    </>
  );
};

export { Winners };
