import React, { useEffect, useState } from "react";
import styles from './StockExchange.module.scss'
import { HeaderGeneral } from "../../components/HeaderGeneral/HeaderGeneral";
import { Button, Popover } from "antd";
import { ReactComponent as Info } from '../../assets/svg/info.svg'
import { useLocation, useNavigate } from "react-router-dom";
import Stock from "../../components/Stock";
import StockModel from "../../components/Stock/StockModel";
import { stockApi } from "../../api/api";

const StockExchange = () => {
 const navigate = useNavigate();
 const location = useLocation()


 console.log(location)


 const mock = [
  {
   id: 1,
   name: "Amy Winehouse",
   stock: {
    current: 10.3,
    change: +0.34,
    percentage: +3
   },
   fanbase: 2032404,
   engagement: 3.2,
   global: 1000,
   image: "https://hips.hearstapps.com/hmg-prod/images/amy-winehouse-662f6e6678bdf.jpg?crop=0.822xw:0.548xh;0.178xw,0.142xh&resize=980:*"
  },
  {
   id: 1,
   name: "Ice Spice",
   stock: {
    current: 13.3,
    change: -0.34,
    percentage: -5
   },
   fanbase: 2044567,
   engagement: 12.2,
   global: 100000000,
   image: "https://media-cldnry.s-nbcnews.com/image/upload/t_fit-1240w,f_auto,q_auto:best/rockcms/2024-02/ice-spice-1x1-jp-240201-be1081.jpg"
  },
 ]



 const content = (
  <div>
   <p>Content</p>
   <p>Content</p>
  </div>
 );


 return (
  <>
   <HeaderGeneral title="Stock Exchange" />
   <div className={styles.container}>
     {
      !location.search ?
       <>
    <div className={styles.info_popularity}>
        <p className={styles.popularity}>Popularity (past 30 day)</p>
        <Popover content={content} title="Title">
         <Button className={styles.info_popularity_button} type="link" ><Info /></Button>
        </Popover>
        </div>
        <Stock/>
       </>
       :
       <>
       <StockModel id={location.search}/>
       </>
     }

   </div>
  </>
 )

}

export default StockExchange;
