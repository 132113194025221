import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
import { ConfigProvider, Drawer, theme as antdTheme } from "antd";

import { useAppDispatch, useAppSelector } from "./hooks/reduxHooks";
import { toggleVisibleMenu, toggleVisibleMenuAll } from "./store/mainMenuSlice";
import { Navigate } from "./components/Navigate/Navigate";
import { Layout } from "./components/Layout/Layout";
import { NFTBattles } from "./pages/NFTBattles/NFTBattles";
import { Winners } from "./pages/Winners/Winners";
import { Support } from "./pages/Support/Support";
import { Contacts } from "./pages/Contacts/Contacts";
import { HowToPlayAndWin } from "./pages/HowToPlayAndWin/HowToPlayAndWin";
import { Loading } from "./pages/Loading/Loading";
import { Battles } from "./components/Profile/Battles/Battles";
import { Favorites } from "./components/Profile/Favorites/Favorites";
import { ProfileSettings } from "./components/ProfileSettings/ProfileSettings";
import { ButtonsPage } from "./pages/NFTBattles/buttonsPage/ButtonsPage";
import { ShortNavigate } from "./components/Navigate/ShortNavigate";
import { SupportFAQ } from "./components/SupportFAQ/SupportFAQ";
import { ResetPassword } from "./pages/ResetPassword/ResetPassword";
import { setVideoData, setVoteData } from "./store/playerSlice";
import VideoPlayer from "./components/VideoPlayer";

import { MakeOffer } from "./pages/MakeOffer/MakeOffer";
import { Playlist } from "./components/Profile/Playlist/Playlist";
import { GameRules } from "./pages/GameRules/GameRules";
import { Terms } from "./pages/Terms/Terms";
import { Privacy } from "./pages/Privacy/Privacy";
import Report from "./pages/Report/Report";
import BuyTokens from "./components/BuyTokens";
import ContactUs from "./components/ContactUs";

import styled from "./App.module.scss";
import LuckySpin from "./pages/LuckySpin";
import Battle from "./pages/NFTBattles/Battle";
import ProfileWallet from "./components/ProfileWallet";
import Superwet from "./pages/Superwet";
import { authAPI, profileAPI } from "./api/api";

import { MantineProvider } from "@mantine/core";
import BattleArchive from "./pages/BattleArchive/BattleArchive";
import BattleRandomPage from "./pages/BattleRandom";
import BattleRandom from './components/BattleRandom'
import StockExchange from "./pages/StockExchange";
import ProfileStock from "./components/ProfileStock";

const Home = () => {
    const navigate = useNavigate();

    if (localStorage.getItem("isAuth")) {
        navigate("/battles/tournament");
    } else {
        navigate("/login");
    }

    return null;
};

const App = () => {
    const [isVisibleDesktopMenu, setIsVisibleDesktopMenu] = useState(true);
    const [isVisibleShortDesktopMenu, setIsVisibleShortDesktopMenu] =
        useState(false);
    const dispatch = useAppDispatch();
    const [voted, setVoted] = useState(true);
    const location = useLocation();
    const { pathname } = location;
    const navigate = useNavigate();

    const idVideo = useAppSelector((state) => state.player.videoId);

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: `900`px)",
    });

    const isMobile = useMediaQuery({
        query: "(min-width: 489px)",
    });
    const isDesktop = useMediaQuery({
        query: "(min-width: 1701px)",
    });

    const isShortDesktop = useMediaQuery({
        query: "(min-width: 1201px)",
    });

    useEffect(() => {
        dispatch(toggleVisibleMenuAll(isDesktopOrLaptop));
    }, [dispatch, isDesktopOrLaptop]);

    useEffect(() => {
        dispatch(toggleVisibleMenu(location.pathname));
    }, [dispatch, location]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        if (!isDesktop) {
            setIsVisibleDesktopMenu(false);
        }
        if (
            location.pathname === "/login" ||
            location.pathname === "/" ||
            location.pathname === "/#" ||
            location.pathname === ""
        ) {
            setIsVisibleDesktopMenu(false);
            setIsVisibleShortDesktopMenu(false);
            return;
        } else if (isShortDesktop && !isDesktop) {
            setIsVisibleShortDesktopMenu(true);
        } else if (!isDesktop) {
            setIsVisibleShortDesktopMenu(false);
            setIsVisibleDesktopMenu(false);
            return;
        } else {
            setIsVisibleShortDesktopMenu(false);
            setIsVisibleDesktopMenu(true);
        }
    }, [isDesktop, isShortDesktop, location.pathname]);
    const isOpenModalLogin = useAppSelector((state) => state.modalLogin.isOpen);

    const handleClose = () => {
        dispatch(
            setVoteData({
                vote_title: "",
                vote: () => null,
                vote_singer: "",
                vote_video: { id: 0 },
                setISVoted: () => null,
                vote_id: 0,
                pick: null,
                setPick: () => null
            })
        );
        dispatch(
            setVideoData({
                id: 0,
                url: "",
                image: "",
                title: "",
                genre: "",
                artists_names: [""],
                categories_names: [""],
            })
        )
    }

    useEffect(() => {
        profileAPI
            .getProfile()
            .then((res) => {

            })
            // .catch(() => navigate("/login"))
    }, [])
    

    return (
        <ConfigProvider
            theme={{
                // Отключаем алгоритм для всех компонентов
                // algorithm: false,
                // Устанавливаем цвета компонентов
                // algorithm: antdTheme,
                components: {
                    Button: {
                        colorPrimary: '#C8102E',
                    },
                    Select: {
                        colorPrimary: '#C8102E',
                    },
                    Slider: {
                        colorPrimary: 'gray',
                    },
                    Switch: {
                        colorTextQuaternary: "#1D428A",
                        colorTextTertiary: "#1D428A",
                    },
                },
                // Устанавливаем цвета токенов
                token: {
                    colorPrimary: '#C8102E',
                    colorBgContainer: '#fff',
                },
            }}
        >
            <div
                className={styled.container}
                style={isOpenModalLogin ? { overflow: "hidden" } : {}}>
                {isVisibleDesktopMenu && <Navigate />}
                {isVisibleShortDesktopMenu && <ShortNavigate />}

                <Layout>
                    <Routes>
                        <Route path='/' element={<Home />} />
                        {/*  <Route path='explore' element={<Explore />} /> */}

                        {/* <Route path='nft-battles' element={<NFTBattles />} /> */}

                        {/* battles pages start */}
                        <Route path='battles/tournament' element={<ButtonsPage />} />
                        <Route path="random" element={<BattleRandom/>} />
                        <Route path="random-battle" element={<BattleRandomPage/>} />
                        <Route path="stock-exchange" element={<StockExchange/>} />
                        <Route path='battle/all-battles' element={<NFTBattles />} />
                        <Route path='battle' element={<Battle />} />
                        <Route path='history' element={<BattleArchive/>}/>
                        {/* battles pages finish */}
                        {/* <Route path='buy-nft' element={<BuyNFT />} /> */}
                        <Route path='make-offer' element={<MakeOffer />} />
                        <Route path='hall-of-fame' element={<Winners />} />
                        <Route path='game-rules' element={<GameRules />} />
                        <Route path='lucky-spin' element={<LuckySpin />} />
                        <Route path='private-film' element={<Superwet />} />
                        {/* profile */}
                        {/* <Route path='profile' element={<NFTSong />} /> */}
                        <Route path='profile/settings' element={<ProfileSettings />} />
                        <Route path='profile/playlists' element={<Playlist />} />
                        <Route path='profile/battles' element={<Battles />} />
                        <Route path='/profile/buy-tokens' element={<BuyTokens />} />
                        <Route path='profile/settings' element={<ProfileSettings />} />
                        <Route path='profile/battle-results' element={<Winners onlyResults />} />
                        <Route path='profile/support' element={<ContactUs />} />
                        <Route path='profile/wallet' element={<ProfileWallet />} />
                        <Route path="/profile/stocks_exchange" element={<ProfileStock/>} />
                        {/* profile-end */}

                        {/* WInners */}
                        <Route path='hall-of-fame/top-players' element={<Favorites />} />
                        <Route path='hall-of-fame/top-nfts' element={<Favorites />} />
                        {/* WInners end */}
                        {/* <Route path='your-playlist' element={<YourPlaylist />} /> */}
                        <Route path='profile/playlists' element={<Playlist />} />
                        <Route path='support' element={<Support />}>
                            <Route path='/support/:idCategory/FAQ' element={<SupportFAQ />} />
                        </Route>
                        <Route path='contacts' element={<Contacts />} />
                        <Route path='login' element={<HowToPlayAndWin />} />
                        <Route path='reset-password' element={<ResetPassword />} />

                        {/* footer */}
                        <Route path='terms-n-conditions' element={<Terms />} />
                        <Route path='privacy' element={<Privacy />} />
                        <Route path='report' element={<Report />} />

                        {/* preloader */}
                        <Route path='loading' element={<Loading />} />
                    </Routes>
                </Layout>

                <Drawer
                    onClose={handleClose}
                    placement={"bottom"}
                    open={!!idVideo}
                    height={"100%"}
                    destroyOnClose
                    styles={{
                        content: { height: "100%" },
                        body: { padding: "20px 5px" },
                        header: { display: "flex", justifyContent: "flex-end" },
                    }}
                >
                    <MantineProvider>
                        <VideoPlayer />
                    </MantineProvider>
                </Drawer>
            </div>
        </ConfigProvider>
    );
};

export default App;
