import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, ConfigProvider, Row, Select, Typography } from "antd"
import { WalletOutlined } from '@ant-design/icons';
import Logo from '../../assets/logo-new.svg';
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { authAPI, profileAPI } from "../../api/api";
import { setUserData } from "../../store/playerSlice";
import { userLogOut } from "../../store/isAuthSlice";
import logoutSvg from '../../assets/logout.svg'
import { ReactComponent as WalletIcon } from '../../assets/svg/wallet.svg'
import s from './Account.module.scss'
import {sprintf} from 'sprintf-js'
import {ReactComponent as Balance} from '../../assets/svg/account_balance.svg'


type AccountType = {
	isAuth: boolean
	setIsAuth: (set: boolean) => void
}

const { Text } = Typography;

const Account = ({ isAuth, setIsAuth }: AccountType) => {

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { userId, firstName, lastName, nickname, email, gender, phone, balance, avatar, player_status, giver_status, sponsor_status } = useAppSelector((state) => state.player);


	async function logout() {
		authAPI
			.logOut()
			.then(() => {
				dispatch(userLogOut(null));
			})
			.then(() => localStorage.removeItem('isAuth'))
			.then(() => navigate('/login'))
			.then(() => setIsAuth(false));
	}
	const isBlockedRoute = useAppSelector((state) => state.player.isBlockedRoute);

	useEffect(() => {
		function ccc() {
			profileAPI
				.getProfile()
				.then((res) => {
					dispatch(
						setUserData({
							first_name: res.first_name,
							last_name: res.last_name,
							nickname: res.nickname,
							email: res.email,
							gender: res.gender,
							phone: res.phone,
							id: res.id,
							balance: res.amount,
							avatar: res?.avatar,
							first_voted: res?.data?.first_voted,
							income: res?.transaction_sums?.income,
							expense: res?.transaction_sums?.expense,
							transactions: res?.videobet_transactions,
							stocks: res?.stocks
						})
					)
				});
		}
		ccc();
	}, []);

	// console.log(isAuth)

	const centered = { display: "flex", justifyContent: "center", alignItems: "center" }

	return (
		<Row justify={"end"} className={s.wrapper}>
			<Col xs={24} md={16} lg={12} xl={6} xxl={4}>
				<Row align={'middle'} className={s.content} gutter={[10, 10]}>
						<Col xs={2} xl={4} style={centered}>
						<Button className={s.profilePic} type="link" href="#/profile/settings">
							{
								!!avatar && <img src={avatar} alt="avatar" width={50} height={50} className={s.avatar} />
							}
						</Button>

					</Col>
					<Col xs={20} xl={18}>
						<ConfigProvider
							theme={{
								components: {
									Select: {
										fontSize: 12,
										algorithm: true,
									},
									Button: {
										fontSize: 12,
										algorithm: true,
									}
								}
							}}
						>
							<Row gutter={[10, 10]} style={{ marginTop: 5 }}>
								<Col span={24} className={s.logo_col}>
									<div className={s.logo_col}>
										<Button className={s.button_link} type="link" href="#/game-rules">
											<img className={s.logo} src={Logo} alt='' style={{marginTop: 8}} width={150} />
										</Button>
										<Button className={s.button_wallet} type={"default"} onClick={() => navigate('/profile/buy-tokens')}>
											<div className={s.button_wrapper}>
												<Balance />
												<span style={{ paddingLeft: 10 }}>
													{sprintf('%.2f', balance)} TKN</span>
											</div>

										</Button>
									</div>

								</Col>
							</Row>
						</ConfigProvider>
					</Col>
					<Col span={2} style={centered}>
						<Button className={s.logout_button} onClick={() => logout()} title='logout' type={"text"} disabled={isBlockedRoute}>
							<img src={logoutSvg} alt="<-" />
						</Button>
					</Col>
				</Row>
			</Col>
		</Row>
	)
}

export default Account;
