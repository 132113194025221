import 'react-toastify/dist/ReactToastify.css';
import {AnimateBtn} from '../../components/Buttons/AnimateBtn/AnimateBtn';

import styled from './ResetPassword.module.scss';
import {useSearchParams} from "react-router-dom";
import {Field, Form, Formik} from "formik";
import {authAPI} from "../../api/api";

const ResetPassword = () => {
    const [searchParams] = useSearchParams();

    return (
        <>
            <div className={styled.container}>
                <h1 className={styled.text}>Reset password</h1>
                {/* <Formik
                    initialValues={{
                        email: "",
                        password: "",
                        passwordConfirmation: "",
                        token: searchParams.get("token")
                    }}
                    onSubmit={async (values) => {
                        authAPI
                            .resetPassword(
                                values.token,
                                values.email,
                                values.password,
                                values.passwordConfirmation
                            ).then((res) => {
                            console.log(res);
                        })
                            .catch((error) => {
                                console.log(error.response.data);
                            });
                    }}
                >
                    <Form className={styled.container}>
                        <Field
                            className={styled.inputData}
                            type='email'
                            id='email'
                            name='email'
                            placeholder='email'
                        />
                        <Field
                            className={styled.inputData}
                            type='password'
                            id='password'
                            name='password'
                            placeholder='new password'
                        />
                        <Field
                            className={styled.inputData}
                            type='password'
                            id='passwordConfirmation'
                            name='passwordConfirmation'
                            placeholder='repeat password'
                        />
                        <Field
                            className={styled.inputData}
                            type='hidden'
                            id='token'
                            name='token'
                        />

                        <div className={styled.btn}>
                            <AnimateBtn
                                additionalStyles={{height: "62px", width: "283px"}}
                                type='submit'
                                title='Submit'
                            />
                        </div>

                    </Form>
                </Formik> */}
            </div>
        </>
    );
};

export {ResetPassword};
