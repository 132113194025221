import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';

import { AgeConfirm } from '../../components/AgeConfirm/AgeConfirm';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';

import { LoginModal } from '../Login/LoginModal/LoginModal';
import { authAPI } from '../../api/api';
import { userLogOut } from '../../store/isAuthSlice';

import styled from './HowToPlayAndWin.module.scss';
import { useNavigate } from 'react-router-dom';
import { setIsAge } from '../../store/playerSlice';

const HowToPlayAndWin = () => {
    const dispatch = useAppDispatch();
    const [isAuth, setIsAuth] = useState(false);
    const isAge = useAppSelector((state) => state.player.isAge);

    const navigate = useNavigate();
    const isOpenModalLogin = useAppSelector((state) => state.modalLogin.isOpen);
    useEffect(() => {
        if (localStorage.getItem('isAuth')) setIsAuth(true);
        if (!localStorage.getItem('isAuth')) setIsAuth(false);
        if (localStorage.getItem("ageConfirm")) {

            dispatch(setIsAge(true))
        }
        if (!localStorage.getItem("ageConfirm")) {
             dispatch(setIsAge(false))
            }
    }, [dispatch, isAuth, navigate]);

    async function logout() {
        authAPI
            .logOut()
            .then(() => {
                dispatch(userLogOut(null));
            })
            .then(() => setIsAuth(false))
            .catch(() => {
                toast.error('the server is not responding, try again');
            });
    }

    useEffect(() => {
        if (isOpenModalLogin) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isOpenModalLogin]);

    return (
        <div className={styled.containerWrapper}>
            {
                !isAge
                    ? (
                        <AgeConfirm
                            onOk={(() => {
                                //@ts-ignore
                                localStorage.setItem('ageConfirm', true);
                                dispatch(setIsAge(true))
                            })}
                            onExit={() => {navigate("/game-rules"); }}
                        />
                    )
                    : <LoginModal />
            }
        </div>
    );
};

export { HowToPlayAndWin };
