import { useEffect, useRef, useState } from 'react';

import NavigateElement from './NavigateElement';
import { useAppSelector } from '../../hooks/reduxHooks';
import { ReactComponent as Person } from '../../assets/person.svg';
import { ReactComponent as Swords } from '../../assets/swords.svg';
import { ReactComponent as Trophy } from '../../assets/trophy.svg';
import { ReactComponent as Spin } from '../../assets/spinn.svg';
import { ReactComponent as Support } from '../../assets/contact_support.svg';
import { ReactComponent as Rules } from '../../assets/rules.svg';
import { ReactComponent as TwitterX } from '../../assets/twitter-x.svg';
import { ReactComponent as Archive} from '../../assets/archive.svg'
import { InstagramOutlined, } from '@ant-design/icons';
import { ReactComponent as Star } from '../../assets/svg/superwet.svg'
import shape from '../../assets/shape.svg';
import { ReactComponent as Cycle} from '../../assets/svg/cycle_FILL0_wght300_GRAD0_opsz24.svg';
import { ReactComponent as Visibility} from '../../assets/svg/visibility_lock_FILL0_wght300_GRAD0_opsz24.svg';
import { ReactComponent as Random} from '../../assets/random.svg'
import { ReactComponent as Chart} from '../../assets/svg/monitoring.svg'

import styled from './ShortNavigate.module.scss';

import Logo from '../../assets/logo-new.svg';
import { Button } from 'antd';


const ShortNavigate = () => {
  const ref = useRef(null);
  const [isAuth, setIsAuth] = useState(true);

  const isOpenModal = useAppSelector((state) => state.modalLogin.isOpen);

  useEffect(() => {
    if (localStorage.getItem('isAuth')) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  }, []);

  const menu = [
    { name: 'Battle Tournament', url: 'battles/tournament', path: 'battles', svg: <Swords /> },
    { name: "Battle Random", url: 'random', path: "random", svg: <Random/>},
    { name: "Battle History", url: 'history', path: "history", svg: <Archive/>},
    { name: 'Hall of Fame', url: 'hall-of-fame', path: 'hall-of-fame', svg: <Trophy /> },
    { name: 'Stock Exchange', url: 'stock-exchange', path: 'stock-exchange', svg: <Chart /> },
    { name: 'Lucky Spin', url: 'lucky-spin', path: 'lucky-spin', svg: <Cycle /> },
    { name: 'Private Film', url: 'private-film', path: "private-film", svg: <Visibility /> },
  ];

  const profile = [
    { name: 'Account', url: 'profile/settings', path: "profile", svg: <Person /> },
  ];

  const help = [
    { name: 'Contact us', url: 'contacts', path: 'contacts', svg: <Support /> },
    { name: 'Game Rules', url: 'game-rules', path: 'game-rules', svg: <Rules /> },
  ];

  const social = [
    { name: "twitter", url: "https://twitter.com/Playporn_bet", svg: <TwitterX style={{ width: 40 }} /> },
  ]

  return (
    <div style={{ position: "relative" }}>
      {isOpenModal ? null : (
        <nav ref={ref} className={styled.container}>
          <div className={styled.topWrapper}>
            <div className={styled.logoWrapper}>
              <Button className={styled.button_link} type='link' href='/#/game-rules'>
                <img className={styled.logo} src={Logo} alt='logo' />
              </Button>
            </div>
            <div className={styled.menuWrapper}>
              {isAuth && <NavigateElement menuElement={profile} />}
              <NavigateElement menuElement={menu} />
              <NavigateElement menuElement={help} />
            </div>
            <div className={styled.social_media}>
              {
                social.map(({ svg, url, name }) => {
                  return (
                    <a href={url} key={name}>
                      <div style={{ display: "flex", justifyContent: "center", alignItems: 'center' }} >
                      {svg} <span style={{fontSize: 18, color: "#000"}}>Follow Us</span>
                      </div>
                    </a>
                  )
                })
              }
            </div>

          </div>

          {/* <div className={styled.rightsWrapper}>
            <div className={styled.iconsShape}>
              <img className={styled.webLink} src={shape} alt='shape' />
              <img className={styled.webLink} src={shape} alt='shape' />
              <img className={styled.webLink} src={shape} alt='shape' />
              <img className={styled.webLink} src={shape} alt='shape' />
            </div>
          </div> */}
        </nav>
      )}
      {/* <div style={{ position: "relative"}}>
        {
          social.map((el) => {
            return (
              <div style={{ margin: 20, display: "flex", justifyContent: "center" }}>{el.svg}</div>
            )
          })
        }
      </div> */}
    </div>
  );
};

export { ShortNavigate };
