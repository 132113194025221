import { Col, Row } from 'antd';
import { MantineProvider } from "@mantine/core";
import Lock from '../../components/SongCard/SongPoster/components/Padlock/icons/Lock'

import { Pideo } from '../../components/Pideo';

import styled from './GameRules.module.scss';
import { useMediaQuery } from 'react-responsive';
import { HeaderGeneral } from '../../components/HeaderGeneral/HeaderGeneral';

const GameRules = () => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 1201px)',
  });

  return (
    <>
      <HeaderGeneral title='RULES OF THE GAME' />
      <div className={styled.wrapper} style={localStorage.getItem('isAuth') ? {} : isDesktop ? { marginTop: 70 } : { marginTop: 0 }}>
        <Row >
          <Col span={24}>
          </Col>
        </Row>
        <Row className={styled.rules_container}>
          <MantineProvider>
            <Col span={24} style={{ marginBottom: 32 }}>
              <Pideo
                id={"game-rules"}
                preview='https://s3.playporn.bet/uploads/content/2024/03/29/photo_2024-02-22_16-01-43.jpg'
                videos={{ 1080: "https://s3.playporn.bet/uploads/content/2024/03/29/Gameplay%20presentation%20%D0%9E%D0%9A.mov" }} />

            </Col>
          </MantineProvider>
          <Col span={24}>
            <h2 className={styled.subHeader}>
              Participation Eligibility:
            </h2>
            <p className={styled.text}>
              Players must meet the legal age requirements of their jurisdiction. Access to PlayPorn.bet is restricted in regions where such activities violate local laws.
            </p>

            <h2 className={styled.subHeader}>
              IN-GAME CURRENCY - TOKENS:
            </h2>
            <p className={styled.text}>
              The in-game currency of PlayPorn.bet is represented by tokens. These tokens are exclusive to the PlayPorn.bet gaming platform and cannot be exchanged for real currency. Players can use these tokens to request custom videos from the Superwet model list.          </p>

            <h2 className={styled.subHeader}>
              Token Acquisition:
            </h2>
            <p className={styled.text}>
              Tokens are acquired at a rate of 1 token per euro.
            </p>

            <h2 className={styled.subHeader}>
              MINIMUM BET:
            </h2>
            <p className={styled.text}>
              The minimum bet in PlayPorn.bet games is 1 token.
            </p>

            <h2 className={styled.subHeader}>
              Gameplay:
            </h2>
            <p className={styled.text}>
              The tournament follows an elimination play-off system with 8 participants. The winner of each pair advances to the next round, while the defeated participant is eliminated from the tournament. The participant who wins the final becomes the overall winner. Players can place bets on two competing videos within a week. The video with the most player votes wins when the timer, programmed for one week, expires.          </p>
            <h2 className={styled.subHeader}>
              Prize Fund Distribution:
            </h2>
            <p className={styled.text}>
              The prize pool is composed of tokens from losing players. Distribution: 70% of the prize pool goes to the winners, distributed proportionally based on their individual bets. Additionally, 10% is allocated to the winning model, 5% to the losing model, and 15% serves as a commission for PlayPorn.bet.
            </p>
            <h2 className={styled.subHeader}>
              Lucky Spin:
            </h2>
            <p className={styled.text}>
              Players can multiply their tokens through the Lucky Spin by selecting models, placing bets, and spinning the wheel.
            </p>

            <h2 className={styled.subHeader}>
              Private Film
            </h2>
            <p className={styled.text}>
              Private movie is an exclusive video, specially created by the model for those who purchase a ticket to the private screening. You have the opportunity to buy a ticket until the campaign countdown concludes. Filming will commence upon reaching the required amount, specified as the campaign goal. If the target amount isn't achieved by the end of the campaign countdown, the campaign will be halted, and funds for purchased tickets will be refunded to users' wallets in their Playporn.bet account.
            </p>
            <p className={styled.text}>
              You can choose from four ticket options: <br />
              $10 - grants access to view the video and awards you a bronze producer status. <br />
              $20 - provides access to view the video and awards you a silver producer status. <br />
              $100 - gives access to view the video, awards you a gold producer status, and the model will write your name on her body. <br />
              $3000 - exclusively grants you access to view the video, awards you a diamond producer status, and the model will inscribe only your name on her body.
            </p>
            <p className={styled.text}>
              Additionally, you can purchase a ticket for any amount exceeding $10, thereby expediting the fundraising period until the goal is attained and receiving the corresponding status along with its privileges.
            </p>

            <h2 className={styled.subHeader}>
              PLAYER STRATEGY AND SUCCESS:
            </h2>
            <p className={styled.text}>
              It's essential to keep in mind that PlayPorn.bet is designed as an entertainment game where the key factors for success are player strategy, skills, and knowledge. To enhance your chances of winning, delve into studying and analyzing the popularity of models participating in battles, along with the strategies and behaviors of fellow players!          </p>

            <h2 className={styled.subHeader}>
              Script Guidelines:
            </h2>
            <p className={styled.text}>
              Custom video scripts must align with the chosen model, comply with safety regulations, and respect everyone's rights and sensitivities.          </p>

            <h2 className={styled.subHeader}>
              COMPLIANCE:
            </h2>
            <p className={styled.text}>
              Players must adhere to local laws, platform rules, and ethical standards.
            </p>

            <h2 className={styled.subHeader}>
              DISPUTE RESOLUTION:
            </h2>
            <p className={styled.text}>
              PlayPorn.bet reserves the right to resolve any disputes and make final decisions on game-related matters. By participating, players acknowledge and agree to abide by these rules. Play responsibly!          </p>

            {/* <h2 className={styled.subHeader}>
            IMPORTANT!!!
            </h2>
            <p className={styled.text}>
            The winning video is being decided by majority of the votes - not the amount of the bets!<br />
            The funds (the bets) of the players who bet on lost video is going to the prize pool which is being distributed between winning players and video owners.
            </p>
            
            <h2 className={styled.subHeader}>BATTLE EXAMPLE:</h2>
            <p className={styled.text}>Video #001 gained 2000 tokens in one week from 100 players who bid. At the same time, video #002 gained 1000 tokens in the same period of time, collecting votes from 200 players. </p>
            <p className={styled.text}>Respectively, video #002 from the example wins the battle as more people have voted for it.</p>
            <p className={styled.text}>The prize fund consists of the bets of losing players.</p>
            <p className={styled.text}>The owner models of the #002 video receives 10% of the prize fund. <br />The net income of the owner of video #002 in this battle is 10% out of 2000 tokens = 200 tokens.</p>
            <p className={styled.textBronze}>The owner models of the #001 video which has lost the battle receives 5% out of the prize fund. In our example video #001 owner's incentive prize will be 100 tokens </p>
            <p className={styled.textBronze}>Another 70% of the prize fund is distributed among the players who voted for the winner of the battle - Video #002, in exact proportion to their bets! </p>
            <p className={styled.textBronze}>1400 tokens distributed among 200 players (the player who bet more receives more money!)</p>
            <p className={styled.textBronze}>1 Token = 1 EURO on <a href="playporn.bet" target='_blank'>PlayPorn.bet</a></p>
          */}

            {/* <h2 className={styled.subHeader}>Lucky Spin</h2>
          <p className={styled.text}>On PlayPorn.bet, players can instantly multiply their tokens by playing the Lucky Spin.</p>
          <p className={styled.text}>Select one or more models participating in a particular spin, select the amount of the bet and make a spin. If your bet wins it will be multiplied by 4!</p>
          
          <h2 className={styled.subHeader}>SUPERWET</h2>
          <p className={styled.text}>Players who have earned 3000 tokens in games on PlayPorn.bet have a unique opportunity to order an Exclusive Custom Video from Superwet models based on their own scenario*.</p>
          <p className={styled.text}>* After selecting a model from the list and paying for the service, the PlayPorn.bet manager will contact the player to discuss the script and other details for shooting the video.</p>
          <p className={styled.text}>The price includes the cost of video production in the geographical location of the model at the time of order and the model’s fee. If, according to the player’s scenario, the model must film in another geographical location, the player will have to additionally pay travel expenses.</p>
          <p className={styled.text}>A unique link to the finished exclusive video will be sent to the player within 14 days after payment.</p>
        <p className={styled.text}>Important!!! The Custom Video script will necessarily be consistent with the model and will have to meet safety requirements in its production for performers, as well as be within the limits of “reasonable” without infringing on anyone’s rights and feelings.</p> */}
          </Col>
        </Row>
      </div>
    </>
  );
};

export { GameRules };
