import { useEffect, useMemo, useState } from "react";
import { battleAPI, profileAPI, videoAPI } from "../../../api/api";
import { ReactComponent as Heart } from "../../../assets/layer1.svg";
import { ReactComponent as RedHeart } from '../../../assets/heardRed.svg'
import { ReactComponent as RedTip } from '../../../assets/Heart_bet_red 1.svg'
import { ReactComponent as Tip } from '../../../assets/Heart_bet_red 3.svg'
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { toast } from "react-toastify";

import { ReactComponent as LikeRed } from '../../../assets/svg/hearts/like_filled_r.svg';
import { ReactComponent as LikeWhite } from '../../../assets/svg/hearts/like_filled_w.svg';
import { ReactComponent as LikeOutlinedRed } from '../../../assets/svg/hearts/like_outlined_r.svg';
import { ReactComponent as LikeOutlinedWhite } from '../../../assets/svg/hearts/like_outlined_w.svg'

import { ReactComponent as Thumbsup} from '../../../assets/thumb up.svg'
import { ReactComponent as ThumbsupRed} from '../../../assets/thumb up red.svg'

import styled from "../SongCard.module.scss";
//import { ScrollingText } from "../../TextUtils/ScrollingText";
import { Button, Col, Input, Popover, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { setUserData } from "../../../store/playerSlice";
//import { useSearchParams } from "react-router-dom";
interface IProps {
    title: any;
    singer: string;
    // video: {
    //     id: number;
    // };
    video: any
    setISVoted: (v: boolean) => void;
    id: any;
    battleId?: number;
    setPick?: any
    pick?: any
    getPopupContainer?: () => HTMLElement,
    archive?: boolean
    onVotee?: () => void
    type?: string
    battle?: any
    likes?: any
    handleLikes?: any,
    isLike?: any
}

const SongInfo: React.FC<IProps> = ({
    battleId,
    title,
    setISVoted,
    id,
    video,
    pick,
    setPick,
    getPopupContainer,
    archive,
    type,
    battle,
    likes,
    handleLikes,
    isLike
    
}) => {

    // console.log(battle.data.battle_customers)
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState<any>({});
    const dispatch = useAppDispatch();
    const isLastChance = useAppSelector((state) => state.battle.isLastChange);
    const playingSong = useAppSelector((state) => state.battle.songId);
    const idVideo = useAppSelector((state) => state.player.videoId);
    const [likedId, setLikedId] = useState<any>()

    const [isLiked, setIsLiked] = useState(false);    const [votesCount, setVotesCount] = useState(video?.votes_count || 0)
    const [likesCount, setLikesCount] = useState(video?.likes || 0)
    // const [votesCount, setVotesCount] = useState()
    const [newLike, setNewLike] = useState<any>({})
    const [tips, setTips] = useState((video?.artists_names[0]?.tips_sum) * (-1))
    const [amount, setAmount] = useState<number>(1)
    const [openPop, setOpenPop] = useState(false);
    const { balance } = useAppSelector((state) => state.player);
    const { setOnVotee: onVotee } = useAppSelector((state) => state.player);
    const { firstVoted } = useAppSelector((state) => state.player);


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value: inputValue } = e.target;
        const reg = /^-?\d*(\.\d*)?$/;
        if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
            +inputValue <= 500 ? setAmount(+inputValue) : setAmount(500)
        }
    };


    // useEffect(() => {
    //     archive ? setTips(video?.artists_names[0].tips_sum) : setVotesCount(video?.votes_count)
    // }, [video, pick, pick.touch, setPick, archive, votesCount, tips])

// console.log(id, likes)
    function votee(id: number, amount) {
        return battleAPI
            .votesForCard(id, battleId, amount)
            .then((res: any) => {
                setPick({ touch: pick?.touch + 1, id: video.id })
                setVotesCount((res.data.votes_count))

                return res.data;
            })
            .then((res) => {
                profileAPI
                    .getProfile()
                    .then((res) => {
                        dispatch(
                            setUserData({
                                first_name: res.first_name,
                                last_name: res.last_name,
                                nickname: res.nickname,
                                email: res.email,
                                gender: res.gender,
                                phone: res.phone,
                                id: res.id,
                                balance: res.amount,
                                avatar: res?.avatar,
                                first_voted: res?.data?.first_voted,
                                income: res?.transaction_sums.income,
                                expense: res?.transaction_sums.expense,
                                transactions: res?.videobet_transactions,
                                stocks: res?.stocks

                            })
                        )
                    });
            })
            .catch((error) => {
                toast.error(error.response.data.message);
                console.log("ERROR: ", error.response.data.message);
            });
    }

    function tip(battle_id: number, artist_id: number, amount) {
        return battleAPI
            .tipsForCard(battle_id, artist_id, amount)
            .then((res: any) => {
                setPick({ touch: pick?.touch + 1, id: video.id })
                setTips(res.data.tips_sum * (-1))

                return res.data;
            })
            .then((res) => {
                profileAPI
                    .getProfile()
                    .then((res) => {
                        dispatch(
                            setUserData({
                                first_name: res.first_name,
                                last_name: res.last_name,
                                nickname: res.nickname,
                                email: res.email,
                                gender: res.gender,
                                phone: res.phone,
                                id: res.id,
                                balance: res.amount,
                                avatar: res?.avatar,
                                first_voted: res?.data?.first_voted,
                                income: res?.transaction_sums.income,
                                expense: res?.transaction_sums.expense,
                                transactions: res?.videobet_transactions,
                                stocks: res?.stocks

                            })
                        )
                    });
            })
            .catch((error) => {
                toast.error(error.response.data.message)
            })
            .catch((error) => {
                toast.error(error.response.data.message);
                console.log("ERROR: ", error.response.data.message);
            });
    }


    const pulsateLike = useMemo(() => {
        return !!video.id;
    }, [playingSong, isLastChance]);

    useEffect(() => {
        if (isLiked) {
            setTimeout(() => {
                setIsLiked(false);
            }, 1000);
        }


    }, [isLiked]);

    useEffect(() => {
        profileAPI
            .getProfile()
            .then((res) => {
                return res.data;
            })
            .then((res) => {
                setUserInfo(res);
            });
    }, []);




    const handleLike = () => {
        if (video.id) {
            setIsLiked(!isLiked);
            setISVoted(true);
            setOpenPop(false)
            votee(id, amount);
        }
    };



    const handleTip = () => {
        setIsLiked(!isLiked);
        setISVoted(true);
        setOpenPop(false)
        tip(video.battleId, video.artists_names[0].id, amount)

    }

    const handleFirstLike = () => {

        if (video.id) {

            setIsLiked(!isLiked);
            setISVoted(true);
            setOpenPop(false)
            votee(id, 1);

        }
    };

    // useEffect(() => {
    //     if (archive) {
    //         setTips(prevTipsCount => video?.artists_names[0].tips_sum * -1);
    //     } else {
    //         setVotesCount(prevVotesCount => video?.votes_count);
    //     }
    // }, [archive, video]);

    function popoverVisual() {
        if (!!balance && localStorage.getItem('isAuth')) {
            return (

                type !== 'random' ?

                    <Popover
                        content={
                            <Row justify={"center"} gutter={[20, 20]}>
                                <Col span={24}>
                                    <Input
                                        onChange={handleChange}
                                        value={amount}
                                    />
                                </Col>
                                <Col>
                                    <Button
                                        type={"primary"}
                                        onClick={
                                            archive ?
                                                handleTip : handleLike}
                                    >
                                        {
                                            archive ? "Send" : "Bet"
                                        }
                                    </Button>
                                </Col>
                            </Row>
                        }
                        title={
                            archive ? "Place Your Tips" : type === "random" ? "Like" : "Place Your Bet"}
                        trigger="click"
                        open={openPop}
                        onOpenChange={(open) => setOpenPop(open)}
                        getPopupContainer={getPopupContainer ?? null}
                    >

                        <Button
                            type={"text"}
                            className={styled.songInfoSvg}
                            onClick={() => setAmount(1)}
                        >
                            {
                                idVideo ?
                                    archive ? <RedTip /> : <RedHeart />
                                    :
                                    archive ? <Tip /> : <Heart />
                            }

                        </Button>
                    </Popover>

                    :
                    <Button
                        type={"text"}
                        className={styled.songInfoSvg}
                        onClick={handleLikes}
                    >
                        {
                            idVideo ?
                                archive ? <RedTip /> : type === 'random' ? <ThumbsupRed className={styled.like_icon}/> : <RedHeart />
                                :
                                archive ? <Tip /> : type === 'random' ? <Thumbsup className={styled.like_icon}/>  : <Heart />
                        }

                    </Button>
            )
        }
        // if (!userInfo.first_voted && localStorage.getItem('isAuth')) {
        //     return (
        //         <Popover
        //             content={
        //                 <Row justify={"center"} gutter={[20, 20]} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        //                     <Col span={24} style={{ maxWidth: 272 }}>
        //                         <p className={styled.notVoted}>
        //                             To unlock the video you need to bet 1 tkn
        //                         </p>
        //                     </Col>
        //                     <Col>
        //                         <Button
        //                             type={"primary"}
        //                             onClick={() => { navigate('/profile/buy-tokens') }}
        //                         >
        //                             Bet 1 TKN
        //                         </Button>
        //                     </Col>
        //                 </Row>
        //             }
        //             trigger="click"
        //             open={openPop}
        //             onOpenChange={(open) => setOpenPop(open)}
        //         >

        //             <Button
        //                 type={"text"}
        //                 className={styled.songInfoSvg}
        //             >
        //                 {
        //                     idVideo ?
        //                         <RedHeart />
        //                         :
        //                         <Heart />
        //                 }

        //             </Button>
        //         </Popover>
        //     )
        // }

        else if (localStorage.getItem('ageConfirm') && !localStorage.getItem('isAuth')) {
            return (
                <Popover
                    content={
                        <Row justify={"center"} gutter={[20, 20]} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Col span={24} style={{ maxWidth: 272 }}>
                                <p className={styled.notVoted}>
                                    To unlock the video you need to sign up first
                                </p>
                            </Col>
                            <Col>
                                <Button
                                    type={"primary"}
                                    onClick={() => navigate('/login')}
                                >
                                    Create account
                                </Button>
                            </Col>
                        </Row>
                    }
                    trigger="click"
                    open={openPop}
                    onOpenChange={(open) => setOpenPop(open)}
                >

                    <Button
                        type={"text"}
                        className={styled.songInfoSvg}
                    >
                        {
                            idVideo ?
                                archive ? <RedTip /> : <RedHeart />
                                :
                                archive ? <Tip /> : <Heart />
                        }

                    </Button>
                </Popover>
            )
        }


        else {
            return (
                <Button
                    disabled={true}
                    type={"text"}
                    className={styled.inactiveSongInfo}
                >
                    <Heart style={{ fill: "#FFF" }} />
                </Button>
            )
        }
    }


    return (
        <div className={styled.songInfo}>
            <div className={styled.SongInfoControllers} style={{ color: idVideo ? "#000" : "#fff" }}>
                <div className={styled.songInfoWin}>
                    {
                        firstVoted ?
                            <span >
                                {
                                    archive ? 'SEND TIP' : type === "random" ? "Like it" : "MAKE A BET"
                                }
                            </span>
                            :
                            type !== 'random' ?
                                <div>
                                    <Button onClick={() => { setAmount(1); setOpenPop(true) }} className={styled.money_button}><div className={styled.money_wrapper}>1$</div></Button>
                                    <Button onClick={() => { setAmount(5); setOpenPop(true) }} className={styled.money_button}><div className={styled.money_wrapper}>5$</div></Button>
                                    <Button onClick={() => { setAmount(10); setOpenPop(true) }} className={styled.money_button}><div className={styled.money_wrapper}>10$</div></Button>
                                </div>
                                : <></>
                    }

                </div>
                {popoverVisual()}
                {
                    archive ?
                        <div className={styled.songInfoLike}>{tips} TKN</div>
                        :
                        type === "random" ?
                            <div className={styled.songInfoLike}> {likes}</div>
                            :
                            <div className={styled.songInfoLike}>{votesCount} TKN</div>
                }
            </div>
        </div>
    );

};

export { SongInfo };
