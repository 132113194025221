import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { NotificationT } from '../../components/ToastifyNot/NotificationToastify';
import { Login } from './Login';
import { authAPI } from '../../api/api';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { closeLoginModalMenu, toggleForgotPasswordScene, toggleLoginScene } from '../../store/loginModalSlice';

import styled from './Login.module.scss';
import { Button, Form, Input } from 'antd';


interface IInitial {
  email?: string;
}

const ForgottenPassword = () => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  function navigateToSignIn() {
    dispatch(toggleLoginScene(null));
  }

  function navigateToCreateAccount() {
    dispatch(toggleLoginScene(null));
  }

  function logInData(values: IInitial) {
    authAPI
      .forgottenPassword(values.email)
      .then(() => {
        toast.success('Success');

      })
      .then(() => {
        console.log('then called');
      })
      .then(() => {
        if (localStorage.getItem('isAuth')) {
          navigate('/battles/tournament');
        }
        dispatch(closeLoginModalMenu(null));
      })
      .catch((error) => {
        toast.error('Something went wrong, please try again');

      });
  }

  return (
    <>
      <NotificationT />
      <Login>
        <>
          <h2 className={styled.header}>Forgotten password</h2>
          <Form
            layout="vertical"
            onFinish={(values) => {
              logInData(values)
            }}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ maxWidth: 300, width: "80%", marginTop: 30 }}
          >
            <Form.Item
              label="E-mail"
              name={"email"}
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "insert a correct email",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <div className={styled.btn}>

              <Button
                htmlType="submit"
                type={"default"}
                className={styled.btn2}
              >
                <span className={styled.submitBtn}>
                  Send
                </span>
              </Button>

              <Button
                onClick={() => navigateToCreateAccount()}
                type={"default"}
                className={styled.btn2}
              >
                Create new account
              </Button>

              <Button
                onClick={() => navigateToSignIn()}
                type={"default"}
                className={styled.btn2}
              >
                Have an Account? Login
              </Button>

            </div>
          </Form>
        </>
      </Login>
    </>
  )
};

export { ForgottenPassword };
