import { ConfigProvider } from 'antd';
import styled from './Login.module.scss';
import Logo from '../../assets/logo-new.svg';

interface IProps {
  children: JSX.Element;
}

const Login: React.FC<IProps> = ({ children }) => {
  return (
    <ConfigProvider
        theme={{
            components: {
              Button: {
                colorPrimary: '#C8102E',
                algorithm: true, // Enable algorithm
              },
            },
            token: {
                colorPrimary: '#C8102E',
                colorBgContainer: '#fff',
              },
          }}
        >
    <div className={styled.container} style={{ overflow: "hidden"}}>
      <div className={styled.loginWrapper}>
        <img className={styled.logo} src={Logo} alt='logo' />
        {children}
      </div>
    </div>
    </ConfigProvider>
  );
};


export { Login };
