import { Row, Col, Form, Radio, Button, notification, Popover } from "antd";
import React, { useEffect, useState } from "react";
import { HeaderGeneral } from "../../components/HeaderGeneral/HeaderGeneral";
import { crowdfundingAPI, profileAPI, superwetApi } from "../../api/api";
import s from './Superwet.module.scss'
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { ReactComponent as Info } from '../../assets/svg/info.svg'

import type { NotificationPlacement } from 'antd/es/notification/interface';
import { setUserData } from "../../store/playerSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import Crowdfounding from "./Crowndounding";
import { identity } from "@mantine/core/lib/core/factory/factory";
import { toast } from "react-toastify";
import { LoadingOutlined } from "@ant-design/icons";


const Superwet = () => {
    const [searchParams] = useSearchParams();
    const [models, setModels] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false)
    const [value, setValue] = useState(null)
    const [userInfo, setUserInfo] = useState<any>({});
    const [currentCP, setCurrentCP] = useState<any>()
    const [crFund, setCP] = useState<any>()
    const [api, contextHolder] = notification.useNotification();
    const [isLoading, setIsLoading] = useState(true)
    const [amount, setAmount] = useState(0)
    const dispatch = useAppDispatch();

    const { balance } = useAppSelector((state) => state.player);

    let superwetId: any = searchParams.get('id');





    const openNotification = (type, placement: NotificationPlacement, message) => {

        if (type === "success") {
            api.success({
                message: message,
                placement,
            });
        }
        if (type === 'error') {
            api.error({
                message: message,
                placement,
            });
        }
    };


    const onSubmit = ({ data: id }) => {
        setIsDisabled(true)
        superwetApi
            .postResult(id)
            .then((res) => {
                profileAPI
                    .getProfile()
                    .then((res) => {
                        dispatch(
                            setUserData({
                                first_name: res.first_name,
                                last_name: res.last_name,
                                nickname: res.nickname,
                                email: res.email,
                                gender: res.gender,
                                phone: res.phone,
                                id: res.id,
                                balance: res.amount,
                                avatar: res?.avatar,
                                first_voted: res?.data?.first_voted,
                                income: res?.transaction_sums.income,
                                expense: res?.transaction_sums.expense,
                                transactions: res?.videobet_transactions,
                                stocks: res?.stocks

                            })
                        )
                    });
                setTimeout(() => {
                    openNotification('success', 'topRight', "Success")
                    setValue(null)
                }, 1000)

            })
            .catch((error) => {
                setIsDisabled(true)
                setValue(null)
                openNotification('error', 'topRight', error.message)
            })

    }

    // useEffect(() => {
    //     crowdfundingAPI
    //         .getCrowdfunding()
    //         .then((res) => {
    //             return res.data
    //         })
    //         .then((res) => {
    //             setCP(res)
    //         })
    // }, [])

    // console.log(crFund)


    useEffect(() => {
        profileAPI
            .getProfile()
            .then((res) => {
                return res.data;
            })
            .then((res) => {
                setUserInfo(res);
            });
    }, []);


    useEffect(() => {
        setIsLoading(true)
        superwetId ? crowdfundingAPI
            .getCrowdfundingById(superwetId)
            .then((res) => {
                return res.data
            })
            .then((res) => {
                setCurrentCP(res)
                // console.log(res)
            })
            .then((res) => {
                setIsLoading(false)
            })
            :
            crowdfundingAPI
                .getCrowdfunding()
                .then((res) => {
                    return res.data
                })
                .then((res) => {
                    setCP(res)


                })
                .then((res) => {
                    setIsLoading(false)
                })
    }, [superwetId])



    useEffect(() => {
        setIsDisabled(balance < 3000 || value === null)
    }, [balance, value])

    const navigate = useNavigate()

    const [openPop, setOpenPop] = useState(false);

    if (isLoading) {
        return (
            <LoadingOutlined />
        )
    }

    const content = (
        <p className={s.description}>
        Private movie is an exclusive video, specially created by the model for those who purchase a ticket to the private screening. You have the opportunity to buy a ticket until the campaign countdown concludes. Filming will commence upon reaching the required amount, specified as the campaign goal. If the target amount isn't achieved by the end of the campaign countdown, the campaign will be halted, and funds for purchased tickets will be refunded to users' wallets in their Playporn.bet account.</p>
    );

    return (
        <div>
            <div className={s.info_wrapper}>
                <HeaderGeneral title="Private Film" style={{ marginLeft: 18, paddingRight: 0 }} />
                <Popover content={content}>
                    <Button type="link">
                        <Info />
                    </Button>
                </Popover>
            </div>
            {contextHolder}
            <Row>
                <Col span={24}>
                    <Col xs={23} lg={17}>
                          <div>
                            <div>
                                {
                                    superwetId ? <Crowdfounding
                                        id={superwetId}
                                        name={currentCP?.artist?.name}
                                        title={currentCP?.title}
                                        description={currentCP?.description}
                                        ended_at={currentCP?.ended_at}
                                        total_amount={currentCP?.current_amount}
                                        presentation={currentCP?.presentation.slideshow}
                                        created_at={currentCP?.created_at}
                                        target_amount={currentCP?.total_amount}

                                    /> :

                                        <div className={s.cr}>
                                            {
                                                crFund?.map((items) => {
                                                    return (
                                                        <Crowdfounding
                                                            id={items.id}
                                                            name={items?.artist?.name}
                                                            title={items?.title}
                                                            description={items?.description}
                                                            created_at={items?.created_at}
                                                            ended_at={items?.ended_at}
                                                            total_amount={items.current_amount}
                                                            presentation={items.presentation.slideshow}
                                                            target_amount={items?.total_amount}

                                                        />
                                                    )
                                                })
                                            }
                                        </div>

                                }
                            </div>
                        </div>
                    </Col>
                </Col>
            </Row>
        </div>
    )
}

export default Superwet;