import { Flex } from '@mantine/core';
import { ReactNode } from 'react';

type ControlsBlockProps = {
    children: ReactNode
}

export const ControlsBlock = ({ children }: ControlsBlockProps) => (
    <Flex gap={16} align={'center'} style={{ zIndex: 999 }}>
        {children}
    </Flex>
)
