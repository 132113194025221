import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { Button, Col, InputNumber, Popover, Row, Table, notification } from "antd";
import DetailsProfile from "../Profile/DetailsProfile";
import { ProfileLayout } from "../ProfileLayout/ProfileLayout";
import { NotificationT } from "../ToastifyNot/NotificationToastify";
import {sprintf} from 'sprintf-js'

import styles from './ProfileStock.module.scss'
import { profileAPI, stockApi } from "../../api/api";
import { setUserData } from "../../store/playerSlice";

const ProfileStock = () => {
  const dispatch = useAppDispatch();
  const { nickname, player_status, giver_status, sponsor_status, stocks } = useAppSelector((state) => state.player);
  const [data, setData] = useState<any>()
  const [amount, setAmount] = useState(null); 
  const [visible, setVisible] = useState(false);
  const [visiblePopoverId, setVisiblePopoverId] = useState(null);


  const [sellPrice, setSellPrice] = useState<any>()
  const isDesktop = useMediaQuery({
    query: "(min-width: 992px)",
  });

  const handleChange = (value) => {
    setAmount(value);
    console.log(amount)
};

const handleVisibleChange = (popoverId, newVisible) => {
  setVisiblePopoverId(newVisible ? popoverId : null);
  if (!newVisible) {
    setAmount(null);
  }
};


  const handleSellStocks = (artist_id, quantity) => {
    stockApi
      .sellStocks(artist_id, quantity)
      .then((res) => {
        notification.success({message: "Success"})
      })
      .then((res) => {
        profileAPI
              .getProfile()
              .then((res) => {
                    dispatch(
                          setUserData({
                                first_name: res.first_name,
                                last_name: res.last_name,
                                nickname: res.nickname,
                                email: res.email,
                                gender: res.gender,
                                phone: res.phone,
                                id: res.id,
                                balance: res.amount,
                                avatar: res?.avatar,
                                first_voted: res?.data?.first_voted,
                                income: res?.transaction_sums.income,
                                expense: res?.transaction_sums.expense,
                                transactions: res?.videobet_transactions,
                                stocks: res?.stocks
                          })
                    )
              })
              .catch(() => {
                notification.error({message: "Uh! Something is wrong"})
          })
  })
  }

  const status = (status: any) => {

    if (status === "bronze") {
      return (<span style={{ color: "#D2B48C" }}>Bronze</span>)
    } else if (status === "silver") {
      return (<span style={{ color: "#C0C0C0" }}>Silver</span>)
    } else if (status === "gold") {
      return (<span style={{ color: "#FFD700" }}>Gold</span>)
    } else if (status === "diamond") {
      return (<span style={{ color: "#7851A9" }}>Diamond</span>)
    }
  }

  const columns: any = [
    {
      title: 'Model',
      dataIndex: "artist_name",
      key: "artist_name"
    },
    {
      title: "Stocks",
      dataIndex: "shares_quantity",
      key: "shares_quantity"
    },
    {
      title: "Sell Price",
      dataIndex: "stock_price",
      key: "stock_price"
    },
    {
      title: "Actions",
      render: (index, record) => {
        const popoverId = record.artist_id;

        return (
          <Popover
          visible={visiblePopoverId === popoverId}
          onVisibleChange={(newVisible) => handleVisibleChange(popoverId, newVisible)}

          trigger={"click"}
          style={{ padding: 0, height: 'min-content'}}
          content={
            <Row className={styles.popover}>
               <Col span={24}>
                <h2>Selling</h2>
              </Col>
              <Col span={24}>
                <span>Quantity: {record.shares_quantity}</span>
              </Col>
              <Col span={24}>
                <span>Sell Price: {record.stock_price} per stock</span>
              </Col>
              <Col span={24}>
                <InputNumber value={amount} min={1} max={record.shares_quantity} onChange={handleChange} className={styles.input_num} placeholder="Amount"/>
              </Col>
              <Col span={24}>
                <Button onClick={() => handleSellStocks(record.artist_id, amount)}  type="primary" className={styles.sell_btn}>{amount === null ? 'Sell' : `+ ${sprintf('%.2f',amount*record.stock_price)} TKN`}</Button>
              </Col>
            </Row>
          }
          >
          <Button type="primary">Sell</Button>
          </Popover>
        )
      }

    }
  ]


  return (
    <ProfileLayout>
      <NotificationT />
      <Row gutter={[20, 20]}>
        <Col span={24}>
          {

            isDesktop ?

              <div>
                <h2 className={styles.accountName}>{nickname}</h2>
                {player_status ? <h3>{status(player_status)} Player</h3> : <></>}
                {sponsor_status ? <h3>{status(sponsor_status)} Sponsor</h3> : <></>}
                {giver_status ? <h3>{status(giver_status)} Producer</h3> : <></>}
              </div> : <></>
          }

        </Col>
        <Col span={24}>
          <DetailsProfile />
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col xs={24} lg={12} style={{ marginTop: 40 }}>
          <Table pagination={{ pageSize: 4 }}
            style={{ width: '100%' }}
            dataSource={stocks} columns={columns} />
        </Col>
      </Row>
    </ProfileLayout>
  )
};

export default ProfileStock;
