import { Flex, Transition } from '@mantine/core';
import { IconChevronsLeft, IconChevronsRight } from '@tabler/icons-react';

type MotionEffectProps = {
    affected: boolean;
    direction: 'left' | 'right'
}

export const MotionEffect = (props: MotionEffectProps) => {
    const { affected, direction } = props
    
    const dirProps = direction === 'left' ? { left: 100 } : { right: 100 }
    
    const Icon = direction === 'left' ? IconChevronsLeft : IconChevronsRight
    
    const transaction = direction === 'left' ? 'slide-left' : 'slide-right'
    
    return (
        <Transition mounted={ affected } transition={ transaction }>
            { (styles) => (
                <Flex
                    { ...dirProps }
                    pos={ 'absolute' }
                    top={ 'calc(50% - 18px)' }
                    style={ { ...styles, borderRadius: '50%' } }
                >
                    
                    <Flex
                        style={ { borderRadius: '50%' } }
                        bg={ 'rgba(0, 0, 0, 0.6)' }
                        p={ 12 }
                    >
                        <Icon style={ { color: 'white' } } size={ 24 } />
                    </Flex>
                </Flex>) }
        </Transition>
    )
}