export async function loadData(URL: string = "", { headers, ...options }: { headers: any }) {
	const response = await fetch(
		`${process.env.REACT_APP_BE_BASE_URL}/api/${URL}`,
		{
			method: "GET",
			credentials: "include",
			redirect: "follow",
			mode: "cors",
			headers: {
				"Content-Type": "application/json",
				"Charset": "utf-8",
				...headers
			},
			...options
		}
	);

	switch (response.status) {
		case 404:
			return Promise.reject(response);
		case 401:
			return Promise.reject(response);
		case 403:
			return Promise.reject(response);
		case 500:
			return Promise.reject({ message: "Ошибка сервера" });
		case 200:
			try {
				return await response.json();
			} catch {
				return Promise.resolve(response);
			}
		default:
			return await response.json();
	}
}