import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Row } from 'antd';
import { useMediaQuery } from 'react-responsive';

import Timer from '../../Timer';
import { SongCard } from '../../SongCard/SongCard';
import arrowRight from '../../../assets/arrow_right.svg'
import vs from '../../../assets/VS.png'

import s from '../BattleList.module.scss';

interface IProps {
    battle: any;
    idxBattle: number;
    currentVideoIdx?: number;
    currentBattleIdx?: number;
    battleEndTime: string;
    winnerCustomerId: number;
    battleId: number;
    pick?: any
    setPick?: any
    categoryId: number
    withoutPlay?: boolean

    type?: "string"
}

const BattleItem: React.FC<IProps> = ({
    battle,
    idxBattle,
    currentBattleIdx,
    winnerCustomerId,
    battleId,
    pick,
    setPick,
    categoryId,
    withoutPlay,
    // style = [{}, {}]
}) => {

    const navigate = useNavigate();
    const [videos, setVideos] = useState<any[]>([]);
    const [isVoted, setISVoted] = useState(false);
    const [archive, setArchive] = useState<any>()

    const { battle_customers } = battle

    const isMobile = useMediaQuery({
        query: "(max-width: 992px)",
    });

    useEffect(() => {
        if (battle && battle_customers) {
            const battleVideos = battle_customers
                .map(({ track, id, customer }: { track: any[], id: number, customer: any[] }) => {
                    return { ...track, id, battleId: battle.id, customer: { ...customer } };
                });
                
            setVideos(battleVideos);
        }
    }, [battle, battle_customers, pick?.touch]);

    let songRefs: any = [];

    function redirectToCurrentBasttle(id): void {
        // type = "archive" ? navigate(`archive/?id=${id}`)  :
        navigate(`/battle/?id=${id}`);
    }

    const location = useLocation();
    const { pathname } = location;
    

    const colorsBorder = [
        { backgroundColor: "#C8102E", borderRadius: 15, border: "10px solid #C8102E", padding: 0 },
        { backgroundColor: "#1D428A", padding: 0, borderRadius: 15, border: "10px solid #1D428A" }]

    
        
    if (!winnerCustomerId) {

        return (
            <>
                <Row gutter={isMobile ? 0 : [20, 20]}
                    style={{ padding: isMobile ? "0 15px" : "10px" }}
                >
                    {withoutPlay && <Col span={24}>
                        <Timer date={battle.date_end} />
                    </Col>}
                    {
                        videos.map((video: any, idx: number) => {
                            return (
                                <>
                                    <Col xs={24} lg={10} style={colorsBorder[idx]} >
                                        <SongCard
                                            songRef={(ref: any) => (songRefs[idx] = ref)}
                                            idxBattle={idxBattle}
                                            currentBattleIdx={currentBattleIdx}
                                            video={video}
                                            key={video.id}
                                            setISVoted={setISVoted}
                                            idx={idx}
                                            battleId={battleId}
                                            pick={pick}
                                            setPick={setPick}
                                            withoutPlay={withoutPlay}
                                            archive={location.pathname === '/history' ? true : false}
                                        />

                                    </Col>
                                    {
                                        idx === 0 && withoutPlay && (
                                            <Col xs={24} lg={4} style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                height: isMobile ? 120 : "auto",
                                                justifyContent: isMobile ? "space-between" : "center"
                                            }}>
                                                {isMobile && (
                                                    <div className={s.middle_content}>
                                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                            <h1 className={s.middle_content_vs}>VS</h1>
                                                            <img className={s.vs_icon} src={vs} alt="vs" />
                                                        </div>
                                                        <div style={{ position: "relative", top: -40, transform: "translate(-50%, -50%)" }}>
                                                            <Button
                                                                onClick={() => redirectToCurrentBasttle(battleId)}
                                                                className={s.battleBtn}
                                                            >
                                                                <Row className={s.middle_content_id} style={{ top: -25 }} justify={"space-between"} align={"middle"} >
                                                                    <Col span={20}>
                                                                        <p>Battle #{battleId}</p>
                                                                    </Col>

                                                                </Row>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                )}
                                                {!isMobile && (
                                                    <div className={s.middle_content}>
                                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                            <img className={s.vs_icon} src={vs} alt="vs" />
                                                        </div>
                                                        <div style={{ position: "relative", top: -60, left: "50%", transform: "translate(-50%, -50%)" }}>
                                                            <Button
                                                                onClick={() => redirectToCurrentBasttle(battleId)}
                                                                className={s.battleBtn}
                                                            >
                                                                <p>

                                                                    <Row className={s.middle_content_id} justify={"space-between"} align={"middle"} >
                                                                        <Col span={20}>
                                                                            <p>
                                                                                Battle #{battleId}
                                                                            </p>
                                                                        </Col>

                                                                    </Row>
                                                                </p>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                )}
                                            </Col>
                                        )
                                    }
                                    {
                                        idx === 0 && !withoutPlay && (
                                            <Col xs={24} lg={4} style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: isMobile ? "flex-end" : "center",
                                                height: isMobile ? 85 : "auto",
                                                justifyContent: "center"
                                            }}>
                                                <>
                                                    <div></div>
                                                    <div style={{ marginBottom: isMobile ? 30 : 60, marginTop: isMobile ? 30 : 60, marginLeft: 'auto', marginRight: 'auto' }} >
                                                        <div className={s.animatedText} >
                                                            <p style={{ fontWeight: 900, textAlign: "center", color: "#C8102E", fontSize: isMobile ? 14 : 20, fontStyle: "italic", margin: 'auto' }}>Place a bet and win!</p>
                                                        </div>
                                                    </div>
                                                </>
                                            </Col>
                                        )
                                    }
                                </>
                            )
                        })
                    }
                </Row>
            </>
        )
    }

    return <></>
};

export { BattleItem };
