import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button } from "antd";
import { useRequest } from 'ahooks';

import { battleAPI, musicAPI } from '../../../api/api';
import { HeaderGeneral } from '../../../components/HeaderGeneral/HeaderGeneral';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { loadMusicCategories, selectCategory } from '../../../store/musicCategoriesSlice';
import { Stages } from '../../../components/Stages';

import style from './newButtons.module.scss'
import styled from './ButtonsPage.module.scss';

import { useMediaQuery } from 'react-responsive';
import { HeaderTournament } from '../../../components/HeaderTournament/HeaderTournament';

import mockTournaments from "./tournament.mock";
import BattleRandom from '../../../components/BattleRandom';

interface IProps {
  title: string;
  id?: number;
  handleClick?: any;
  cover?: string;
}

// const Button: React.FC<IProps> = ({ title, id, handleClick, cover }) => {
//   return (
//     <button onClick={() => handleClick(id)} className={styled.btnSmall}>
//       <img
//         src={cover}
//         alt=""
//         style={{ objectFit: "cover", width: "-webkit-fill-available", height: "-webkit-fill-available", borderRadius: 9 }}
//       />
//       <div className={styled.btnTitle}>{title}</div>
//     </button>
//   );
// };



const tournamentMerge = (source = [], target = []) => {
  const current = source.slice();

  return target.slice().map((item) => {
    const internalIndex = current.findIndex(({ stage }) => item.stage === stage);

    if (internalIndex !== -1) {
      const internal = Object.assign({}, current[internalIndex]);

      current[internalIndex] = { stage: null };

      return internal;
    }

    return item;
  });
}



const ButtonsPage = () => {
  const [activeBtn, setActiveBtn] = useState(0);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const [categories, setCategories] = useState<any[]>([]);

  const isMobile = useMediaQuery({
    query: "(max-width: 430px)",
  });

  const { data, loading, error } = useRequest(musicAPI.getTournaments);

  // useEffect(() => {
  //   musicAPI.getCategories().then(categoriesResponse => {
  //     setCategories(categoriesResponse);
  //     dispatch(loadMusicCategories(categoriesResponse));
  //   });
  // }, [dispatch]);

  function redirectToTheFirstRound(id: number | null, name: string): void {

    dispatch(selectCategory({ id, name }));
    navigate(`/battle/all-battles?status=0&category_id=${id}&step=1`);
  }

  const tournaments = tournamentMerge(data, mockTournaments);

  function toggleTop(index: number): void {
    setActiveBtn(index);

  }

  // const tops = [
  //   { title: 'Tournament' },
  //   { title: 'Battle Random' }
  // ];


  return (
    <>
      <HeaderGeneral title='Tournament / 1st Season' />

      <div className={style.container}>
        <div className={style.btnsWrapper}>
          {/* {tops.map((el, index) => {
            return (
              <Button
              type='link'
              onClick={() => toggleTop(index)}
              key={el.title}
              className={activeBtn === index ? style.activeBtn : style.notActiveBtn}
              >
              <HeaderTournament style={ activeBtn === index ? {color: "#C8102E"} : {}} title={el.title} />
              </Button>
            );
          })}
          </div>
          
          {
            activeBtn === 0 && (
            <> */}

          <div className={styled.container}>
            <Row>
              <Col span={24} md={20} lg={18} xl={16} xxl={14}>
                <Stages items={tournaments} isLoading={loading} />
              </Col>
            </Row>
          </div>
          {/* </>
          )
        } */}
          {/* {
          activeBtn === 1 && (
            <BattleRandom/>
          )
        } */}

        </div>
      </div>
    </>
  );
};

export { ButtonsPage };
