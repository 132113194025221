import React, { useEffect, useState } from "react";
import { HeaderGeneral } from "../../components/HeaderGeneral/HeaderGeneral";
import { Button, Col, Row } from "antd";
import { count, log } from "console";
import { TopWinnersList } from "../../components/TopWinnersList/TopWinnersList";
import { battleAPI, statisticsAPI } from "../../api/api";
import styled from './Battle.module.scss'
import { LoadingOutlined } from "@ant-design/icons";
import { useSearchParams } from "react-router-dom";
import { SongCard } from "../../components/SongCard/SongCard";
import { useMediaQuery } from "react-responsive";
import { BattleItem } from "../../components/BattleList/BattleItem.tsx/BattleItem";
import { Loading } from "../Loading/Loading";
import vs from '../../assets/VS.png'
import { HeaderTournament } from "../../components/HeaderTournament/HeaderTournament";

const BattleArchive = ({ onlyResults }: { onlyResults?: boolean }) => {
  const [activeBtn, setActiveBtn] = useState(0);
  const [userStatistic, setUserStatistic] = useState<any>([]);
  const [battle, setBattle] = useState<any>()


  const [per, setPer] = useState<number>(3)
  const [page, setPage] = useState<number>(1)
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [searchParams] = useSearchParams();
  const [videos, setVideos] = useState<any[]>([]);
  const [isVoted, setISVoted] = useState(false);
  const withoutPlay = false
  const isShortDesktop = useMediaQuery({
    query: "(max-width: 1440px)",
  });

  let songRefs: any = [];

  function toggleTop(index: number): void {
    setActiveBtn(index);
  }

  const loadMoreData = (per: number, page: number) => {
    if (loading) {
      return;
    }
    setPage(page + 1)
    setLoading(true);

    battleAPI
      .getArchiveBattle()
      .then((body) => {

        setCount(body.count)
        setUserStatistic(body.data);
        setLoading(false);

      })
      .catch(() => {
      });
  }

  const loadBattle = () => {
    setLoading(true)
    localStorage.getItem('isAuth') ?
      battleAPI.getBattleById(archiveId)
        .then(data => {
          setBattle(data)
        })
        .then(() => {
          setLoading(false)
        })
      :
      battleAPI.getBattleByIdAnonim(archiveId)
        .then(data => {
          setBattle(data)
        })
        .then(() => {
        })
  }

  let archiveId: any = searchParams.get('id');

  useEffect(() => {
    loadMoreData(per, page);
    if (archiveId) {
      loadBattle()
    }
  }, [page, per, archiveId]);


  const isMobile = useMediaQuery({
    query: "(max-width: 992px)",
  });

  const { battle_customers } = battle || {};

  useEffect(() => {
    if (battle) {
      const battleVideos = battle_customers
        .map(({ track, id, customer }: { track: any[], id: number, customer: any[] }) => {
          return { ...track, id, battleId: battle.id, customer: { ...customer } };
        });

      setVideos(battleVideos);
    }
  }, [battle, battle_customers]);

  const colorsBorder = [
    { backgroundColor: "#C8102E", borderRadius: 15, border: "10px solid #C8102E", padding: 0 },
    { backgroundColor: "#1D428A", padding: 0, borderRadius: 15, border: "10px solid #1D428A" }]


  if (loading) {
    return (<LoadingOutlined />)
  }

  return (
    <>
      {
        !!archiveId ?
          <>
            <HeaderGeneral title='Battle History' />
            <Row

              style={{ padding: isMobile ? "0 15px" : "10px", margin: isMobile ? "0" : '20px' }}
            >

              {
                videos.map((video: any, idx: number) => {
                  return (
                    //   <TopWinnersList
                    //   page={page}
                    //   setPage={setPage}
                    //   per={per}
                    //   count={count}
                    //   loadMoreData={loadMoreData}
                    //   userStatistic={userStatistic}
                    //   coverStyle={{ height: 130 }}
                    //   isArchive={true}
                    //   type='archive'
                    // />
                    <>
                      <Col xs={24} lg={10} style={colorsBorder[idx]} >
                        <SongCard
                          archive
                          songRef={(ref: any) => (songRefs[idx] = ref)}
                          idxBattle={battle.id}
                          currentBattleIdx={battle.id}
                          video={video}
                          key={video.id}
                          setISVoted={setISVoted}
                          idx={idx}
                          battleId={battle.id}
                          pick={() => { }}
                          setPick={() => { }}
                          withoutPlay={withoutPlay}
                        />
                      </Col>
                      {
                        idx === 0 && !withoutPlay && (
                          <Col xs={24} lg={4} style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: isMobile ? "flex-end" : "center",
                            height: isMobile ? 85 : "auto",
                            justifyContent: "center"
                          }}>
                            <>
                              <div></div>
                              <div style={{ marginBottom: isMobile ? 30 : 60, marginTop: isMobile ? 30 : 60, marginLeft: 'auto', marginRight: 'auto' }} >
                                <div className={styled.animatedText} >
                                  <img className={styled.vs_icon} src={vs} alt="vs" />
                                </div>
                              </div>
                            </>
                          </Col>
                        )
                      }
                    </>
                  )
                })
              }
            </Row>
          </>




          :
          <>
            <HeaderGeneral title='Battle History' />
            <div className={styled.container}>

              <TopWinnersList
                page={page}
                setPage={setPage}
                per={per}
                count={count}
                loadMoreData={loadMoreData}
                userStatistic={userStatistic}
                coverStyle={{ height: 130 }}
                isArchive={true}
                type='archive'
              />
            </div>
          </>
      }

    </>
  )
}

export default BattleArchive;

