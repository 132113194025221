const Lock = () => {
    const width = 40.84388185654009;

    const height = 40;

    return (
<svg width={width} height={height} viewBox="0 0 605 711" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M502 281.5H103C81.1848 281.5 63.5 299.185 63.5 321V646C63.5 667.815 81.1848 685.5 103 685.5H502C523.815 685.5 541.5 667.815 541.5 646V321C541.5 299.185 523.815 281.5 502 281.5Z" stroke="white" stroke-width="51"/>
<path d="M134.064 288.265V197C132.397 158.833 162.627 33 297 33C431 33 469.064 150.5 471.564 197V288.265" stroke="white" stroke-width="48"/>
<path d="M257.788 515.986L258.636 515.135L257.788 515.986C266.882 525.046 278.372 535.869 292.255 548.455L292.26 548.459L301.498 556.758L302.501 557.658L303.503 556.758L312.739 548.459L312.742 548.457C326.679 535.871 338.185 525.046 347.253 515.984C356.327 506.917 363.554 498.819 368.915 491.695C374.276 484.573 378.072 478.01 380.248 472.014C382.414 466.045 383.5 459.975 383.5 453.812C383.5 441.567 379.352 431.244 371.047 422.944C362.741 414.645 352.41 410.5 340.157 410.5C332.607 410.5 325.463 412.268 318.748 415.795C312.571 419.039 307.155 423.571 302.499 429.368C297.843 423.571 292.428 419.039 286.251 415.795C279.535 412.268 272.392 410.5 264.841 410.5C252.589 410.5 242.259 414.645 233.953 422.944C225.648 431.244 221.5 441.567 221.5 453.812C221.5 459.975 222.586 466.045 224.752 472.014C226.928 478.01 230.724 484.573 236.085 491.695C241.447 498.82 248.687 506.918 257.788 515.986Z" fill="white" stroke="white" stroke-width="3"/>
</svg>

    )
}

export default Lock;

