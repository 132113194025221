import { Flex, Slider, Transition } from '@mantine/core';
import { IconVolume, IconVolume2, IconVolumeOff } from '@tabler/icons-react';
import React, { MouseEventHandler } from 'react';

import { IconButton } from './IconButton';

const getVolumeIcon = (volume: number) => {
    if (volume === 0) {
        return IconVolumeOff
    }
    
    if (volume <= 0.5) {
        return IconVolume2
    }
    
    return IconVolume
}

type VolumeControlProps = {
    visible: boolean;
    setVolumeVisible: (payload: boolean) => MouseEventHandler<HTMLElement>;
    
    volume: number;
    changeVolume: (vol: number) => void;
    toggleMute: () => void;
}

export const VolumeControl = (props: VolumeControlProps) => {
    const { visible, setVolumeVisible, volume, changeVolume, toggleMute } = props;
    
    const Icon = getVolumeIcon(volume)
    
    return (
        <Flex
            gap={ 8 }
            align={ 'center' }
            pr={ visible ? 20 : 0 }
            onMouseEnter={ setVolumeVisible(true) }
            onMouseLeave={ setVolumeVisible(false) }
        >
            <IconButton onClick={ toggleMute }>
                <Icon />
            </IconButton>
            <Transition mounted={ visible } transition={ 'scale-x' }>
                { styles => (
                    <Slider
                        style={ { width: 50, ...styles } }
                        label={ null }
                        color="white"
                        size="xs"
                        value={ volume }
                        defaultValue={ 0 }
                        min={ 0 }
                        max={ 1 }
                        step={ 0.0005 }
                        onChange={ changeVolume }
                    />) }
            </Transition>
        </Flex>
    )
}