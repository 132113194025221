import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { NotificationT } from '../../components/ToastifyNot/NotificationToastify';
import { authAPI } from '../../api/api';

import { Login } from './Login';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { toggleLoginScene, closeLoginModalMenu } from '../../store/loginModalSlice';
import { userLogIn } from '../../store/isAuthSlice';

import styled from './Login.module.scss';
import { Button, Form, Input } from 'antd';
import MaskedInput from '../../components/Form/MaskedInput';

import { useNavigate } from 'react-router-dom';

interface IInitial {
  first_name?: string;
  last_name?: string;
  email?: string;
  password?: string;
  c_password?: string;
  phone?: string;
  nickname?: string
}

const Registration = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  function sendDataReg(values: IInitial) {
    authAPI
      .signUp(
        values.nickname,
        // values.last_name,
        values.email,
        values.password,
        values.c_password,
       // values.phone,
      )
      .then((res) => {
        dispatch(userLogIn(null));
        toast.success("Now login using your username and password")
        setTimeout(() => navigateToSignIn(), 2000);
      })
      .then((res) => {
        authAPI
        .logIn(values.email, values.password)
        .then(() => {
          dispatch(userLogIn(null));
        })
        .then(() => {
          authAPI
            .authMe()
            .then(() => {
              localStorage.setItem('isAuth', "true")
              navigate('/battles/tournament');
              dispatch(closeLoginModalMenu(null));
            })
            .catch(() => {
              navigate("/login")
            })
        })
        .catch((error) => {
          if (error.response) {
            toast.error('Wrong email or password, please try again');
          } else {
            toast.error('Something went wrong, please try again');
          }
        });
      })
      .catch((error) => {
        if (error.response.data.nickname) {
          toast.error(error?.response?.data?.nickname?.[0]);
        } else if (error.response.data.email) {
          toast.error(error.response.data.email[0]);
        }
        // else if (error.response.data.phone) {
        //   toast.error(error.response.data.phone[0]);
        // }
        else {
          toast.error('Something went wrong, please try again');
        }
      });
  }

  function navigateToSignIn() {
    dispatch(toggleLoginScene(null));
  }

  return (
    <>
      <NotificationT />
      <Login>
        <>
          <h2 className={styled.header}>Sign Up</h2>
          <Form
            layout="vertical"
            onFinish={(values) => { sendDataReg(values) }}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ maxWidth: 300, width: "80%", marginTop: 30 }}
          >
            <Form.Item
              label="Nickname"
              name={"nickname"}
              rules={[
                {
                  required: true,
                  type: "string",
                  message: "insert a correct email",
                },
              ]}
            >
              <Input />
            </Form.Item>

            {/* <Form.Item
              label="First Name"
              name={"last_name"}
              rules={[
                {
                  required: true,
                  type: "string",
                  message: "Введите корректный email",
                },
              ]}
            >
              <Input />
            </Form.Item> */}

            <Form.Item
              label="Email"
              name={"email"}
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "insert a correct email",
                },
              ]}
            >
              <Input />
            </Form.Item>

            {/* <Form.Item
              label="Phone"
              required
              name={"phone"}
              rules={[
                {
                  required: true,
                  message: "type phone",
                },
              ]}
            >
              <MaskedInput />
            </Form.Item> */}

            <Form.Item
              label="Password"
              required
              name={"password"}
              rules={[{ required: true, message: "insert the password" }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="Confirm password"
              required
              name={"c_password"}
              rules={[{ required: true, message: "insert the password" }]}
            >
              <Input.Password />
            </Form.Item>

            <div className={styled.btn}>
              <Button
                htmlType="submit"
                type={"default"}
                className={styled.btn2}
              >
                <span className={styled.submitBtn}>Send</span>
              </Button>
              <Button
                onClick={() => navigateToSignIn()}
                type={"default"}
                className={styled.btn2}
              >
                Have an Account? Login
              </Button>
            </div>
          </Form>
        </>
      </Login>
    </>
  );
};

export { Registration };
