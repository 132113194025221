import get from "lodash/get";
import { loadData } from "./utils";

function getS3Path(name: string, type: string) {
	const url = `s3/sign/?objectName=${name}&contentType=${type}&kind=playporn-uploads&path=content`;
	const options = {
		headers: {
			"x-amz-acl": "public-read",
			// "Cache-Control": "max-age=31536000",
		}
	};

	return loadData(url, options)
		.then((data: any) => get(data, "signedUrl", ""))
		.catch((error: object) => error);
}

export function putToS3(image: any, name = "image-name", contentType = "image/webp") {
	return getS3Path(name, contentType)
		.then((url: string) => {
			return fetch(image)
				.then((result) => {
					return result.arrayBuffer().then((buffer) => {
						const body = new File([buffer], "image_data_url.jpg", {
							type: contentType,
						});
						const headers = new Headers();

						headers.append("x-amz-acl", "public-read");
						headers.append("Cache-Control", "no-cache");
						headers.append("Content-Type", contentType);
						headers.append(
							"Content-Disposition",
							`inline; filename=""`
						);
						const request = new Request(url, {
							headers,
							method: "PUT",
							body,
						});

						return fetch(request)
							.then(response => {
								if (response.status === 200) {
									return response.url.split("?")[0];
								} else {
									return null;
								}
							})
							.catch(error => {
								return error
							});
					});
				})
				.catch(error => error);
		})
		.catch((error: any) => console.error(error));
}
