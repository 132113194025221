import { Link, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { closeMobileMenu } from '../../store/mobileMenuSlice';
import { ReactComponent as Cross } from '../../assets/svg/mobile-icons/cross.svg';
import { ReactComponent as User } from '../../assets/svg/mobile-icons/user.svg';
import Logo from '../../assets/logo-new.svg';
import { ReactComponent as Person } from '../../assets/person.svg';
import { ReactComponent as Swords } from '../../assets/swords.svg';
import { ReactComponent as Trophy } from '../../assets/trophy.svg';
import { ReactComponent as Spin } from '../../assets/spinn.svg';
import { ReactComponent as Support } from '../../assets/contact_support.svg';
import { ReactComponent as Rules } from '../../assets/rules.svg';
import { ReactComponent as Star } from '../../assets/svg/superwet.svg'
import { ReactComponent as TwitterX } from '../../assets/twitter-x.svg';
import { ReactComponent as Archive} from '../../assets/svg/archive.svg'
import { InstagramOutlined, } from '@ant-design/icons';
import { ReactComponent as Random} from '../../assets/random.svg'
import { ReactComponent as Cycle} from '../../assets/svg/cycle_FILL0_wght300_GRAD0_opsz24.svg';
import { ReactComponent as Visibility} from '../../assets/svg/visibility_lock_FILL0_wght300_GRAD0_opsz24.svg';
import { ReactComponent as Chart} from '../../assets/svg/monitoring.svg'

import styled from './MobileMenu.module.scss';
import { useState } from 'react';
import { useEffect } from 'react';
import { openLoginModalMenu, toggleLoginScene } from "../../store/loginModalSlice";
import { Button, Col, Row } from 'antd';

const social = [
  { name: "twitter", url: "https://twitter.com/Playporn_bet", svg: <TwitterX style={{ width: 29 }} /> },
]

const MobileMenu = () => {
  const isOpen = useAppSelector((state) => state.mobileMenu.isOpen);
  const navigate = useNavigate();
  const [isAuth, setIsAuth] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('isAuth')) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  }, []);

  function openProfile() {
    dispatch(closeMobileMenu(null));
    navigate('/profile/settings');
  }

  function openModal() {
    dispatch(closeMobileMenu(null));
    dispatch(toggleLoginScene(null));
    dispatch(openLoginModalMenu(null));
  }

  function openNotifications() {
    dispatch(closeMobileMenu(null));
    navigate('/profile/notification');
  }

  const [searchValue, setSearchValue] = useState("");
  function onClickLoupe() {
    dispatch(closeMobileMenu(null));
    navigate(`/buy-nft?search=${searchValue}`)
  }
  const dispatch = useAppDispatch();

  useEffect(() => {
    // document.body.style.overflow = 'hidden';

    return () => {
      // document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <div className={styled.mobileMenu}>
      {isOpen && (
        <nav className={styled.mobileNavigationWrapper}>
          <div className={styled.iconsWrapper}>
            <Cross
              className={styled.navIcons}
              onClick={() => dispatch(closeMobileMenu(null))}
            />
            <img
              className={styled.logo}
              src={Logo}
              alt=''
              width={200}
            />
            {/* <Logo className={styled.logo} /> */}
            <div className={styled.userInfoIconsWrapper}>
              {/*<Notification onClick={openNotifications} className={styled.navIcons} />*/}
              {/*<User onClick={openProfile} className={styled.navIcons} />*/}
              {!isAuth && <User className={styled.navIcons} onClick={() => openModal()} title='Sign Up Free' />}
              {/*{isAuth && <WhiteBtn handleClick={() => logout()} title='logout'/>}*/}
            </div>
          </div>

          {/* main */}
          <div className={styled.mainNavigation}>
            <>
              {
                localStorage.getItem('isAuth') ?
                  <Button
                    onClick={() => {
                      dispatch(closeMobileMenu(null))
                      navigate('/profile/settings')
                    }}
                    className={styled.button}
                  >
                    <Row gutter={[10, 10]} justify={"center"} className={styled.bthContent}>
                      <Col><Person className={styled.icon} /></Col>
                      <Col><span>Account</span></Col>
                    </Row>
                  </Button>
                  : <></>
              }

              <Button
                onClick={() => {
                  dispatch(closeMobileMenu(null))
                  navigate('/battles/tournament')
                }}
                className={styled.button}
              >
                <Row gutter={[10, 10]} justify={"center"} className={styled.bthContent}>
                  <Col><Swords className={styled.icon} /></Col>
                  <Col><span>Battle Tournament</span></Col>
                </Row>
              </Button>
              <Button
                onClick={() => {
                  dispatch(closeMobileMenu(null))
                  navigate('random')
                }}
                className={styled.button}
              >
                <Row gutter={[10, 10]} justify={"center"} className={styled.bthContent}>
                  <Col><Random className={styled.icon} /></Col>
                  <Col><span>Battle Random</span></Col>
                </Row>
              </Button>
              <Button
                onClick={() => {
                  dispatch(closeMobileMenu(null))
                  navigate('/history')
                }}
                className={styled.button}
              >
                <Row gutter={[10, 10]} justify={"center"} className={styled.bthContent}>
                  <Col><Archive className={styled.icon} /></Col>
                  <Col><span>Battle History</span></Col>
                </Row>
              </Button>
              <Button
                onClick={() => {
                  dispatch(closeMobileMenu(null))
                  navigate('/hall-of-fame')
                }}
                className={styled.button}
              >
                <Row gutter={[10, 10]} justify={"center"} className={styled.bthContent}>
                  <Col><Trophy className={styled.icon} /></Col>
                  <Col><span>Hall of Fame</span></Col>
                </Row>
              </Button>
              <Button
                onClick={() => {
                  dispatch(closeMobileMenu(null))
                  navigate('/stock-exchange')
                }}
                className={styled.button}
              >
                <Row gutter={[10, 10]} justify={"center"} className={styled.bthContent}>
                  <Col><Chart className={styled.icon} /></Col>
                  <Col><span>Stock Exchange</span></Col>
                </Row>
              </Button>
              <Button
                onClick={() => {
                  dispatch(closeMobileMenu(null))
                  navigate('/lucky-spin')
                }}
                className={styled.button}
              >
                <Row gutter={[10, 10]} justify={"center"} className={styled.bthContent}>
                  <Col><Cycle style={{ width: 24, heigth: 24 }} className={styled.icon} /></Col>
                  <Col><span>Lucky Spin</span></Col>
                </Row>
              </Button>
              <Button
                onClick={() => {
                  dispatch(closeMobileMenu(null))
                  navigate('/private-film')
                }}
                className={styled.button}
              >
                <Row gutter={[10, 10]} justify={"center"} className={styled.bthContent}>
                  <Col><Visibility style={{ width: 24, heigth: 24 }} className={styled.icon} /></Col>
                  <Col><span>Private Film</span></Col>
                </Row>
              </Button>
              <Button
                onClick={() => {
                  dispatch(closeMobileMenu(null))
                  navigate('/contacts')
                }}
                className={styled.button}
              >
                <Row gutter={[10, 10]} justify={"center"} className={styled.bthContent}>
                  <Col><Support style={{ width: 24, heigth: 24 }} className={styled.icon} /></Col>
                  <Col><span>Contact us</span></Col>
                </Row>
              </Button>
            </>

            <Button
              onClick={() => {
                dispatch(closeMobileMenu(null))
                navigate('/game-rules')
              }}
              className={styled.button}
            >
              <Row gutter={[10, 10]} justify={"center"} className={styled.bthContent}>
                <Col><Rules style={{ width: 24, heigth: 24 }} className={styled.icon} /></Col>
                <Col><span>Game Rules</span></Col>
              </Row>
            </Button>
          </div>

          <div className={styled.social_media}>
            {
              social.map(({ svg, url, name }) => {
                return (
                  <a href={url} key={name}>
                    <div style={{ margin: "20px 10px", display: "flex", justifyContent: "center" }}>
                    {svg} <span style={{fontSize: 14, color: "#000"}}>Follow Us</span>
                    </div>
                  </a>
                )
              })
            }
          </div>
        </nav>
      )}
    </div>
  );
};

export { MobileMenu };
