import { ProfileLayout } from '../ProfileLayout/ProfileLayout';

import { Button, Col, Form, Input, Row, Table } from 'antd';
import DetailsProfile from '../Profile/DetailsProfile';
import { useAppSelector } from '../../hooks/reduxHooks';

import s from './ProfileWallet.module.scss';
import { ChangeEvent, useEffect, useState } from 'react';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';

const ProfileWallet = () => {


  const { firstName, lastName, nickname, transactions, sponsor_status, giver_status, player_status } = useAppSelector((state) => state.player);

  const columns: any = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      sorter: (a, b) => b.id - a.id,
      defaultSortOrder: 'ascend'
    },
    {
      title: "Action",
      render: (data) => {
        if (data.kind === "tip") {
          return (
            <>
              <div>Tip</div>
              <div>Model: <br /> {data?.artist?.name}</div>
            </>
          )
        }
        if (data.battle_id) {
          return (
            <>
              <div>Battle</div>
              <div>battle id: {data.battle_id}</div>
              <div>video id: {data?.vote?.track_id || "-"}</div>
            </>
          )

        }
        if (data.kind === "lucky_spin") {
          return (
            <>
              <div>Lucky Spin</div>
              <div>Model: {data?.artist?.name}</div>
            </>
          )
        }

        if (data.kind === "superwet") {
          return (
            <>
              <div>Superwet</div>
              <div>Model: {data?.artist?.name}</div>
            </>
          )
        }

        if (data.kind === 'give') {
          return (
            <>
              <div>Crowdfunding</div>
              <div>ID: {data?.crowdfunding_project_id}</div>
            </>
          )
        }
        return <span>-</span>
      }
    },
    {
      title: 'Bet/Win',
      // dataIndex: 'amount',
      // key: 'amount',
      align: 'center',
      render: (data) => {
        if (data.amount > 0) return <span style={{ color: "green" }}>+{data.amount}
          {
            data.kind === "tip" ? <> tips</> : <> tkn</>
          }
        </span>
        if (data.amount < 0) return <span style={{ color: "red" }}>{data.amount}
          {
            data.kind === "tip" ? <> tips</> : <> tkn</>
          }
        </span>
        return data.amount
      }
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      align: 'center',
      render: (created_at: string) => moment(created_at).format("DD.MM.YY HH:mm")
    },
    // {
    //   title: 'Updated',
    //   dataIndex: 'updated_at',
    //   key: 'updated_at',
    //   align: 'center',
    //   render: (updated_at: string) => moment(updated_at).format("DD.MM.YYYY HH:mm")
    // },
  ]

  const isDesktop = useMediaQuery({
    query: "(min-width: 992px)",
  });


  const status = (status: any) => {

    if (status === "bronze") {
      return (<span style={{ color: "#D2B48C" }}>Bronze</span>)
    } else if (status === "silver") {
      return (<span style={{ color: "#C0C0C0" }}>Silver</span>)
    } else if (status === "gold") {
      return (<span style={{ color: "#FFD700" }}>Gold</span>)
    } else if (status === "diamond") {
      return (<span style={{ color: "#7851A9" }}>Diamond</span>)
    }
  }

  return (
    <ProfileLayout>
      <Row >
        <Col xs={24} lg={15} xl={13} xxl={10}>
          <Row gutter={[20, 20]}>
            <Col span={24}>
              {

                isDesktop ?

                  <div>
                    <h2 className={s.accountName}>{nickname}</h2>
                    {player_status ? <h3>{status(player_status)} Player</h3> : <></>}
                    {sponsor_status ? <h3>{status(sponsor_status)} Sponsor</h3> : <></>}
                    {giver_status ? <h3>{status(giver_status)} Producer</h3> : <></>}
                  </div> : <></>
              }
            </Col>
            <Col span={24}>
              <DetailsProfile />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: 40 }}>
        <Col xs={24} lg={16} xl={20}>
          <Row gutter={[20, 20]}>
            <Col span={24}>
              {/* <span className={s.text_bold}>First Name:</span> <span className={s.text}>{firstName}</span> */}
            </Col>
            <Col span={24}>
              <Table pagination={{ pageSize: 4 }}
                style={{ width: '100%' }}
                dataSource={transactions} columns={columns} />
            </Col>
          </Row>
        </Col>

      </Row>
    </ProfileLayout>
  )
};

export default ProfileWallet;
