import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type PlayerType = {
  songId: number | null;
  volume: number;
  prevVolume: number;
  currentTime: string;
  totalTime: string;
  songUrl: string;
  songOpenseaUrl: string;
  songTitle: string;
  songSinger: string;
  songSubs: Array<Object> | null;
  songImage: string | null;
  genre: string | null;
  videoId: number | string;
  videoUrl: string;
  videoOpenseaUrl: string;
  videoTitle: string;
  videoSinger: string;
  videoSubs: Array<Object> | null;
  videoImage: string | null;
  vote_title: string;
  vote: (id: number) => void;
  vote_singer: string;
  vote_video: any;
  setISVoted: (v: boolean) => void;
  vote_id: number;
  categoriesNames: string[];
  artistsNames: string[];
  battleId?: number
  pick?: any
  setPick?: any
  userId: number
  firstName: string,
  lastName: string,
  nickname?: string,
  email: string,
  gender: string,
  phone: string,
  balance: any,
  income: any,
  expense: any,
  transactions: any,
  stocks?: any
  avatar: any
  isAge: boolean
  setOnVotee: () => void
  isBlockedRoute: boolean
  firstVoted?: boolean | undefined,
  player_status?: any,
  giver_status?: any,
  sponsor_status?: any
};


const initialState: PlayerType = {
  songId: null,
  volume: 0.5,
  prevVolume: 0,
  currentTime: "0",
  totalTime: "0",
  songUrl: "",
  songOpenseaUrl: "",
  songImage: "",
  songTitle: "",
  songSinger: "",
  genre: "",
  songSubs: [],
  videoId: 0,
  videoUrl: "",
  videoOpenseaUrl: "",
  videoTitle: "",
  videoSinger: "",
  videoSubs: [],
  videoImage: "",
  vote_title: "",
  vote: () => null,
  vote_singer: "",
  vote_video: {id: 0},
  setISVoted: () => null,
  vote_id: 0,
  categoriesNames: [""],
  artistsNames: [""],
  userId: 0,
  firstName: "",
  lastName: "",
  nickname: "",
  email: "",
  gender: "",
  phone: "",
  balance: 0,
  income: 0,
  expense: 0,
  transactions: [],
  stocks: [],
  avatar: null,
  isAge: false,
  setOnVotee: () => null,
  isBlockedRoute: false,
  firstVoted: undefined,
  player_status: "",
  sponsor_status: "",
  giver_status: ""
};

type SetSongData = {
  id: number;
  url: string;
  openseaUrl: string;
  image: string;
  title: string;
  singer: string;
  genre: string;
  lyrics: Array<Object>;
};

type SetVideoData = {
  id: number;
  url: string;
  image: string;
  title: string;
  genre: string;
  categories_names: string[];
  artists_names: string[];
  battleId?: number
};

type SetVoteData = {
  vote_title: string;
  vote_singer: string;
  vote_video: {
    id: number;
  };
  setISVoted: (v: boolean) => void;
  vote_id: number;
  vote: (id: number) => void;
  pick: any;
  setPick: any;
};

type SetUserData = {
  id: number
  first_name: string;
  last_name: string;
  nickname?: string;
  email: string;
  gender: string;
  phone: string;
  balance: any;
  income: any;
  expense: any;
  transactions: any
  stocks: any
  avatar: any;
  first_voted?: boolean;
  giver_status?: any;
  player_status?: any;
  sponsor_status?: any
}

type SetCurrentBattle = {
  id: number
  videos: any[]
  timeToFinish: any
  categoryId: number
}

const playerSlice = createSlice({
  name: "player",
  initialState,
  reducers: {
    setVolume(state, action: PayloadAction<number>) {
      state.volume = action.payload;
    },
    setPrevVolume(state, action: PayloadAction<number>) {
      state.prevVolume = action.payload;
    },

    setCurrentTime(state, action: PayloadAction<string>) {
      state.currentTime = action.payload;
    },

    setTotalTime(state, action: PayloadAction<string>) {
      state.totalTime = action.payload;
    },
    setVideoId(state, action: PayloadAction<number>) {
      state.videoId = action.payload;
    },
    setSongUrl(state, action: PayloadAction<string>) {
      state.songUrl = action.payload;
    },
    setSongOpenseaUrl(state, action: PayloadAction<string>) {
      state.songOpenseaUrl = action.payload;
    },
    setSongTitle(state, action: PayloadAction<string>) {
      state.songTitle = action.payload;
    },
    setSongSinger(state, action: PayloadAction<string>) {
      state.songSinger = action.payload;
    },
    setSongSubs(state, action: PayloadAction<Array<Object>>) {
      state.songSubs = action.payload;
    },
    setSongImage(state, action: PayloadAction<string>) {
      state.songImage = action.payload;
    },

    setSongGenre(state, action: PayloadAction<string>) {
      state.genre = action.payload;
    },
    setSongData(state, action: PayloadAction<SetSongData>) {
      state.songId = action.payload.id;
      state.songUrl = action.payload.url;
      state.songOpenseaUrl = action.payload.openseaUrl;
      state.songImage = action.payload.image;
      state.songTitle = action.payload.title;
      state.songSinger = action.payload.singer;
      state.genre = action.payload.genre;
      state.songSubs = action.payload.lyrics;
    },
    setVideoData(state, action: PayloadAction<SetVideoData>) {
      // console.log("setVideoData", {action})
      state.artistsNames = action.payload.artists_names;
      state.categoriesNames = action.payload.categories_names;
      state.videoId = action.payload.id;
      state.battleId = action.payload.battleId;
      state.videoUrl = action.payload.url;
      state.videoImage = action.payload.image;
      state.videoTitle = action.payload.title;
      state.genre = action.payload.genre;
      // @ts-ignore
      state.more = action.payload;
    },
    setVoteData(state, action: PayloadAction<SetVoteData>) {
      state.vote_title = action.payload.vote_title;
      state.vote = action.payload.vote;
      state.vote_singer = action.payload.vote_singer;
      state.vote_video = action.payload.vote_video;
      state.setISVoted = action.payload.setISVoted;
      state.vote_id = action.payload.vote_id
      state.pick = action.payload.pick
      state.setPick = action.payload.setPick
    },
    setUserData(state, action: PayloadAction<SetUserData>) {
      state.userId = action.payload.id;
      state.firstName = action.payload.first_name;
      state.lastName = action.payload.last_name;
      state.nickname = action.payload.nickname;
      state.email = action.payload.email;
      state.gender = action.payload.gender;
      state.phone = action.payload.phone;
      state.balance = action.payload.balance;
      state.expense = action.payload.expense;
      state.transactions = action.payload.transactions;
      state.stocks = action.payload.stocks;
      state.income = action.payload.income
      state.avatar = action.payload.avatar;
      state.firstVoted = action.payload.first_voted;
      state.player_status = action.payload.player_status;
      state.sponsor_status = action.payload.sponsor_status;
      state.giver_status = action.payload.giver_status;

    },
    setIsAge(state, action: PayloadAction<any>) {
      state.isAge = action.payload
    },
    setOnVotee(state, action: PayloadAction<any>) {
      state.setOnVotee = action.payload
    },
    setIsBlockedRoute(state, action: PayloadAction<any>) {
      state.isBlockedRoute = action.payload
    },
  },
});



export const {
  setVolume,
  setPrevVolume,
  setCurrentTime,
  setTotalTime,
  setVideoId,
  setSongUrl,
  setSongOpenseaUrl,
  setSongTitle,
  setSongSinger,
  setSongSubs,
  setSongImage,
  setSongData,
  setVideoData,
  setSongGenre,
  setVoteData,
  setUserData,
  setIsAge,
  setOnVotee,
  setIsBlockedRoute
} = playerSlice.actions;

export default playerSlice.reducer;
