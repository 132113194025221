export default [
    {
        "id": null,
        "date_end": null,
        "date_start": null,
        "step": 1,
        "stage": 1,
        "status": 0,
        "custom": false,
        "winner_track_id": null,
        "winner_points": null,
        "created_at": null,
        "updated_at": null,
        "music_category_id": null,
        "is_count_tme": 1,
        "play_time": 120,
        "battle_customers": [
            {
                "id": null,
                "track": {
                    "votes_count": 0,
                    "id": null,
                    "name": null,
                    "year": null,
                    "price": null,
                    "file_url": null,
                    "logo_video_url": null,
                    "video_240_url": null,
                    "video_360_url": null,
                    "video_480_url": null,
                    "video_720_url": null,
                    "video_1080_url": null,
                    "favorites_count": null,
                    "is_you_favorite": 0,
                    "win_count": 0,
                    "image": null,
                    "artists_names": [],
                    "categories_names": []
                }
            },
            {
                "id": null,
                "track": {
                    "votes_count": 0,
                    "id": null,
                    "name": null,
                    "year": null,
                    "price": null,
                    "file_url": null,
                    "logo_video_url": null,
                    "video_240_url": null,
                    "video_360_url": null,
                    "video_480_url": null,
                    "video_720_url": null,
                    "video_1080_url": null,
                    "favorites_count": null,
                    "is_you_favorite": 0,
                    "win_count": 0,
                    "image": null,
                    "artists_names": [],
                    "categories_names": []
                }
            }
        ]
    },
    {
        "id": null,
        "date_end": null,
        "date_start": null,
        "step": 1,
        "stage": 1,
        "status": 0,
        "custom": false,
        "winner_track_id": null,
        "winner_points": null,
        "created_at": null,
        "updated_at": null,
        "music_category_id": null,
        "is_count_tme": 1,
        "play_time": 120,
        "battle_customers": [
            {
                "id": null,
                "track": {
                    "votes_count": 0,
                    "id": null,
                    "name": null,
                    "year": null,
                    "price": null,
                    "file_url": null,
                    "logo_video_url": null,
                    "video_240_url": null,
                    "video_360_url": null,
                    "video_480_url": null,
                    "video_720_url": null,
                    "video_1080_url": null,
                    "favorites_count": null,
                    "is_you_favorite": 0,
                    "win_count": 0,
                    "image": null,
                    "artists_names": [],
                    "categories_names": []
                }
            },
            {
                "id": null,
                "track": {
                    "votes_count": 0,
                    "id": null,
                    "name": null,
                    "year": null,
                    "price": null,
                    "file_url": null,
                    "logo_video_url": null,
                    "video_240_url": null,
                    "video_360_url": null,
                    "video_480_url": null,
                    "video_720_url": null,
                    "video_1080_url": null,
                    "favorites_count": null,
                    "is_you_favorite": 0,
                    "win_count": 0,
                    "image": null,
                    "artists_names": [],
                    "categories_names": []
                }
            }
        ]
    },
    {
        "id": null,
        "date_end": null,
        "date_start": null,
        "step": 1,
        "stage": 1,
        "status": 0,
        "custom": false,
        "winner_track_id": null,
        "winner_points": null,
        "created_at": null,
        "updated_at": null,
        "music_category_id": null,
        "is_count_tme": 1,
        "play_time": 120,
        "battle_customers": [
            {
                "id": null,
                "track": {
                    "votes_count": 0,
                    "id": null,
                    "name": null,
                    "year": null,
                    "price": null,
                    "file_url": null,
                    "logo_video_url": null,
                    "video_240_url": null,
                    "video_360_url": null,
                    "video_480_url": null,
                    "video_720_url": null,
                    "video_1080_url": null,
                    "favorites_count": null,
                    "is_you_favorite": 0,
                    "win_count": 0,
                    "image": null,
                    "artists_names": [],
                    "categories_names": []
                }
            },
            {
                "id": null,
                "track": {
                    "votes_count": 0,
                    "id": null,
                    "name": null,
                    "year": null,
                    "price": null,
                    "file_url": null,
                    "logo_video_url": null,
                    "video_240_url": null,
                    "video_360_url": null,
                    "video_480_url": null,
                    "video_720_url": null,
                    "video_1080_url": null,
                    "favorites_count": null,
                    "is_you_favorite": 0,
                    "win_count": 0,
                    "image": null,
                    "artists_names": [],
                    "categories_names": []
                }
            }
        ]
    },
    {
        "id": null,
        "date_end": null,
        "date_start": null,
        "step": 1,
        "stage": 1,
        "status": 0,
        "custom": false,
        "winner_track_id": null,
        "winner_points": null,
        "created_at": null,
        "updated_at": null,
        "music_category_id": null,
        "is_count_tme": 1,
        "play_time": 120,
        "battle_customers": [
            {
                "id": null,
                "track": {
                    "votes_count": 0,
                    "id": null,
                    "name": null,
                    "year": null,
                    "price": null,
                    "file_url": null,
                    "logo_video_url": null,
                    "video_240_url": null,
                    "video_360_url": null,
                    "video_480_url": null,
                    "video_720_url": null,
                    "video_1080_url": null,
                    "favorites_count": null,
                    "is_you_favorite": 0,
                    "win_count": 0,
                    "image": null,
                    "artists_names": [],
                    "categories_names": []
                }
            },
            {
                "id": null,
                "track": {
                    "votes_count": 0,
                    "id": null,
                    "name": null,
                    "year": null,
                    "price": null,
                    "file_url": null,
                    "logo_video_url": null,
                    "video_240_url": null,
                    "video_360_url": null,
                    "video_480_url": null,
                    "video_720_url": null,
                    "video_1080_url": null,
                    "favorites_count": null,
                    "is_you_favorite": 0,
                    "win_count": 0,
                    "image": null,
                    "artists_names": [],
                    "categories_names": []
                }
            }
        ]
    },
    {
        "id": null,
        "date_end": null,
        "date_start": null,
        "step": 1,
        "stage": 2,
        "status": 0,
        "custom": false,
        "winner_track_id": null,
        "winner_points": null,
        "created_at": null,
        "updated_at": null,
        "music_category_id": null,
        "is_count_tme": 1,
        "play_time": 120,
        "battle_customers": [
            {
                "id": null,
                "track": {
                    "votes_count": 0,
                    "id": null,
                    "name": null,
                    "year": null,
                    "price": null,
                    "file_url": null,
                    "logo_video_url": null,
                    "video_240_url": null,
                    "video_360_url": null,
                    "video_480_url": null,
                    "video_720_url": null,
                    "video_1080_url": null,
                    "favorites_count": null,
                    "is_you_favorite": 0,
                    "win_count": 0,
                    "image": null,
                    "artists_names": [],
                    "categories_names": []
                }
            },
            {
                "id": null,
                "track": {
                    "votes_count": 0,
                    "id": null,
                    "name": null,
                    "year": null,
                    "price": null,
                    "file_url": null,
                    "logo_video_url": null,
                    "video_240_url": null,
                    "video_360_url": null,
                    "video_480_url": null,
                    "video_720_url": null,
                    "video_1080_url": null,
                    "favorites_count": null,
                    "is_you_favorite": 0,
                    "win_count": 0,
                    "image": null,
                    "artists_names": [],
                    "categories_names": []
                }
            }
        ]
    },
    {
        "id": null,
        "date_end": null,
        "date_start": null,
        "step": 1,
        "stage": 2,
        "status": 0,
        "custom": false,
        "winner_track_id": null,
        "winner_points": null,
        "created_at": null,
        "updated_at": null,
        "music_category_id": null,
        "is_count_tme": 1,
        "play_time": 120,
        "battle_customers": [
            {
                "id": null,
                "track": {
                    "votes_count": 0,
                    "id": null,
                    "name": null,
                    "year": null,
                    "price": null,
                    "file_url": null,
                    "logo_video_url": null,
                    "video_240_url": null,
                    "video_360_url": null,
                    "video_480_url": null,
                    "video_720_url": null,
                    "video_1080_url": null,
                    "favorites_count": null,
                    "is_you_favorite": 0,
                    "win_count": 0,
                    "image": null,
                    "artists_names": [],
                    "categories_names": []
                }
            },
            {
                "id": null,
                "track": {
                    "votes_count": 0,
                    "id": null,
                    "name": null,
                    "year": null,
                    "price": null,
                    "file_url": null,
                    "logo_video_url": null,
                    "video_240_url": null,
                    "video_360_url": null,
                    "video_480_url": null,
                    "video_720_url": null,
                    "video_1080_url": null,
                    "favorites_count": null,
                    "is_you_favorite": 0,
                    "win_count": 0,
                    "image": null,
                    "artists_names": [],
                    "categories_names": []
                }
            }
        ]
    },
    {
        "id": null,
        "date_end": null,
        "date_start": null,
        "step": 1,
        "stage": 3,
        "status": 0,
        "custom": false,
        "winner_track_id": null,
        "winner_points": null,
        "created_at": null,
        "updated_at": null,
        "music_category_id": null,
        "is_count_tme": 1,
        "play_time": 120,
        "battle_customers": [
            {
                "id": null,
                "track": {
                    "votes_count": 0,
                    "id": null,
                    "name": null,
                    "year": null,
                    "price": null,
                    "file_url": null,
                    "logo_video_url": null,
                    "video_240_url": null,
                    "video_360_url": null,
                    "video_480_url": null,
                    "video_720_url": null,
                    "video_1080_url": null,
                    "favorites_count": null,
                    "is_you_favorite": 0,
                    "win_count": 0,
                    "image": null,
                    "artists_names": [],
                    "categories_names": []
                }
            },
            {
                "id": null,
                "track": {
                    "votes_count": 0,
                    "id": null,
                    "name": null,
                    "year": null,
                    "price": null,
                    "file_url": null,
                    "logo_video_url": null,
                    "video_240_url": null,
                    "video_360_url": null,
                    "video_480_url": null,
                    "video_720_url": null,
                    "video_1080_url": null,
                    "favorites_count": null,
                    "is_you_favorite": 0,
                    "win_count": 0,
                    "image": null,
                    "artists_names": [],
                    "categories_names": []
                }
            }
        ]
    }
]