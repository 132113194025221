import { useContext, useMemo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { HeaderGeneral } from '../HeaderGeneral/HeaderGeneral';
import { ProfileMenu } from '../ProfileMenu/ProfileMenu';
import { ProfileTabs } from '../ProfileTabs/ProfileTabs';
// import cover from '../../assets/deefault_avatar.png';
import downArrow from '../../assets/profile/arrow-down.png';
import upArrow from '../../assets/profile/arrow-top.png';
import logo from '../../assets/profile/logo.svg';
import { BE_BASE_URL, profileAPI } from '../../api/api';

import styled from './ProfileLayout.module.scss';
import { Col, Radio, Row } from 'antd';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { setUserData } from '../../store/playerSlice';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as Plus } from '../../assets/svg/add_circle_FILL1_wght300_GRAD0_opsz24.svg'
interface IProps {
  children?: any;
}

const ProfileLayout: React.FC<IProps> = ({ children }) => {
  //const [userData, setUserData] = useState<any>({});
  const dispatch = useAppDispatch();
  const { userId, firstName, lastName, nickname, email, gender, phone, balance, avatar, player_status, giver_status, sponsor_status} = useAppSelector((state) => state.player);

  const [transactionSum, setTransactionSum] = useState<any>({});
  //const [balance, setBalance] = useState<any>(0);
  const navigate = useNavigate();
  const [isAuth, setIsAuth] = useState(true);

  const isMobile = useMediaQuery({
    query: "(max-width: 430px)",
  });

  const getUserData = () => {
    profileAPI
      .getProfile()
      .then((res) => res)
      .then((res) => {

        dispatch(
          setUserData({
            first_name: res.first_name,
            last_name: res.last_name,
            nickname: res.nickname,
            email: res.email,
            gender: res.gender,
            phone: res.phone,
            id: res.id,
            balance: res.amount,
            avatar: res?.avatar,
            first_voted: res?.data?.first_voted,
            income: res?.transaction_sums.income,
            expense: res?.transaction_sums.expense,
            transactions: res?.videobet_transactions,
            player_status: res?.player_status,
            giver_status: res?.giver_status,
            sponsor_status: res?.sponsor_status,
            stocks: res?.stocks
          })
        )
      });
  }

  useEffect(() => {
    getUserData()
  }, []);


  const status = (status: any) => {

    if (status === "bronze") {
      return(<span style={{color: "#D2B48C"}}>Bronze</span>)
    } else if (status === "silver") {
      return(<span style={{color: "#C0C0C0"}}>Silver</span>)
    } else if (status === "gold") {
      return(<span style={{color: "#FFD700"}}>Gold</span>)
    } else if (status === "diamond") {
      return(<span style={{color: "#7851A9"}}>Diamond</span>)
    }

  }




  useEffect(() => {
    if (localStorage.getItem('isAuth')) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  }, []);

  useEffect(() => {
    if (!isAuth) {
      navigate('/login');
    }
  }, [isAuth, navigate]);

  useEffect(() => {
    if (userId === 0) {
      profileAPI.getProfile().then((res) => {
        setTransactionSum(res.data.transactions_sum);
        setUserData(res.data.data);
      });
    }
  }, [userId]);

  const isDesktop = useMediaQuery({
    query: "(min-width: 992px)",
});


  return (
    <div>
      <Row >
        <Col span={24}>
          <HeaderGeneral title='Account' style={{ padding: isMobile ? "20px 30px 0px 30px" : "0px 30px 30px 40px" }} />
          {
            !isDesktop ?
            <div  style={{ padding: isMobile ? "20px 30px 0px 30px" : "0px 30px 30px 40px" }}>
              <h2 style={{marginBottom: 20}}>{nickname}</h2>
              {player_status ? <h3>{status(player_status)} Player</h3> : <></>}
              {sponsor_status ? <h3>{status(sponsor_status)} Sponsor</h3> : <></>}
              {giver_status ? <h3>{status(giver_status)} Producer</h3> : <></>}
            </div>
            
            : <></>
          }
        </Col>
      </Row>
      <Row className={styled.container}>
        <Col xs={24} lg={5}>
          <Row>
            <Col span={24} style={{ display: 'flex', justifyContent: "center" }}>
              <div className={styled.avatar_wrapp}>
                {
                  !!avatar && <img className={styled.avatar} src={avatar} alt={""} />
                }
              </div>
            </Col>
            <Col span={24} style={{ display: 'flex', justifyContent: "center" }}>
              <ProfileMenu />
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={18} style={{ padding: !isMobile ? "5px 0px" : "5px 10px" }}>
          {children}
        </Col>
      </Row>
    </div>
  );
};

export { ProfileLayout };
