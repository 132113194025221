import { IconBrandSpeedtest } from '@tabler/icons-react';
import React from 'react';
import { SettingControl } from './SettingControl';

type PlaybackRateSelectProps = {
    playbackRate: number;
    changePlaybackRate: (rate: number) => void;
}

const rates = [
    0.5,
    1,
    1.25,
    1.5,
    2
]

export const PlaybackRateSelect = (props: PlaybackRateSelectProps) => {
    const { playbackRate, changePlaybackRate } = props;
    
    return (
        <SettingControl
            value={ playbackRate }
            changeValue={ changePlaybackRate }
            icon={ <IconBrandSpeedtest /> }
            options={ rates }
            renderOption={ (r) => r === 1 ? 'Обычная' : r }
        />
    )
}
