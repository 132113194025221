import { IconPictureInPictureOff, IconPictureInPictureOn } from '@tabler/icons-react';
import React from 'react';

import { IconButton } from './IconButton';

type PipControlProps = {
    pip: boolean;
    togglePip: () => void
}

export const PipControl = (props: PipControlProps) => {
    const { pip, togglePip } = props;
    
    const Icon = pip ? IconPictureInPictureOff : IconPictureInPictureOn
    
    return (
        <IconButton onClick={ togglePip }>
            <Icon />
        </IconButton>
    )
}