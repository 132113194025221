import { FC } from 'react';


import styled from './TopWinnersList.module.scss';
import { Col, Divider, List, Row } from 'antd';
import moment from 'moment';
import { SongPoster } from '../SongCard/SongPoster/SongPoster';
import vs from '../../assets/VS.png'
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useLocation } from 'react-router-dom';

interface IProps {
  userStatistic: any;
  coverStyle?: any;
  page: number
  setPage: any
  count: number
  loadMoreData: any
  per: number
  isArchive?: boolean
  type?: string
}

interface Track {
  track: any;
};

const TopWinnersList: FC<IProps> = ({ userStatistic, coverStyle, per, page, setPage, count, loadMoreData, isArchive, type }) => {

  const location = useLocation();
  return (

    <div
      id="scrollableDiv"
      style={{
        height: userStatistic.length > 2 ? '80vh' : "50vh",
        overflow: "auto",
        // padding: "0 16px",
      }}
    >

      <InfiniteScroll
        dataLength={userStatistic.length}
        next={() => loadMoreData(per, page)}
        hasMore={userStatistic.length < count}
        loader={userStatistic.length === 0 ? <></> : type==='random' ? <></> : <div >Loading ...</div>}
        endMessage={<Divider plain>to be continued...</Divider>}
        scrollableTarget="scrollableDiv"
        style={{ border: 'none' }}
      >
        <List
          dataSource={userStatistic}
          renderItem={({ data }: any) => {
            const winnerId = data.winner_track_id;
            var name
            const winnerTrack = data.battle_customers.find(customer => customer.id === winnerId);
            if (winnerTrack) {
              // Получить имя победителя
              const winnerName = winnerTrack.track.artists_names[0].name;
              name = winnerName
            }


            return (
              <Row align={'middle'} style={{ paddingBottom: "20px", width: "100%" }}>
                <Col
                  style={!coverStyle?.fontSize
                    ? { fontSize: 15, fontWeight: "bold", lineHeight: 2, padding: '30px 30px 30px 20px' }
                    : { fontSize: 20, fontWeight: "bold", paddingTop: 20 }
                  }
                  xs={24} lg={5}
                >
                  {
                    type === "random" ?
                      <></>
                      :
                      location.pathname === '/profile/battle-results' ?
                        <div>
                          <p className={styled.small_text_profile}>Tournament: <span className={styled.small_text}>1st Season</span></p>
                          <p className={styled.small_text_profile}>Battle: <span className={styled.small_text}>#{data.id}</span></p>
                          <p className={styled.small_text_profile}>End date: <span className={styled.small_text}>{moment(data.date_end).format('DD.MM.YY')}</span></p>
                          <p className={styled.small_text_profile}>Winner: <span style={{ textTransform: 'uppercase' }} className={styled.small_text}>{name}</span></p>
                          <p className={styled.small_text_profile}>Prize pool: <span className={styled.small_text}>{data.winner_points}tkn</span></p>
                          <p className={styled.small_text_profile}>Winning players: <span className={styled.small_text}>{data.winner_points}</span></p>
                        </div>
                        :
                        <div>
                          <p>Tournament: <span className={styled.small_text}>1st Season</span></p>
                          <p>Battle: <span className={styled.small_text}>#{data.id}</span></p>
                          <p>End date: <span className={styled.small_text}>{moment(data.date_end).format('DD.MM.YY')}</span></p>
                          <p>Winner: <span style={{ textTransform: 'uppercase' }} className={styled.small_text}>{name}</span></p>
                          <p>Prize pool: <span className={styled.small_text}>{data.winner_points}tkn</span></p>
                          <p>Winning players: <span className={styled.small_text}>{data.winner_points}</span></p>
                        </div>

                  }

                  {/* <p>Category: <span className={styled.small_text}>#{data.music_category_id}</span></p> */}
                  {/* <p>Tournament: <span className={styled.small_text}>1st Season</span></p>
                  <p>Battle: <span className={styled.small_text}>#{data.id}</span></p>
                  <p>End date: <span className={styled.small_text}>{moment(data.date_end).format('DD.MM.YY')}</span></p>
                  <p>Winner: <span style={{ textTransform: 'uppercase' }} className={styled.small_text}>{name}</span></p>
                  <p>Prize pool: <span className={styled.small_text}>{data.winner_points}tkn</span></p>
                  <p>Winning players: <span className={styled.small_text}>{data.winner_points}</span></p> */}
                </Col>
                <Col xs={24} lg={13}>
                  <Row justify={"center"} style={{ marginTop: 20 }}>
                    {
                      data.battle_customers.map((video: any, idx: number) => {
                        return (
                          <>
                            <Col xs={24} lg={10} style={{ display: 'flex', alignItems: "center", flexDirection: "column" }}>

                              {
                                type === "archive" &&


                                <a href={`#/history?id=${data.id}`}>
                                  <SongPoster
                                    isPlaying={false}
                                    videoData={video.track}
                                    voteData={{}}
                                    battleId={data.id}
                                    winerVideoId={data.winner_track_id}
                                    coverStyle={coverStyle}
                                    withoutPlay={true}
                                  />
                                </a>

                              }
                              {

                                type === "random" &&
                                <a href={`#/random-battle?id=${data.id}`}>

                                <SongPoster
                                  isPlaying={false}
                                  videoData={video.track}
                                  voteData={{}}
                                  battleId={data.id}
                                  winerVideoId={data.winner_track_id}
                                  coverStyle={coverStyle}
                                  withoutPlay={true}
                                  />
                                  </a>
                              }

                              {
                                type === undefined &&
                                <SongPoster
                                  isPlaying={false}
                                  videoData={video.track}
                                  voteData={{}}
                                  battleId={data.id}
                                  winerVideoId={data.winner_track_id}
                                  coverStyle={coverStyle}
                                  withoutPlay={true}
                                />
                              }

                            </Col>
                            {
                              idx === 0 && (
                                <Col xs={24} lg={4} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                  {/* <h1 style={{ fontSize: 50, marginBottom: 20 }}>VS</h1> */}
                                  {
                                    <img className={styled.vs_icon} src={vs} alt="" />
                                  }

                                </Col>
                              )
                            }
                          </>
                        )
                      })
                    }
                  </Row>
                </Col>
              </Row>
            )
          }}
        />
      </InfiniteScroll>
    </div>

  )
};

export { TopWinnersList };
