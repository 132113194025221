import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Col, Row } from "antd";

import CustomPrompt from "../../components/CustomPrompt";
import Timer from "../../components/Timer";
import { HeaderGeneral } from "../../components/HeaderGeneral/HeaderGeneral";
import { BattleItem } from "../../components/BattleList/BattleItem.tsx/BattleItem";
import { setIsBlockedRoute, setUserData } from "../../store/playerSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import styled from './Battle.module.scss'
import { AgeConfirm } from "../../components/AgeConfirm/AgeConfirm";
import { setIsAge } from '../../store/playerSlice';
import { battleAPI, musicAPI, profileAPI } from "../../api/api";
import { error } from "console";



const Battle = () => {

	const [searchParams] = useSearchParams();
	const [catName, setCatName] = useState("")
	const [battle, setBattle] = useState<any>(null)
	const [pick, setPick] = useState({ touch: 0, id: null });
	const isAge = useAppSelector((state) => state.player.isAge);

	const navigate = useNavigate();
	const isOpenModalLogin = useAppSelector((state) => state.modalLogin.isOpen);
	const isBlockedRoute = useAppSelector((state) => state.player.isBlockedRoute);
	const firstVoted = useAppSelector((state) => state.player.firstVoted);

	const dispatch = useAppDispatch();

	const isMobile = useMediaQuery({
		query: "(max-width: 430px)",
	});

	let battleId: any = searchParams.get('id');

	const [isAuth, setIsAuth] = useState(true);

	useEffect(() => {
		if (localStorage.getItem('isAuth')) {
			setIsAuth(true);
		} else {
			setIsAuth(false);
		}
	}, []);

	useEffect(() => {
		getProfile();
		reefreshBattle();
	}, []);


	const getProfile = () => {
		profileAPI
			.getProfile()
			.then((res) => res)
			.then((res) => {
				dispatch(
					setUserData({
						first_name: res?.first_name,
						last_name: res?.last_name,
						nickname: res?.nickname,
						email: res?.email,
						gender: res?.gender,
						phone: res?.phone,
						id: res?.id,
						balance: res?.amount,
						avatar: res?.avatar,
						first_voted: res?.data?.first_voted,
						income: res?.transaction_sums.income,
						expense: res?.transaction_sums.expense,
						transactions: res?.videobet_transactions,
						stocks: res?.stocks
					})
				)

			})
			.catch((error) => { });

		if (battle?.battle_customers?.length) {
			if (firstVoted === undefined) {
				localStorage.setItem('Blocked', battleId);
			} else {
				localStorage.removeItem("Blocked")
			}
		}
	}

	const reefreshBattle = () => {
		localStorage.getItem('isAuth') ?
			battleAPI
				.getBattleById(battleId)
				.then(data => {
					setBattle(data)
					let block = false
					if (firstVoted === true) block = false
					if (firstVoted === undefined) block = true
					dispatch(
						setIsBlockedRoute(block)
					)
				})
			:
			battleAPI
				.getBattleByIdAnonim(battleId)
				.then(data => {
					setBattle(data)
				})

	}



	// useEffect(() => {
	// 	profileAPI
	// 		.getProfile()
	// 		.then((res) => res)
	// 		.then((res) => {
	// 			dispatch(
	// 				setUserData({
	// 					first_name: res.first_name,
	// 					last_name: res.last_name,
	// 					nickname: res.nickname,
	// 					email: res.email,
	// 					gender: res.gender,
	// 					phone: res.phone,
	// 					id: res.id,
	// 					balance: res.amount,
	// 					avatar: res?.avatar,
	// 					first_voted: res?.data?.first_voted,
	// 					income: res?.transaction_sums.income,
	// 					expense: res?.transaction_sums.expense,
	// 					transactions: res?.videobet_transactions
	// 				})
	// 			)
	// 		}
	// 		)
	// 		;

	// }, [firstVoted, pick.touch, pick, setPick])
	if (battle) {
		musicAPI.getCategories().then(categoriesResponse => {
			setCatName(categoriesResponse.find(el => el.id === battle.music_category_id)?.name);
		}

		);
	}
	// useEffect(() => {


	// }, [battle]);




	if (battle?.id) {
		return (
			<>
				{
					!localStorage.getItem('ageConfirm')
						? (
							<AgeConfirm
								onOk={(() => {
									//@ts-ignore
									localStorage.setItem('ageConfirm', true);
									dispatch(setIsAge(true))
								})}
								onExit={() => { navigate("/game-rules"); }}
							/>
						)
						:
						<Row gutter={[20, 20]} >

							<Col span={24}>
								<div className={styled.mobile}>
									<HeaderGeneral title='Battles' />
								</div>
							</Col>
							<Col span={24}>
								<div className={styled.mobileLogo}>
									{catName && <p style={{ fontSize: 24 }}>Tournament / Battle #{battleId}</p>}
								</div>
							</Col>
							<Col span={24}>
								<Timer date={battle.date_end} />
							</Col>
							<Col span={24} style={{ padding: isMobile ? "0 20px" : "0 50px" }}>
								<BattleItem
									battleId={battle.id}
									winnerCustomerId={battle.winner_customer_id}
									battleEndTime={battle.date_end}
									key={battle.id}
									battle={battle}
									idxBattle={battle.id}
									currentBattleIdx={battle.id}
									pick={pick}
									setPick={setPick}
									categoryId={battle.music_category_id}
								/>
							</Col>
						</Row>
				}
			</>

		)
	}

	return <></>
}

export default Battle;