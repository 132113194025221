import { Button, Col, Row } from "antd";
import NavigateElement from "../Navigate/NavigateElement";
import { Link, useLocation, useNavigate } from 'react-router-dom';

import styled from './Layout.module.scss';

type IProps = {

}





const PageFooter: React.FC<IProps> = () => {

	const currentDate = new Date()
	const navigate = useNavigate();
	const location = useLocation();

	const footerMenu = [
		{ name: 'Terms & Conditions', url: 'terms-n-conditions', },
		{ name: 'Privacy Policy', url: 'privacy', },
		{ name: 'Report Content', url: 'report', },
	]
	

	const year = currentDate.getFullYear()
	return (
		<Row justify={"space-between"} gutter={[0, 20]}  className={styled.footer} style={location.pathname === "/login" ? {padding: "20px"}: {}}>
			<Col >
				<Row gutter={[20, 20]}>
					<div  className={styled.footer_content}>
						{
							footerMenu.map(({ name, url }: { name: string, url: string }) => {
								return (
									// <Link to={url} className={styled.links}>
									// 	<Col className={styled.text}>
									// 	 	<p>{name}</p>
									// 	</Col>
									// </Link>
									<Button
										type={"text"}
										onClick={() => navigate(url)}

									>
										<p className={styled.text}>{name}</p>
									</Button>
								)
							})
						}
					</div>

				</Row>
			</Col>
			<Col style={{paddingTop: 2}} >
				<p className={styled.year}>© Playporn.bet, {year}</p>
				<p className={styled.address}>Fantasybattles Ltd., Nicosia, Cyprus</p>
			</Col>

		</Row>

	)
}

export default PageFooter;