import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { NotificationT } from '../../components/ToastifyNot/NotificationToastify';
import { Login } from './Login';
import { authAPI } from '../../api/api';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { closeLoginModalMenu, toggleForgotPasswordScene, toggleLoginScene } from '../../store/loginModalSlice';
import { userLogIn } from '../../store/isAuthSlice';

import styled from './Login.module.scss';
import { Button, Form, Input } from 'antd';
import { useEffect } from 'react';

interface IInitial {
  email: string;
  password: string;
}

const SignIn = () => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  function navigateToCreateAccount() {
    dispatch(toggleLoginScene(null));
  }



  function navigateToForgottenPassword() {
    dispatch(toggleForgotPasswordScene(null));
  }

  function logInData(values: IInitial) {
    authAPI
      .logIn(values.email, values.password)
      .then(() => {
        dispatch(userLogIn(null));
      })
      .then(() => {
        authAPI
          .authMe()
          .then(() => {
            localStorage.setItem('isAuth', "true")
            navigate('/battles/tournament');
            dispatch(closeLoginModalMenu(null));
            
          })
          .catch(() => {
            navigate("/login")
          })
      })
      .catch((error) => {
        if (error.response) {
          toast.error('Wrong email or password, please try again');
        } else {
          toast.error('Something went wrong, please try again');
        }
      });
  }

  return (
    <>
      <NotificationT />
      <Login>
        <>
          <h2 className={styled.header}>Sign In</h2>
          <Form
            layout="vertical"
            onFinish={(values) => {
              logInData(values)
            }}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ maxWidth: 300, width: "80%", marginTop: 30 }}
          >

            <Form.Item
              label="E-mail"
              name={"email"}
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "insert a correct email",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Password"
              required
              name={"password"}
              rules={[{ required: true, message: "insert the password" }]}
            >
              <Input.Password />
            </Form.Item>
            <div className={styled.btn}>
              <Button
                htmlType="submit"
                type={"default"}
                className={styled.btn2}
              >
                <span>
                  Login
                </span>
              </Button>
              <Button
                onClick={() => navigateToCreateAccount()}
                type={"default"}
                className={styled.btn2}
              >
                Create new account
              </Button>
              <Button
                type={'default'}
                onClick={() => navigateToForgottenPassword()}
                className={styled.btn2}
              >
                Forgotten password
              </Button>
            </div>
          </Form>
        </>
      </Login>
    </>
  );
};

export { SignIn };
