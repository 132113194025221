import { Flex, Stack, Text, Transition } from '@mantine/core';
import { TablerIconsProps } from '@tabler/icons-react';

type VolumeEffectProps = {
    affected: boolean;
    volume: number;
    VolumeIcon: (props: TablerIconsProps) => JSX.Element;
}

export const VolumeEffect = (props: VolumeEffectProps) => {
    const { volume, VolumeIcon, affected } = props
    
    return (
        <Transition mounted={ affected }>
            { (styles) => (
                <Stack
                    pos={ 'absolute' }
                    left={ 0 }
                    top={ 0 }
                    style={ { ...styles } }
                    w={ '100%' }
                    h={ '55%' }
                    align={ 'center' }
                    justify={ 'space-between' }
                    pt={ 50 }
                >
                    <Flex
                        bg={ 'rgba(0, 0, 0, 0.6)' }
                        p={ '12px 18px' }
                    >
                        <Text c={ 'white' }>{ `${ Math.floor((volume) * 100) }%` }</Text>
                    </Flex>
                    <Flex
                        style={ { borderRadius: '50%' } }
                        bg={ 'rgba(0, 0, 0, 0.6)' }
                        p={ 12 }
                    >
                        <VolumeIcon style={ { color: 'white' } } size={ 24 } />
                    </Flex>
                </Stack>
            ) }
        </Transition>
    )
}