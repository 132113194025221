import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from './ProfileBtn.module.scss';
import { Row, Col, Button } from 'antd';

interface IProps {
  title: string;
  isActive: number;
  setIsActive: (index: number) => void;
  index: number;
  svg: JSX.Element;
  url: string;
}

const ProfileBtn: React.FC<IProps> = ({ url, title, svg, isActive, setIsActive, index }) => {

  const navigate = useNavigate();

  return (
    <Button
    className={styled.button}
      type={"default"}
      onClick={() => navigate(url)}
    >
      <Row justify={"start"} gutter={[20, 20]}>
        <Col>
          <div className={isActive === index ? styled.activeIcon : styled.icon}>{svg}</div>
        </Col>
        <Col>
          <span className={isActive === index ? styled.activeText : styled.text}>{title}</span>
        </Col>
      </Row>
    </Button>
  );
};

export { ProfileBtn };
