import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
/* import { ReactComponent as Logo } from '../../assets/logoPlayporn.png'; */
import { ReactComponent as User } from '../../assets/svg/mobile-icons/user.svg';
import { closeMobileMenu, openMobileMenu } from '../../store/mobileMenuSlice';
import { MobileMenu } from '../mobileMenu/MobileMenu';
import Logo from '../../assets/logo-new.svg';


import styled from './Layout.module.scss';
import { WhiteBtn } from '../Buttons/WhiteBtn/WhiteBtn';
import { authAPI } from '../../api/api';
import { userLogOut } from '../../store/isAuthSlice';
import { openLoginModalMenu } from "../../store/loginModalSlice";
import { LoginModal } from "../../pages/Login/LoginModal/LoginModal";
import PageFooter from './PageFooter';
import { Button, Col, Drawer, Row } from 'antd';
import Account from '../Account';
import dots from '../../assets/svg/menu_FILL0_wght300_GRAD0_opsz24.svg'
import { Navigate } from '../Navigate/Navigate';

interface IProps {
    children: JSX.Element;
}

const Layout: React.FC<IProps> = ({ children }) => {
    const navigate = useNavigate();
    const [isAuth, setIsAuth] = useState(false);

    // function openProfile() {
    //     navigate('/customer-tracks');
    // }

    const isVisibleMenu = useAppSelector((state) => state.mainMenu.isVisible);
    const dispatch = useAppDispatch();
    const isOpenMobileMenu = useAppSelector((state) => state.mobileMenu.isOpen);
    const isOpenModalLogin = useAppSelector((state) => state.modalLogin.isOpen);

    const isDesktop = useMediaQuery({
        query: '(min-width: 1201px)',
    });
    const location = useLocation();

    // function openModal() {
    //     dispatch(openLoginModalMenu(null));
    // }

    useEffect(() => {
        if (localStorage.getItem('isAuth')) {
            setIsAuth(true);
        } else {
            setIsAuth(false);
        }
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (localStorage.getItem('isAuth')) {
                setIsAuth(true);
            } else {
                setIsAuth(false);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    async function logout() {
        authAPI
            .logOut()
            .then(() => {
                dispatch(userLogOut(null));
            })
            .then(() => localStorage.removeItem('isAuth'))
            .then(() => navigate('/login'))
            .then(() => setIsAuth(false));
    }

    function openLoginModal() {
        dispatch(closeMobileMenu(null));
        dispatch(openLoginModalMenu(null));
    }

    return (
        <div className={!isVisibleMenu ? styled.activeContainer : styled.container}>
            {isAuth /* && (!location.pathname.includes('profile') && !location.pathname.includes('customer-tracks')) */ && <Account isAuth={isAuth} setIsAuth={setIsAuth} />}
            <div>
                {!isDesktop &&  (
                    <div className={styled.head_wrapper}>
                        <img
                            className={styled.menuIconOpen}
                            onClick={() => dispatch(openMobileMenu(null))}
                            src={dots}
                            alt='menu'
                        />

                        {
                            !isAuth && (
                                <Row gutter={[10, 10]} style={{ marginTop: 5 }}>
								<Col span={24} className={styled.logo_col}>
									<div className={styled.logo_col}>
										<Button className={styled.button_link} type="link" href="#/game-rules">
											<img className={styled.logoAnonim} src={Logo} alt='' style={{marginTop: 8}} width={150} />
										</Button>
									</div>

								</Col>
							</Row>                            )
                        }
                    </div>

                )}
                <div>
                    {!isDesktop &&
                        <div>
                            {/* <Account isAuth={isAuth} setIsAuth={setIsAuth} /> */}

                            <img className={styled.logo} src='image/logo-new.svg' alt='' width={200} />

                        </div>
                    }
                </div>

            </div>

            <Drawer
                title="Drawer with extra actions"
                placement={"top"}
                height={570}
                onClose={() => dispatch(closeMobileMenu(null))}
                open={isOpenMobileMenu}
            >
                <MobileMenu />
            </Drawer>
            {isOpenModalLogin && <LoginModal />}
            <div className={styled.wrapper}>
                <>{children}</>
            </div>
            {
             <PageFooter />
            }
        </div>
    );
};

export { Layout };