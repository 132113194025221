import { Col, Row, Table } from "antd"
import styled from './DetailsProfile.module.scss'
import { useAppSelector } from "../../../hooks/reduxHooks"
import {sprintf} from 'sprintf-js'

const DetailsProfile = () => {

  const { balance,
    income,
    expense
  } = useAppSelector((state) => state.player);

  return (
    <Row style={{maxWidth: 369}} justify={'space-between'} gutter={[20, 20]}>
      <Col className={styled.accDetails}>
        <h3>EXPENSE</h3>
        <p>{expense} TKN</p>
      </Col>
      <Col className={styled.accDetails}>
        <h3>INCOME</h3>
        <p>{sprintf('%.2f', income)} TKN</p>
      </Col>
      <Col className={styled.accDetails}>
        <h3>BALANCE</h3>
        <p>{sprintf('%.2f', balance)} TKN</p>
      </Col>
    </Row>
  )
}

export default DetailsProfile;