import { Button, Col, Row } from 'antd';
import { HeaderGeneral } from '../HeaderGeneral/HeaderGeneral';

import s from './GameRules.module.scss';


const AgeConfirm = ({ onOk, onExit }: any) => {

  return (
    <div >
      <Row justify={"center"}>
        <Col span={24} className={s.logoWraper}>
          <img
            //className={}
            src='image/logo-new.svg'
            alt=''
            width={350}
            style={{marginTop: 40}}
          />
        </Col>
      </Row>
      <Row className={s.container} justify={"center"}>
        <Col>
          <h1 className={s.header}>Age verification</h1>
        </Col>
        <Col>
          <p className={s.text}>This website contains age-restricted materials including nudity and explicit depictions of sexual activity.</p>
          <p className={s.text}>By entering, you affirm that you are at least 18 years of age or the age of majority in the jurisdiction you
            are accessing the website from and you consent to viewing sexually explicit content.
          </p>
        </Col>
        <Col span={24} className={s.btnBlock}>
          <Row justify={"space-around"} gutter={[20, 20]}>
            <Col>
              <Button onClick={onOk} className={s.btn_confirm}>
                <span className={s.btnText}>I AM 18 OR OLDER - ENTER</span>
              </Button>
            </Col>
            <Col>
              <Button onClick={onExit} className={s.btn_negate}>
                <span className={s.btnText}>AM UNDER 18 *- EXIT </span>
              </Button>
            </Col>
          </Row>
        </Col>
        <Col>
          <p className={s.text}>
            This website Is rated with RTA label.
          </p>
          <p className={s.text}>
            Parents, you can easily block access to this site.
          </p>
        </Col>
      </Row>
    </div>
  );
};

export { AgeConfirm };
