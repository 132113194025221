import { Flex, Menu } from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import { IconCheck } from '@tabler/icons-react';
import React, { ReactNode, useReducer } from 'react';
import { IconButton } from './IconButton';

type SettingControlProps = {
    value: number;
    changeValue: (v: number) => void;
    icon: ReactNode;
    options: number[];
    renderOption?: (v: number) => ReactNode
}

export const SettingControl = (props: SettingControlProps) => {
    const { value, changeValue, icon, options, renderOption } = props

    const ref = useClickOutside(() => setTimeout(() => changeVisible(false), 300));
    const [visible, changeVisible] = useReducer((v: boolean, p: boolean) => p ?? !v, false)

    return (
        <Flex ref={ref}>
            <Menu
                width={120}
                position={'top'}
                transitionProps={{ transition: 'pop' }}
                opened={visible}
                withinPortal={false}
                withArrow
                // portalProps={{
                //     target: document.querySelector("body")
                // }}
                zIndex={9999}
                positionDependencies={[value, changeValue, icon, options, renderOption]}
            >
                <Menu.Target>
                    <IconButton
                        onClick={() => changeVisible(!visible)}>
                        {icon}
                    </IconButton>
                </Menu.Target>

                <Menu.Dropdown bg={'rgba(0, 0, 0, 0.8)'}>
                    {options.map(opt => (
                        <Menu.Item
                            key={opt}
                            color={'white'}
                            rightSection={
                                opt === value
                                    ? <IconCheck color={'#fa5252'} size={14} />
                                    : undefined
                            }
                            onClick={() => changeValue(opt)}
                        >
                            {renderOption ? renderOption(opt) : opt}
                        </Menu.Item>
                    ))}
                </Menu.Dropdown>
            </Menu>
        </Flex>
    )
}