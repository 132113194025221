import { Button, Col, Form, Input, Radio, RadioChangeEvent, Row, Space } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';

import styled from './Report.module.scss';
import { contactAPI } from '../../api/api';
import { toast } from 'react-toastify';
import { NotificationT } from '../../components/ToastifyNot/NotificationToastify';

const Report = () => {

  const [text, setText] = useState<string>("")
  const [issue, setIssue] = useState(1);
  const [disabled, setDisabled] = useState<boolean>(true)

  const [form] = Form.useForm();

  const sendMessage = (message: any) => {
    contactAPI
      .reportContent(message.url, message.reason, message.message)
      .then((res) => {
        form.resetFields();
        setText("")
        setDisabled(true)
      })
      .then(() => {
        toast.success("Success");
      })
      .catch((error) => {
        toast.error('Something is wrong');
      })
  }

  const onChange = (e: RadioChangeEvent) => {
    console.log('radio checked', e.target.value);
    setIssue(e.target.value);
  };
  

  useEffect(() => {
    if (text.length === 0) {

      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [text.length, setText])

  return (
    <div className={styled.wrapper}>
      <NotificationT />
      <Row >
        <Col span={24}>
          <h1 className={styled.header}>
            Report Content
          </h1>
        </Col>
      </Row>
      <Row className={styled.container}>
        <Col xs={24} lg={16} xl={12} >
          <Form
            form={form}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            layout="vertical"
            onFinish={sendMessage}

          >
            <Row gutter={[20, 20]}>
              <p className={styled.text} style={{ paddingLeft: 10 }}>
                By reporting a piece of content that you think is inappropriate, you helping to protect the Playporn.bet community and the integrity of our platform. <br />Thank you.
              </p>
              <Col span={14}>
                <Form.Item
                  required
                  label={"Add the URL of the content*:"}
                  name={"url"}
                  className={styled.input}
                >
                  <Input
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setText(event.target.value)}
                    value={text}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <p className={styled.text}>Issue with content:*</p>
                <Form.Item
                  required
                  name={"reason"}
                  className={styled.input}
                >
                  <Radio.Group onChange={onChange} value={issue}>
                    <Space direction="vertical">
                      <Radio value={"infringes my rights or other legal concern"}>infringes my rights or other legal concern</Radio>
                      <Radio value={"copyright concern"}>copyright concern</Radio>
                      <Radio value={"violent or harmful acts, hateful or inflammatory"}>violent or harmful acts, hateful or inflammatory</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={24}>
                <p className={styled.text}>Please provide any additional information that will help with our review</p>
                <Form.Item
                  required
                  name={"message"}
                  className={styled.input}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item wrapperCol={{ span: 24 }}>
                  <Row gutter={[20, 20]} justify={"center"}>
                    <Col>
                      <Button type="primary" htmlType="submit" disabled={disabled}>
                        Send
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default Report;
