import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

import s from './timer.module.scss'
import { useLocation } from "react-router-dom";

const Timer = ({ date: ms }) => {
	const date = new Date(ms)
	const location = useLocation();

	const [finishTime] = useState(date.getTime());
	const [[diffDays, diffH, diffM, diffS], setDiff] = useState([0, 0, 0, 0]);
	const [tick, setTick] = useState(false);
	const isMobile = useMediaQuery({
		query: '(max-width: 430px)',
	});

	useEffect(() => {
		// @ts-ignore
		const diff = (finishTime - new Date()) / 1000;
		if (diff < 0) return // время вышло
		setDiff([
			Math.floor(diff / 86400), // дни
			Math.floor((diff / 3600) % 24),
			Math.floor((diff / 60) % 60),
			Math.floor(diff % 60)
		])
	}, [tick, finishTime])

	useEffect(() => {
		const timerID = setInterval(() => setTick(!tick), 1000);
		return () => clearInterval(timerID);
	}, [tick])

	return (
		<>
			{
				localStorage.getItem('isAuth') ?
					<Row className={s.timer} justify={"center"}>
						<Col className={s.options}>
							{
								location.pathname === "/battles/random" ?
									<span className={s.optionsName}>
										{isMobile ? "Countdown " : "Countdown of Random Battle "}
									</span> :

									<span className={s.optionsName}>
										{isMobile ? "Countdown " : "Countdown of bets in this battle: "}
									</span>
							}

							<span className={s.optionsValue}>
								{`${diffDays} day ${diffH.toString().padStart(2, '0')}:${diffM.toString().padStart(2, '0')}:${diffS.toString().padStart(2, '0')}`}
							</span>
						</Col>
					</Row>
					:
					<Row style={{ marginTop: 40 }} className={s.timer} justify={"center"}>
						<Col className={s.options}>
						{
								location.pathname === "/battles/random" ?
									<span className={s.optionsName}>
										{isMobile ? "Countdown " : "Countdown of Random Battle "}
									</span> :

									<span className={s.optionsName}>
										{isMobile ? "Countdown " : "Countdown of bets in this battle: "}
									</span>
							}
							<span className={s.optionsValue}>
								{`${diffDays} day ${diffH.toString().padStart(2, '0')}:${diffM.toString().padStart(2, '0')}:${diffS.toString().padStart(2, '0')}`}
							</span>
						</Col>
					</Row>
			}
		</>
	)
}

export default Timer;

