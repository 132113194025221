import { Flex, Transition } from '@mantine/core';
import { TablerIconsProps } from '@tabler/icons-react';

type PlaybackEffectProps = {
    affected: boolean;
    PlaybackIcon: (props: TablerIconsProps) => JSX.Element;
}

export const PlaybackEffect = (props: PlaybackEffectProps) => {
    const { affected, PlaybackIcon } = props
    
    return (
        <Transition mounted={ affected }>
            { (styles) => (
                <Flex
                    pos={ 'absolute' }
                    left={ 0 }
                    top={ 0 }
                    style={ { ...styles, borderRadius: '50%' } }
                    h={ '100%' }
                    w={ '100%' }
                    align={ 'center' }
                    justify={ 'center' }
                >
                    
                    <Flex
                        style={ { borderRadius: '50%' } }
                        bg={ 'rgba(0, 0, 0, 0.6)' }
                        p={ 12 }
                    >
                        <PlaybackIcon style={ { color: 'white' } } size={ 24 } />
                    </Flex>
                </Flex>) }
        </Transition>
    )
}