import { IconPlayerPauseFilled, IconPlayerPlayFilled } from '@tabler/icons-react';
import React from 'react';

import { IconButton } from './IconButton';

type PlaybackControlProps = {
    playing: boolean;
    togglePlay: () => void
}

export const PlaybackControl = (props: PlaybackControlProps) => {
    const { playing, togglePlay } = props;
    
    const Icon = playing ? IconPlayerPauseFilled : IconPlayerPlayFilled
    
    return (
        <IconButton onClick={ togglePlay }>
            <Icon />
        </IconButton>
    )
}