import { IconBadgeHd } from '@tabler/icons-react';
import React from 'react';
import { SettingControl } from './SettingControl';

type QualitySelectProps = {
    quality: number;
    changeQuality: (quality: number) => void;
    qualities: Array<number>
}

export const QualitySelect = (props: QualitySelectProps) => {
    const { quality, changeQuality, qualities } = props;

    return (
        <SettingControl
            value={quality}
            changeValue={changeQuality}
            icon={<IconBadgeHd />}
            options={qualities}
            renderOption={(q) => `${q}p`}
        />
    )
}