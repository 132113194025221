// @ts-nocheck

import { Col, Divider, Flex, Row } from "antd";
import { isMobile } from 'react-device-detect'
import { useLocation } from "react-router-dom";

import { useAppSelector } from "../../hooks/reduxHooks";
import { setVideoData } from "../../store/playerSlice";

import { SongInfo } from "../SongCard/SongInfo/SongInfo";
import styled from './VideoPlayer.module.scss'
import Comments from "./Comments";
import {videoAPI} from '../../api/api'

import { Pideo } from "../Pideo";
import { useState } from "react";

const VideoPlayer = () => {
    const {
        vote_title,
        vote,
        vote_singer,
        vote_video,
        setISVoted,
        vote_id,
        battleId,
        pick,
        setPick
    } = useAppSelector((state) => state.player);

    const { artistsNames, categoriesNames, videoId, videoUrl, videoImage, videoTitle, genre, setOnVotee, ...more } = useAppSelector(
        (state) => state.player
    );

    const [isLiked, setIsLiked] = useState(() => more?.more?.more?.likes ?? 0)

    const location = useLocation();
    const { pathname } = location;

    const getNames = (names: string[]) => {
        let resultSrring = "";

        if (!names.length) {
            return resultSrring;
        }

        names.forEach((el: any, id) => {
            id !== (names.length - 1)
                ? resultSrring = `${resultSrring + el.name}, `
                : resultSrring = resultSrring + el.name
        });

        return resultSrring;
    }

    const video_240_url = more.more.more?.video_240_url;
    const video_360_url = more.more.more?.video_360_url;
    const video_480_url = more.more.more?.video_480_url;
    const video_720_url = more.more.more?.video_720_url;

    const videos = {
        240: video_240_url,
        360: video_360_url,
        480: video_480_url,
        720: video_720_url,
        1080: videoUrl
    }

    const isPideo = Object.values(videos).length > 1;

    function like(video_id: any, battle_id: any) {
        return videoAPI
            .postLike(battle_id, video_id)
                .then(() => {
                    setIsLiked(prev => +!prev)
                })
            }
            
    return (
        <div>
            <Row style={{ position: 'relative' }} justify={"center"}>
                <Col
                    span={24}
                    sm={20}
                    style={{
                        position: "relative",
                        paddingTop: "0px",
                    }}
                >
                    {
                        isPideo && (
                            <Pideo
                                id={vote_video?.id}
                                videos={videos}
                            >
                                <SongInfo
                                    video={vote_video}
                                    title={vote_title}
                                    singer={vote_singer}
                                    setISVoted={setISVoted}
                                    id={videoId}
                                    battleId={battleId}
                                    setPick={setPick}
                                    pick={pick}
                                />
                            </Pideo>
                        )
                    }

                    {
                        !isPideo && (
                            <iframe
                                title="#"
                                src={videoUrl}
                                scrolling={"no"}
                                style={{
                                    position: "relative",
                                    top: "-2px",
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                            </iframe>
                        )
                    }

                    <Row align={'middle'} justify={'space-between'} gutter={isMobile ? 0 : [10, 0]} style={{ padding: isMobile ? 0 : "0", marginTop: 20 }}>
                        <Col xs={!!battleId ? 10 : 12} lg={!!battleId ? 10 : 12}
                        //  style={{ paddingTop: 10, marginBottom: 10 }}
                        >
                            <h2 className={styled.text}>
                                #{videoId} {getNames(artistsNames)}
                            </h2>
                        </Col>
                        <Col >
                            <Row>
                                <Col xs={!!battleId ? 24 : 0} lg={!!battleId ? 24 : 0}>
                                    {(!!battleId ) && (
                                        <div>
                                            <SongInfo
                                                // vote={vote}
                                                video={vote_video}
                                                title={vote_title}
                                                singer={vote_singer}
                                                setISVoted={setISVoted}
                                                id={videoId}
                                                battleId={battleId}
                                                setPick={setPick}
                                                pick={pick}
                                                archive={location.pathname === '/history' ? true : false}
                                                type={location.pathname==="/random-battle" ? "random" : ""}
                                                likes={isLiked}
                                                handleLikes={() => like(videoId, battleId)}
                                            //onVotee={setOnVotee}
                                            />
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Divider orientation={"left"} >Comments</Divider>

                    <Comments videoId={videoId} />
                </Col>
            </Row>
        </div>
    );
};

export default VideoPlayer;
