import { Col, Row } from 'antd';

import styled from './Privacy.module.scss';

const Privacy = () => {
  return (
    <div className={styled.wrapper}>
      <Row >
        <Col span={24}>
          <h1 className={styled.header}>
            Privacy Policy
          </h1>
        </Col>
      </Row>
      <Row className={styled.container}>
        <Col span={24}>
          <h2 className={styled.subHeader}>
            INTRODUCTION
          </h2>
          <p className={styled.text}>
            FantasyBattles Ltd. (hereinafter “we”, “us” or “our”) operate the website Playporn.bet (hereinafter “Playporn.bet”) and is the controller of the information collected or provided via the Websites and its affiliates.<br />
            Please read this privacy policy carefully, as your access to and use of our Websites signifies that you have read and understand all terms within this privacy policy. We respect your privacy and are committed to protecting your personal data.<br />
            If you have any questions about our privacy practices, please see “Contact Information” below for information on how to contact us.
          </p>

          <h2 className={styled.subHeader}>SCOPE</h2>
          <p className={styled.text}>For the purposes of this privacy policy, “process”, “processed” or “processing” means any operation or set of operations performed on personal data or on sets of personal data (excluding disclosure thereof), whether or not by automated means, including one or several of the following operations: collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, alignment or combination, restriction, erasure or destruction. Information on disclosure of personal data or of sets of personal data may be found in the “Disclosure of Your Personal Information” section below.</p>
          <p className={styled.text} style={{ margin: "10px 0" }}>This privacy policy applies to information we collect:</p>
          <ul className={styled.list}>
            <li>on the Websites and your email communications with the Websites,</li>
            <li>through our mobile applications that provide dedicated non-browser-based interaction between you and the Websites, or</li>
            <li>when you interact with our advertising and applications on third-party websites and services, if those applications or advertising include links to this privacy policy.</li>
          </ul>

          <h2 className={styled.subHeader}>THE DATA WE PROCESS ABOUT YOU</h2>
          <p className={styled.text}>We may process different kinds of personal data about you, depending on whether you chose to create an account with us.</p>
          <p className={styled.text} style={{ margin: "10px 0" }}>Persons who visit the Websites without logging in or registering (“unregistered users”):</p>
          <ul className={styled.list}>
            <li>Contact Information: We process email address or any other information which you voluntarily provide to us at your direction for a specific function, for example a contest or survey. In some cases, we process usernames or similar identifiers, and email addresses as part of the process to verify that you are over the age of majority required to have access to the Websites and to view their contents.</li>
            <li>Website activity data: We process information about how you use our Websites, products and services and interact with our content and advertisements, including the pages you visit in our Websites, search history, and the referring web page from which you arrived at our Websites. We process browser and operating system information, devices you use to access the Websites and your time zone setting. We also process online identifiers. Specifically, we process internet protocol (IP) address information and we set cookies as explained below in the section on Cookies and Automatic Data Processing Technologies.</li>
            <li>Identifiers: Some users may be asked to provide identifiers (including government-issued photo identification as well as other documentation) in order to verify that they are over the age of majority required to have access to the Websites and to view their contents. In such cases, this information is processed by trusted third party age verification service providers. This policy does not apply to the privacy practices of these third-party age verification service providers. Read the privacy terms and conditions of these service providers carefully.</li>
          </ul>
          <p className={styled.text} style={{ margin: "10px 0" }}>Persons who choose to create an account on Playporn.bet, including persons who sign up in the Playporn Model Partner Program (“registered users”) and persons who choose to upgrade their account on Playporn.bet to a premium account such that they have access to the content found on Playporn.bet (“Premium users”):</p>
          <ul className={styled.list}>
            <li>We process from registered users the same categories of information described above for unregistered users.</li>
            <li>Contact Information: We process username or similar identifier, and email address.</li>
            <li>Payment and Commercial Information: If you subscribe, make a purchase, or receive payments from us (such as through our Playporn Model Partner Program), we process payment card or account details and related information necessary to process payments. We also process details about payments to and from you, details about your subscription, and details of products and services you have purchased or received from us.</li>
            <li>User Submitted Personal Information: We process information you submit in order to personalize your account or for a specific function, for example date of birth, age, gender, your interests, preferences, feedback, survey responses, your preferences in receiving marketing from us and our third parties, and your communication preferences, as well as any other information which you voluntarily provide to us at your direction for a specific function.</li>
            <li>Identifiers and Biometric Information: If you choose to register to certain services on the Websites (such as our Playporn Model Partner Program), we process identifiers you submit to us (including government-issued photo identification as well as other documentation) in order for us to verify your identity, age, and record checks. We also process biometric information in order to verify the authenticity of the identifiers you provide to us while registering for such services as further explained in the section “Biometric Information” below. This information is processed by trusted third party age verification service providers. This policy does not apply to the privacy practices of these third-party age verification service providers. Read the privacy terms and conditions of these service providers carefully.</li>
            <li>User Contributions including Audio/Video Information: We provide areas on our Websites where you can post information about yourself and others, communicate with others, upload content (e.g., pictures, video files, etc.), and post comments or reviews of content found on the Websites.</li>
          </ul>
          <p className={styled.text}>
            Please use caution in providing user contributions. By providing user contributions you are making that content and information publicly available. User contributions can be read, collected, used, and disclosed by others, and we cannot control who accesses your user contributions or what other users may do with the information you voluntarily post or submit. User contributions are governed by the Playporn.bet terms of use found at Playporn.bet and the Playporn.bet terms of use found at Playporn.bet.<br />
            We may process your data to produce and share aggregated insights that do not directly or indirectly identify you and are not associated with you. Such aggregate information is not personal information.<br />
            Our Websites are not directed to persons under the age of 18 or the applicable age of majority in the jurisdiction from which the Websites are accessed (“minors”), and we prohibit minors from using the Websites. As indicated throughout this privacy policy, some users may be asked to provide some information to us and our service providers in order for us to make sure that they are over the age of majority required to have access to the Websites and to view their contents. We do not knowingly process personal information from minors. If you are the parent or legal guardian of a minor who has provided us with personal information, then please contact us at support@playporn.bet to have that minor’s personal information deleted.
          </p>

          <h2 className={styled.subHeader}>THE SOURCES FROM WHICH WE PROCESS PERSONAL INFORMATION</h2>
          <p className={styled.text} style={{ margin: "10px 0" }}>We process Personal Information in the following ways:</p>
          <ul className={styled.list}>
            <li>Directly from you: We process the categories of information listed above directly from you.</li>
            <li>Automated technologies or interactions. As explained in the section below on Cookies and Automatic Data Processing Technologies, we set cookies and other automatic techniques to process website activity data when you visit the Websites or other websites owned by our corporate group.</li>
          </ul>

          <h2 className={styled.subHeader}>PURPOSES FOR WHICH WE PROCESS YOUR PERSONAL INFORMATION</h2>
          <p className={styled.text} style={{ margin: "10px 0" }}>We process personal information for the purposes described below.</p>
          <ul className={styled.list}>
            <li>Provision of services: We process identifiers, website activity data, and, additionally, for registered users and Premium users only, contact information, and payment and commercial information, and user contributions to present our Websites and their contents to you, including any interactive features on our Websites, to provide you with information, products or services that you request from us, and to verify your eligibility and deliver prizes in connection with contests and sweepstakes.</li>
            <li>Customer management (Registered Users and Premium Users Only): We process identifiers and contact information, and payment and commercial information to manage a Premium users’ and registered users’ account, to provide customer support and notices to the registered user about their account or subscription, including expiration and renewal notices, and notices about changes to our Websites or any products or services we offer or provide through them. We process identifiers and biometric information for the purpose of verifying your identity and the authenticity of the identifiers you provide to us while registering for certain services on the Websites.</li>
            <li>Customization of content and marketing (Unregistered Users and Registered Users Only): We process for both Registered Users and Unregistered Users, identifiers and contact information, website activity data; and for Registered Users, user submitted personal information and user contributions to analyze your use of, or interest in, our Websites’ content, products, or services, in order to develop and display content and advertising tailored to your interests on our Websites.</li>
            <li>Analytics: We process identifiers and website activity data to determine whether users of our Websites are unique, or whether the same user is using the Websites on multiple occasions, and to monitor aggregate metrics such as total number of visitors, pages viewed, demographic patterns.</li>
            <li>Functionality and security: We may process any of the categories of data we collect to diagnose or fix technology problems, to verify your payment information, and to detect, prevent, and respond to actual or potential fraud, illegal activities, or intellectual property infringement.</li>
            <li>Compliance: We may process any of the categories of data we process to enforce our terms and conditions and to comply with our legal obligations. In certain jurisdictions, this includes our legal obligation to verify the age of users having access to our Websites.</li>
            <li>We will process contact information and user submitted personal information in any other way we may describe when you provide the information (e.g., for a contest); or for any other purpose with your consent provided separately from this privacy policy.</li>
          </ul>

          <h2 className={styled.subHeader}>OUR LEGAL BASES UNDER CANADIAN AND EUROPEAN UNION (EU) PRIVACY LAW</h2>
          <p className={styled.text} style={{ margin: "10px 0" }}>We have the following legal bases under Canadian and EU privacy law for processing your personal data for the purposes described under Section Purposes for Which We Process Your Personal Information section:</p>
          <ul className={styled.list}>
            <li>We process personal data for the purposes of providing the services, customer management, certain customization of content (e.g., based on your selected preferences, favorites and ratings), and functionality and security because the processing is necessary for the performance of a contract, specifically it is necessary to provide the services or products you have requested, or to provide our Websites and services in a manner consistent with our terms and conditions and any other contract that you have with us.</li>
            <li>We process personal data, including identifiers, for the purpose of verifying your identity because such processing is necessary for us to comply with a legal or regulatory obligation.</li>
            <li>We process biometric information based upon your consent to do so.</li>
            <li>We process personal data for the purposes of customization of advertising, marketing, and analytics for our legitimate interests.</li>
            <li>We process personal data for functionality and security because it is necessary for our legitimate interests, and in certain cases because the processing is necessary for us to comply with a legal or regulatory obligation.</li>
            <li>We process personal information in specific circumstances where you have provided your consent to such processing.</li>
          </ul>

          <h2 className={styled.subHeader}>DISCLOSURE OF YOUR PERSONAL INFORMATION</h2>
          <p className={styled.text} style={{ margin: "10px 0" }}>We disclose personal information:</p>
          <ul className={styled.list}>
            <li>To the public: When you submit user contributions including audio/video content, you are using our Websites and services to make that information public.</li>
            <li>Within our corporate group: We may disclose any of the categories of personal information to members of our corporate group (including affiliates and related entities) to the extent this is necessary for our purposes listed in the sections above.</li>
            <li>Service providers: We disclose the categories of personal information we process to our authorized service providers that perform certain services on our behalf. These services may include payment processing and fulfilling orders, identity and document authenticity verification including biometric processing, age verification, risk and fraud detection and mitigation, customer service, marketing and advertising, customization of content, analytics, security, or hosting our Websites or supporting our Websites’ functionality. These service providers may have access to personal information needed to perform their functions but are not permitted to process such information for any other purposes.</li>
            <li>Legal successors: We may disclose all of the categories of personal information we process to a buyer or other successor in the event of a merger, acquisition or sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation or similar proceeding.</li>
            <li>To comply with the law or protect our rights or the rights of third parties: We access, preserve and share all of the categories of personal information with regulators, law enforcement or others where we reasonably believe such disclosure is needed to (a) satisfy any applicable law, regulation, legal process, or governmental request, (b) enforce applicable terms of use, including investigation of potential violations thereof, (c) detect, prevent, or otherwise address illegal or suspected illegal activities, security or technical issues, (d) protect against harm to the rights, property or safety of our company, our users, our employees, or others; or (e) to maintain and protect the security and integrity of our Websites or infrastructure. In such cases, we may raise or waive any legal objection or right available to us, in our sole discretion.</li>
          </ul>

          <h2 className={styled.subHeader}>COOKIES AND AUTOMATIC DATA COLLECTION TECHNOLOGIES</h2>
          <p className={styled.text} style={{ margin: "10px 0" }}>
            As you navigate through and interact with our Websites, we use automatic data collection technologies to collect website activity data.<br />
            We use cookies, which are small text files that are stored in your web browser or downloaded to your device when you visit a website.<br />
            We currently use the following types of cookies, which are set by the Websites’ domains, or by other domains we own or control:
          </p>
          <ul className={styled.list}>
            <li>Strictly necessary cookies: These are cookies that are required for the operation of our Websites. These include, for example, cookies that enable a user to log in to our Websites and to check if a user is allowed access to a particular service or content.</li>
            <li>Functionality cookies: These cookies help us to personalize and enhance your online experience on our Websites. This type of cookies allows us to recognize you when you return to our Websites and to remember, for example, your choice of language.</li>
            <li>Analytics cookies: These cookies allow us to recognize and count the number of users and to see how users use and explore our Websites. These cookies help us to improve our Websites, for example by ensuring that all users are able to find what they are looking for easily. Additionally, we are using third party session recording technologies that helps us better understand our users’ experience, however, the recording data is pseudonymized.</li>
            <li>Targeting and Advertising cookies: These cookies record visits of a user on our Websites, the pages a user visits and the links a user follows in order to enable us to make our Websites more relevant to the user’s interests and to help us serve ads that might be of interest to the user. Targeting and advertising cookies are used for unregistered users and registered users.</li>
          </ul>
          <p className={styled.text}>
            Analytics, Functionality and Advertising / Targeting cookies can be switched off at any time by accessing the ‘Manage cookies’ option that is located at the footer of our website. However, if you decline to accept these cookies, some functionality on our Websites may be disabled and can interrupt your access to certain parts of our Website.<br />
            Cookies can be either session cookies or persistent cookies. A session cookie expires automatically when you close your browser. A persistent cookie will remain until it expires, or you delete your cookies. Expiration dates are set in the cookies themselves; some may expire after a few minutes while others may expire after multiple years.<br />
            Do Not Track: Our systems do not recognize browser “Do Not Track” signals.<br />
            Use of Google Analytics. We use Google as a service provider to collect and analyze information about how users use the Websites, including, by collecting website activity data through first-party cookies set by our domains, and third-party cookies set by Google. Because we activated IP anonymization for Google Analytics, Google will anonymize the last octet of a particular IP address and will not store your full IP address. Google will use the information only for the purpose of providing Google Analytics services to us and will not use this information for any other purposes. The information collected by Google Analytics may be transmitted to and stored by Google on servers in the United States pursuant to standard contractual clauses approved by the EU. You can learn more on how Google uses data <a href="https://policies.google.com/technologies/partner-sites">here</a>, and you can opt-out of Google Analytics by visiting the <a href="https://tools.google.com/dlpage/gaoptout">Google Analytics opt-out page</a> or by accepting only necessary cookies.
          </p>

          <h2 className={styled.subHeader}>THIRD-PARTY USE OF COOKIES AND OTHER TRACKING TECHNOLOGIES</h2>
          <ul className={styled.list}>
            <li>Some content or applications, including advertisements, on Playporn.bet are provided or served by third parties. These third parties may use cookies alone or in conjunction with other tracking technologies to collect information about you when you click to view, access, or use any of the third-party feature on Playporn.bet or directed to such third-party website. Unless expressly stated otherwise, Playporn.bet does not provide any personal information to these third parties, however, when you click on a third-party internet-based advertising, they may collect information, including your IP address, advertisements you click, time zone setting and location, and information about your browser, operating system and devices you use to access Playporn.bet to provide targeted content.</li>
          </ul>
          <p className={styled.text}>You can set your browser to refuse all third-party cookies, or to alert you when cookies are being sent.</p>

          <h2 className={styled.subHeader}>YOUR CHOICES ABOUT HOW WE COLLECT, USE AND DISCLOSE YOUR PERSONAL INFORMATION</h2>
          <p className={styled.text} style={{ margin: "10px 0" }}>We strive to provide you with choices regarding the personal information you provide to us.</p>
          <ul className={styled.list}>
            <li>You can choose not to provide us with certain personal information, but that may result in you being unable to use certain features of our Websites because such information may be required in order for you to register as a member; subscribe, purchase products or services; participate in a contest, promotion, survey, or sweepstakes; ask a question; or initiate other transactions on our Websites.</li>
            <li>The Cookie Banner can be utilized to customize your cookie preferences. The Cookie Banner will record when you have consented to our cookies. For logged in users, we will ask for consent once a year or when we have major changes to our cookies, to ensure you stay up to date with changes to our cookie and privacy policies. For non-logged in users, your Cookie Banner selection will be kept only for the specific session. The Cookie Banner specifically controls the Functionality, Analytics, and Advertising cookies set by using our website. Strictly necessary cookies cannot be disabled, nor can the tool be used to block cookies on third party websites linked from our website. <br />You can access the Cookie Banner anytime by clicking on the ‘Manage cookies’ option that is located at the footer of our website</li>
            <li>You can opt out of receiving marketing emails from us using the opt-out link provided in our emails or by changing your preferences in your account settings. If you are a Registered User, we may continue to send you other types of important emails e.g., e-mails about your transactions, account, or orders.</li>
            <li>You may use your account settings to delete your user contributions and audio/video information.</li>
            <li>You may also delete and deactivate your account with us at any time unless required not to do so by law or by a court order. If you do so, your profile will no longer be accessible by you. If you later choose to have an account with us, you will have to sign up for a new account as none of the information you previously provided or saved within your account will have been saved.</li>
          </ul>

          <h2 className={styled.subHeader}>YOUR RIGHTS RELATED TO YOUR PERSONAL INFORMATION</h2>
          <p className={styled.text} style={{ margin: "10px 0" }}>Generally, in some regions like Canada, European Economic Area (“EEA”), and the United Kingdom, you have certain rights regarding the personal information we process and that is related to you, including the right:</p>
          <ul className={styled.list}>
            <li>to receive information on the personal information we hold about you and how such personal information is processed (right to access);</li>
            <li>to correct inaccurate personal information concerning you (right to data rectification);</li>
            <li>to delete/erase your personal information (right to deletion, “right to be forgotten”);</li>
            <li>to receive the personal information provided by you in a structured, commonly used and machine-readable format and to transmit the personal information to another data controller (right to data portability);</li>
            <li>to object to the processing of your personal information where such processing is based on our legitimate interests or on public interests (right to object);</li>
            <li>in some cases, to restrict our processing of your personal information (right to restriction of processing); and</li>
            <li>to withdraw your consent at any time where our processing is based on consent (right to withdrawal of consent).</li>
          </ul>
          <p className={styled.text}>
            You may exercise your right to access and deletion using the buttons at the bottom of this page. Additionally, you may, at any time, send us an e-mail at support@playporn.bet to exercise your above rights in accordance with the applicable legal requirements and limitations. If you are located in the European Economic Area or the UK, you have a right to lodge a complaint with your local data protection authority.<br />
            Please note that unless you have created an account with us, we may not have sufficient information to identify you and therefore may not be in a position to respond to your request. Additionally, in some cases in order to adequately verify your identity or your authorization to make the request, we may require you to provide additional information.<br />
            Note that some requests to delete certain personal information will require the deletion of your user account as the provision of user accounts are inextricably linked to the processing of certain personal information (e.g., your e-mail address).<br />
            California Rights and Choices
          </p>
          <p className={styled.text} style={{ margin: "10px 0" }}>The California Consumer Privacy Act (“CCPA”) provides you certain rights in relation to your personal information:</p>
          <ul className={styled.list}>
            <li>Right to Know: You have the right to request that we disclose certain information about our processing of your personal information over the past 12 months, including the specific pieces of information we processed.</li>
            <li>Right to Request Deletion: You have the right to request that we delete any of your personal information we processed from you and retained, subject to certain exceptions set forth in the CCPA.</li>
            <li>Right to Non-Discrimination for the Exercise of Your Rights: We will not discriminate against you because you have exercised any of your rights under the CCPA.</li>
          </ul>
          <p className={styled.text}>
            To exercise the rights described above, please use the buttons at the bottom of this page or email us at support@playporn.bet with the email subject line “CCPA Request.” In either case you will need to provide the following information to verify your identity and enable us to locate your information in our systems: your username and email address that you used to create an account with us, as well as any other information which we may reasonably request in order for us to verify your identity. We may require you verify that you have access to your account and/or email account that you used to register with us.<br />
            You can designate an agent to make a request by executing a notarized power of attorney to have that person act on your behalf and providing that person with the information listed above that allows us to verify your identity and locate your information. Alternatively, you will need to directly confirm your identity with us using the methods described above, sign an authorization for the agent to act on your behalf and provide us with confirmation that you have done so.<br />
            We disclose certain categories of California residents’ personal information for our business purposes, as described in the section above titled Disclosure of Your Personal Information.<br />
            Resident’s Rights – Jurisdictions in the United States, Where Applicable<br />
            We do not sell, trade, or exchange the Registered Users’ personal information with anyone for money.
          </p>

          <h2 className={styled.subHeader}>BIOMETRIC INFORMATION</h2>
          <p className={styled.text} style={{ margin: "10px 0" }}>
            We employ third-party service providers to help verify your identity and age and to check background records and the authenticity of identification documents you provide using facial recognition technology. The facial recognition technology creates a mathematical representation of your face and detects facial features from the live scan of your face, your selfie, and the government-issued ID you submit or previously submitted to us. This mathematical representation, which is considered biometric information/identifier/data under the laws of certain states and countries, is not shared by our service providers with us, and is deleted by our service providers 7 days after its creation. Information derived from this mathematical representation, which may be considered biometric information/identifier/data under the laws of certain states and countries, is shared by our service providers with us as further described below.<br />
            Your biometric information will be processed to:
          </p>
          <ul className={styled.list}>
            <li>verify you are a real, live person and to prevent the use of methods which would allow a user to identify as someone else;</li>
            <li>to match your selfie with your government-issued ID you submit or previously submitted to us to make sure that nobody is impersonating you;</li>
            <li>to help confirm your age; and</li>
            <li>to check your criminal/background history.</li>
          </ul>
          <p className={styled.text}>
            We may disclose your biometric information, face scan, selfie, and government-issued ID (including any government-issued ID or selfie you previously submitted to us) with members of our corporate group, and with third-party service providers. These entities may be located in jurisdictions that do not offer the same level of data protection as your jurisdiction. We (and/or any of these entities) may perform facial recognition and collect your biometric information from your face scan, selfie and any government-issued ID, and use your biometric information for the above purposes.<br />
            Biometric information shared by our service providers with us will be stored for as long as you have a Playporn Model Partner Program account. We will delete your biometric information within three (3) years of you deleting your Playporn Model account or withdrawing your consent, subject to any legal obligations we might have.<br />
            You will be asked to provide your consent to biometric processing. You may withdraw your consent to this processing at any time by using the links below. Withdrawing your consent will not affect the lawfulness of any processing or disclosure that occurred prior to the withdrawal.<br />
            Please note that this section entitled “Biometric Information” applies to relevant users who wish to upload content onto our Websites (through Playporn Model Partner Program for example) but does not apply to identifiers processed by our third-party age verification service providers solely for the purpose of granting you access to our Websites in order to view the contents found thereon.
          </p>

          <h2 className={styled.subHeader}>TRANSFERS OF YOUR PERSONAL INFORMATION TO OTHER COUNTRIES</h2>
          <p className={styled.text}>Where the laws of your country allow you to do so, by using the Websites you consent to the transfer of information that we process about you, including personal information, to other countries in which we, members of our corporate group (including affiliates and related entities) or our service providers are located. When we transfer personal information to countries outside of the European Economic Area (“EEA”) or other regions with comprehensive data protection laws, we will ensure that the information is transferred in accordance with the applicable laws. Where relevant, our transfers outside the EEA are made pursuant to standard contractual clauses approved for use by the European Union.</p>

          <h2 className={styled.subHeader}>RETENTION OF PERSONAL INFORMATION</h2>
          <p className={styled.text}>
            We will only retain your personal information for as long as your account is active, or for as long as necessary to fulfil the purposes we processed it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.<br />
            To determine the appropriate retention period for personal data, we consider a number of factors, including what personal data we are processing, the risk of harm from any unauthorised disclosure, why we are processing your personal data and whether we can achieve this outcome by another means without having to process it.<br />
            Where we no longer need to process your personal information for the purposes set out in this Privacy Policy, we will delete your personal information from our systems.<br />
            Where permissible, we will also delete your personal information upon your request as explained above in the section “Your Rights Related to Your Personal Information”.<br />
            Third-Party Links and Sites<br />
            If you click on a link to a third-party site, you will be taken to websites we do not control. This policy does not apply to the privacy practices of these websites. Read the privacy policy of other websites carefully. We are not responsible for these third-party practices.
          </p>

          <h2 className={styled.subHeader}>CHANGES TO OUR PRIVACY POLICY</h2>
          <p className={styled.text}>We may modify or revise our privacy policy from time to time. If we change anything in our privacy policy, the date of change will be reflected in the “last modified date”. We may attempt to notify you of any material changes as required by law. Please also periodically review the most up-to-date version of our privacy policy, which will be posted at this location, so you are aware of any changes.</p>

          <h2 className={styled.subHeader}>CONTACT INFORMATION</h2>
          <p className={styled.text}>
            If you have any questions about this privacy policy or our information-handling practices, please contact us at  support@playporn.bet<br />
            Our Data Protection Officer can be contacted at the following email address: support@playporn.bet
          </p>

          <h2 className={styled.subHeader}>MANAGE MY DATA</h2>
          <p className={styled.text}>With respect to Data Protection Laws in users' jurisdictions, Playporn.bet Registered Users may request for a copy of their personal data as well as request Playporn.bet to delete their personal data.</p>
        </Col>
      </Row>
    </div>
  );
};

export { Privacy };
