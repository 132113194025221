import React, { useEffect, useState } from "react";
import { TopWinnersList } from "../TopWinnersList/TopWinnersList";
import { battleAPI } from "../../api/api";
import { HeaderTournament } from "../HeaderTournament/HeaderTournament";
import styles from './styles.module.scss'
import { HeaderGeneral } from "../HeaderGeneral/HeaderGeneral";
import { useLocation } from "react-router-dom";

const BattleRandom = () => {

  const [per, setPer] = useState<number>(20)
  const [page, setPage] = useState<number>(1)
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [userStatistic, setUserStatistic] = useState<any>([]);
 const location = useLocation();



  const loadMoreData = (per: number, page: number) => {
    if (loading) {
      return;
    }
    // setPage(page + 1)
    setLoading(true);
    battleAPI
      .getRandom(per, page)
      .then((body) => {
        setCount(body.count)
        setUserStatistic(body.data);
        setLoading(false);

      })
      .catch(() => {
      });
  }

  useEffect(() => {
    loadMoreData(per, page);

  }, [page, per]);

  return (
    <>
      <HeaderGeneral title='Battles Random' />

      <div className={styles.containerWrapper}>

        <TopWinnersList
          page={page}
          setPage={setPage}
          per={per}
          count={count}
          loadMoreData={loadMoreData}
          userStatistic={userStatistic}
          coverStyle={{ height: 130, aspectRaio: "16/9", width: 'auto' }}
          // isArchive={true}
          type='random'
        />
      </div>
    </>
  )
}

export default BattleRandom;
