import { useCallback, useEffect, useState, createContext } from "react";
import { Col, Row, Button, notification, Popover } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { Wheel } from "../../components/WheelComponent";
import { HeaderGeneral } from "../../components/HeaderGeneral/HeaderGeneral";
import { luckyApi, profileAPI } from "../../api/api";
import { sprintf } from 'sprintf-js'


import { ReactComponent as Heart } from "../../assets/svg/crowdfunding/heart.svg";

import type { NotificationPlacement } from 'antd/es/notification/interface';

import s from "./LuckySpin.module.scss";
import { setUserData } from "../../store/playerSlice";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

const LuckySpin = () => {
	const [segments, setSegments] = useState([]);

	const [counters, setCounters] = useState([]);
	const [winText, setWinText] = useState("");
	const [btnText, setBtnText] = useState("Bet");
	const [btnClass, setBtnClass] = useState(s.spinBtn);
	const [loading, setLoading] = useState(true);
	const [mustSpin, setMustSpin] = useState(false);
	const [prizeNumber, setPrizeNumber] = useState(0);

	const [api, contextHolder] = notification.useNotification();

	const isMobile = useMediaQuery({
		query: "(max-width: 430px)",
	});
	const dispatch = useAppDispatch();
	const { balance } = useAppSelector((state) => state.player);

	const sum = counters.reduce((acc, item) => acc + item.count, 0);

	const openNotification = (type, placement: NotificationPlacement, message) => {

		if (type === "success") {
			api.success({
				message: message,
				//description: <Context.Consumer>{({ name }) => `Hello, ${name}!`}</Context.Consumer>,
				placement,
			});
		}
		if (type === 'error') {
			api.error({
				message: message,
				//description: <Context.Consumer>{({ name }) => `Hello, ${name}!`}</Context.Consumer>,
				placement,
			});
		}
	};



	const getModeelsOfGame = () => {
		luckyApi
			.getModels()
			.then((res) => {
				setLoading(true);
				const seg = res.map(({ id, avatar }) => {
					return {
						option: id,
						id,
						image: {
							uri: avatar,
							sizeMultiplier: 1,
							rounded: true,
							count: 0,
						},
					};
				});
				const cntrs = res.map(({ id, avatar }) => {
					return {
						id,
						avatar,
						count: 0,
					};
				});

				setSegments(seg);
				setCounters(cntrs);
			})
			.then(() => setLoading(false))
			.catch((error) => {
				openNotification('error', 'topRight', error.message)
				setTimeout(() => setLoading(false), 2000)
			})
	};

	const getUnauthModels = () => {
		luckyApi
			.getUnauthModels()
			.then((res) => {
				setLoading(true);
				const seg = res.map(({ id, avatar }) => {
					return {
						option: id,
						id,
						image: {
							uri: avatar,
							sizeMultiplier: 1,
							rounded: true,
							count: 0,
						},
					};
				});
				const cntrs = res.map(({ id, avatar }) => {
					return {
						id,
						avatar,
						count: 0,
					};
				});

				setSegments(seg);
				setCounters(cntrs);
			})
			.then(() => setLoading(false))
			.catch((error) => {
				openNotification('error', 'topRight', error.message)
				setTimeout(() => setLoading(false), 2000)
			})
	};

	const handleSpinClick = () => {
		if (!mustSpin) {
			setBtnClass(s.spinBtn_disable);
			luckyApi.postResult({ counters }).then((res) => {
				setPrizeNumber(res.winner_index);
				setMustSpin(true);
			})
				.catch(error => {
					openNotification('error', 'topRight', error.message)
				});
		}
	};

	const handleRefresh = useCallback(() => {
		if (localStorage.getItem('isAuth')) {
			getModeelsOfGame();
		} else {
			getUnauthModels()
		}
	}, []);

	const refreshCounters = (counters) => {
		const arr = counters.map(el => {
			return { ...el, count: 0 }
		})

		setCounters(arr)
	}

	const onFinished = () => {

		profileAPI
			.getProfile()
			.then((res) => res)
			.then((res) => {
				refreshCounters(counters)
				dispatch(
					setUserData({
						first_name: res.first_name,
						last_name: res.last_name,
						nickname: res.nickname,
						email: res.email,
						gender: res.gender,
						phone: res.phone,
						id: res.id,
						balance: res.amount,
						avatar: res?.avatar,
						first_voted: res?.data?.first_voted,
						income: res?.transaction_sums.income,
						expense: res?.transaction_sums.expense,
						transactions: res?.videobet_transactions,
						stocks: res?.stocks

					})
				)
			});

		const winner_id = segments.find((el) => {
			return el.id === segments[prizeNumber].option;
		}).id;

		const isWin = !!counters.find(
			({ id, count }) => id === winner_id && count > 0
		);

		if (isWin) {
			setWinText("Win");
			openNotification('success', 'topRight', "Win")
		} else {
			setWinText("Lose");
			openNotification('success', 'topRight', "Lose")
		}



		setTimeout(() => {
			setMustSpin(false);
			setWinText("");
		}, 3000);
	};

	useEffect(() => {
		if (localStorage.getItem('isAuth')) {
			getModeelsOfGame();
		} else {
			getUnauthModels()
		}
	}, [handleRefresh]);

	const pointer = {
		//src?: string; // Optional
		//style?: React.CSSProperties; // Optional
		//src: "https://www.svgrepo.com/show/54875/inverted-triangle-black-variant.svg",
		// style: {
		// 	position: "absolute",
		// 	left: "calc(100% - 107px)",
		// 	top: "calc(50% - 152px)",
		// 	transform: "rotate(45deg) scale(0.8, 1.3)",
		// 	width: 40
		// },
		// style: {
		// 	width: 30;
		// 	top: 64px;
		// 	right: 64px;
		// }
	};

	useEffect(() => {
		if (mustSpin && sum) {
			setBtnClass(s.spinBtn_active);
			setBtnText("Spin");
		}
		if (!sum) {
			setBtnClass(s.spinBtn_disable);
			setBtnText("Bet");
		}
		if (!mustSpin && sum) {
			setBtnClass(s.spinBtn);
			setBtnText("Spin");
		}
	}, [mustSpin, sum]);


	if (loading) {
		return (
			<Row justify={"center"} align={"middle"} gutter={[20, 20]} style={{ marginLeft: 18, height: "80vh" }}>
				<Col>
					<h1 style={{ fontSize: 30 }}>Loading...</h1>
				</Col>
				<Col>
					<LoadingOutlined style={{ fontSize: 30 }} />

				</Col>
			</Row>
		);
	}


	return (
		<>
			<HeaderGeneral title="Lucky Spin X4" />
			<div>
				{contextHolder}
				<Row justify={"start"}>
					<Col xs={22} lg={18} xl={10} style={{ marginLeft: 18 }}>
						<Row>
							<Col>
								<p className={s.description}>
									On PlayPorn.bet, players can instantly multiply their tokens by playing the Lucky Spin. Select one or more models participating in a particular spin, select the bet size and make a spin. if your bet wins it will be multiplied by 4!
								</p>
							</Col>
						</Row>
					</Col>
					<Col span={24}></Col>
					<Col xs={24} lg={18} xl={24} style={{ marginLeft: isMobile ? 0 : 10 }}>
						<div className={s.spin_container}>
							<div>
								<Row justify={"center"}>
									<div className={s.wheelContainer}>
										<Wheel
											radiusLineWidth={2}
											radiusLineColor={"#C8102E"}
											outerBorderColor={"#C8102E"}
											mustStartSpinning={mustSpin}
											prizeNumber={prizeNumber}
											data={segments}
											backgroundColors={["white"]}
											onStopSpinning={() => {
												onFinished();
											}}
											spinDuration={0.5}
											textDistance={70}
											pointerProps={pointer}
										/>
										<div
											//type={"primary"}
											onClick={
												!mustSpin && !sum
													? () => null
													: handleSpinClick
											}
											className={btnClass}>
											{winText || btnText}
										</div>
									</div>
								</Row>
								<Row justify={"center"}>
									<Col>
										<Button onClick={getModeelsOfGame} disabled={mustSpin}>
											Refresh Models
										</Button>
									</Col>
									<Col span={24} style={{ textAlign: "center", margin: "20px 0" }}>
										<span style={{ fontWeight: "bold", color: '#000' }}>Available tkn:</span> <span style={{ color: '#000' }}>{sprintf('%.2f', balance - sum)}</span>
									</Col>

								</Row>
							</div>
							<Row justify={"center"}>
								<Col>
									<Row justify={"center"} className={s.counter}>
										{counters.map((value, index) => {
											return (
												<Col>
													<Counter
														sum={sum}
														balance={balance}
														key={index}
														value={value}
														hideIncrement={sum >= 20}
														onIncrement={() => {
															const countersCopy = [
																...counters,
															];
															countersCopy[
																index
															].count += 1;
															setCounters(countersCopy);
														}}
														onDecrement={() => {
															const countersCopy = [
																...counters,
															];
															countersCopy[
																index
															].count -= 1;
															setCounters(countersCopy);
														}}
													/>
												</Col>
											);
										})}
									</Row>
								</Col>
							</Row>
						</div>




					</Col>
				</Row>
			</div>
		</>
	);
};

const Counter = ({
	value,
	onIncrement,
	onDecrement,
	hideIncrement,
	sum,
	balance,
}) => {
	const [incrDis, setIncrDis] = useState(false);

	const navigate = useNavigate();
	const [userInfo, setUserInfo] = useState<any>({});
	const [openPop, setOpenPop] = useState(false);
	const isMobile = useMediaQuery({
		query: "(max-width: 430px)",
	});

	useEffect(() => {
		setIncrDis(balance <= sum);
	}, [balance, sum]);

	useEffect(() => {
		profileAPI
			.getProfile()
			.then((res) => {
				return res.data;
			})
			.then((res) => {
				setUserInfo(res);
			});

	}, []);


	return (

		<div style={{ display: "flex" }}>
			{
				localStorage.getItem('isAuth') && userInfo.first_voted ?

					<div style={{ margin: isMobile ? "10px 20px" : "10px 5px" }}>
						<div style={{ textAlign: "center" }}>
							<img
								src={value.avatar}
								alt="1"
								width={70}
								height={70}
								style={{
									borderRadius: 70,
									border: !!value.count
										? "2px solid red"
										: "2px solid gray",
								}}
							/>
						</div>
						<div style={{ textAlign: "center", margin: 10 }}>
							{!!value.count ? (
								<Heart className={s.heart} style={{ fill: "red" }} />
							) : (
								<Heart className={s.heart} />
							)}
						</div>
						<Button
							onClick={() => {
								onDecrement();
							}}
							type={"text"}
							disabled={value.count === 0}>
							-
						</Button>
						<span style={{ color: '#000' }}>{value.count}</span>
						<Button
							onClick={() => {
								onIncrement();
							}}
							type={"text"}
							disabled={incrDis}
						>
							+
						</Button>

					</div>

					:

					<div>
						{
							!userInfo.first_voted && localStorage.getItem('isAuth') ?
								<div style={{ margin: isMobile ? "10px 20px" : "10px 5px" }}>
									<div style={{ textAlign: "center" }}>
										<img
											src={value.avatar}
											alt="1"
											width={70}
											height={70}
											style={{
												borderRadius: 70,
												border: !!value.count
													? "2px solid red"
													: "2px solid gray",
											}}
										/>
									</div>
									<div style={{ textAlign: "center", margin: 10 }}>
										{!!value.count ? (
											<Heart className={s.heart} style={{ fill: "red" }} />
										) : (
											<Heart className={s.heart} />
										)}
									</div>
									<Popover
										content={
											<Row justify={"center"} gutter={[20, 20]} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
												<Col span={24} style={{ maxWidth: 272 }}>
													<p style={{ textAlign: 'center', fontSize: 14, display: 'block', padding: 12 }}>
														To play Lucky Spin you need to bet 1 tkn
													</p>
												</Col>
												<Col>
													<Button
														type={"primary"}
														onClick={() => navigate('/profile/buy-tokens')}
													>
														Bet 1 TKN
													</Button>
												</Col>
											</Row>
										}
										trigger="click"
										open={openPop}
										onOpenChange={(open) => setOpenPop(open)}
									>
										<Button
											onClick={() => {
											}}
											type={"text"}
											disabled={value.count === 0}>
											-
										</Button>
										<span style={{ color: '#000' }}>{value.count}</span>
										<Button
											onClick={() => {
											}}
											type={"text"}
										// disabled={incrDis}
										>
											+
										</Button>
									</Popover>
								</div>
								:
								<Popover
									content={
										<Row justify={"center"} gutter={[20, 20]} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
											<Col span={24} style={{ maxWidth: 272 }}>
												<p style={{ textAlign: 'center', fontSize: 14, display: 'block', padding: 12 }}>
													To play Lucky Spin you need to sign up first
												</p>
											</Col>
											<Col>
												<Button
													type={"primary"}
													onClick={() => navigate('/login')}
												>
													Create Account
												</Button>
											</Col>
										</Row>
									}
									trigger="click"
									open={openPop}
									onOpenChange={(open) => setOpenPop(open)}
								>

									<div style={{ margin: isMobile ? "10px 20px" : "10px 5px" }}>
										<div style={{ textAlign: "center" }}>
											<img
												src={value.avatar}
												alt="1"
												width={70}
												height={70}
												style={{
													borderRadius: 70,
													border: !!value.count
														? "2px solid red"
														: "2px solid gray",
												}}
											/>
										</div>
										<div style={{ textAlign: "center", margin: 10 }}>
											{!!value.count ? (
												<Heart className={s.heart} style={{ fill: "red" }} />
											) : (
												<Heart className={s.heart} />
											)}
										</div>
										<Button
											onClick={() => {
											}}
											type={"text"}
											disabled={value.count === 0}>
											-
										</Button>
										<span style={{ color: '#000' }}>{value.count}</span>
										<Button
											onClick={() => {
											}}
											type={"text"}
										// disabled={incrDis}
										>
											+
										</Button>

									</div>
								</Popover>
						}
					</div>
			}
		</div>
	);
};

export default LuckySpin;
