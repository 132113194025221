import { ReactComponent as Cup } from '../../../assets/favorites/cup.svg';
import { ReactComponent as Wallet } from '../../../assets/favorites/wallet.svg';
import { ReactComponent as NFTImage } from '../../../assets/favorites/nft.svg';
import userExample from '../../../assets/profile/cover.png';

import styled from './TopPlayers.module.scss';
import { useEffect, useMemo, useState } from 'react';
import { BE_BASE_URL, statisticsAPI } from '../../../api/api';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Divider, List } from 'antd';
import { ReactComponent as Trophy } from '../../../assets/trophy.svg';
interface IProps {
  index: number;
  name: string;
  avatar: string | null;
  win_count: number;
  user: any
}

const TopPlayers = () => {

  const [per, setPer] = useState<number>(10)
  const [page, setPage] = useState<number>(1)
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [topPlayers, setTopPlayers] = useState([]);

  const loadMoreData = (per: number, page: number) => {
    if (loading) {
      return;
    }


    setPage(page + 1)
    setLoading(true);

    statisticsAPI
      .getTopPlayers(per, page)
      .then((res) => {
        setCount(res.count || 10)
        setTopPlayers(res.data.users)
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false)
      })
  }

  useEffect(() => {
    loadMoreData(per, page);
  }, [per, page]);





  return (
    <div
      id="scrollableDiv"
      style={{
        height: topPlayers.length > 3 ? '80vh' : "",
        overflow: "auto",
        // padding: "0 16px",
      }}
    >
      <InfiniteScroll
        dataLength={topPlayers.length}
        next={() => loadMoreData(per, page)}
        hasMore={topPlayers.length < count}
        loader={<div ></div>}
        endMessage={<Divider plain>to be continued...</Divider>}
        scrollableTarget="scrollableDiv"
      >
        <List
          dataSource={topPlayers}
          renderItem={(el: any, index: number) => {
            return (
              <Player
                key={el.id}
                index={index}
                name={el.nickname}
                avatar={el.avatar}
                win_count={el.wins_count}
                user={el}
              />
            )
          }}
        />
      </InfiniteScroll>
    </div>
  )
}


const Player: React.FC<IProps> = ({
  index,
  name,
  avatar,
  win_count,
  user
}) => {
  const displayAvatar = useMemo(() => {
    return avatar ? `${avatar}` : userExample;
  }, [avatar]);

  const status = () => {



    if(user?.player_status === 'bronze') {
      color ='#D2B48C'
      trophy = "flex"
      return(
        <span className={styled.status}>Bronze PLayer</span>
      )
    }
    if(user?.player_status === 'silver') {
      color ='#C0C0C0'
      trophy = "block"
      return(
        <span className={styled.status}>Silver Player</span>
      )
    }
    if(user?.player_status === "gold") {
      color ='#FFD700'
      trophy = "block"
      return(
        <span className={styled.status}>Gold Player</span>
      )
  }
  if(!user?.player_status) {
    trophy = "none"
    return(
      <span></span>
    )
  }
}

var color
var trophy
  return (
    <li className={styled.wrapper}>
      <div className={styled.coverWrapper}>
        <img className={styled.cover} src={displayAvatar} alt='cover' />
        <div className={styled.positionWrapper}>
          <div className={styled.place}>{index + 1}</div>
         
        </div>
      </div>
      <div>
        <div style={{ marginBottom: 10 }}>
          <p className={styled.name} style={{ fontSize: 14,wordBreak: "break-word"  }}>{name}</p>
          
          <div style={{display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', justifyContent: "flex-end"}}>
          <p>{status()}</p>
          <Trophy style={{ display: `${trophy}`, transform: "scale(1)", fill: `${color}`, marginRight: 5 }} />  
          </div>
        </div>
        <div className={styled.pointsWrapper}>
          <Trophy style={{ transform: "scale(1)", fill: "#C8102E" }} />
          <div className={styled.points}>{win_count || 1}</div>
        </div>
      </div>
    </li>
  );
};

export { TopPlayers };
