import axios from "axios";
import FormData from "form-data";

//const token = localStorage.getItem('token');

import mockTouraments from "../pages/NFTBattles/buttonsPage/tournament.mock";

const apiPrefix = localStorage.getItem("isAuth") ? "user/" : "/";

export const BE_BASE_URL = process.env.REACT_APP_BE_BASE_URL;

axios.defaults.withCredentials = true;

const headers = {
    "Content-Type": "application/json",
    Charset: "utf-8",
};
// const instance = axios.create({
//     baseURL: BE_BASE_URL + '/api/',
//     headers: {
//         Accept: 'application/json',
//        // Authorization: `Beader ${token}`,
//     },
//     credentials: true,
// });
const instance = axios.create({
    baseURL: BE_BASE_URL + "/api/",
    headers,
});

// instance.interceptors.request.use(
//     function (config) {
//         // Do something before request is sent
//         config.headers.Authorization = 'Bearer ' + localStorage.getItem('token');
//         return config;
//     },
//     function (error) {
//         // Do something with request error
//         return Promise.reject(error);
//     },
// );

const authAPI = {
    authMe() {
        return instance.get(`user/sessions`).then((res) => {
            return res.data;
        });
    },

    signUp(nickname, email, password, c_password) {
        return instance
            .post(`sessions`, { nickname, email, password, c_password })
            .then((res) => {
                return res.data;
            });
    },

    logIn(email, password) {
        return instance
            .post("user/sessions", { login: email, password })
            .then((res) => {
                localStorage.setItem("isAuth", true);
            });
    },

    forgottenPassword(email) {
        return instance.post("sessions/reset_password", { email }).then((res) => {
            const data = res.data.data;
        });
    },

    resetPassword(token, email, password, passwordConfirmation) {
        return instance
            .post("reset-password", {
                // token,
                email,
                password,
                password_confirmation: passwordConfirmation,
            })
            .then((res) => {
                return res.data;
            });
    },

    authWithGoogle() {
        return instance.get("login/google").then((res) => {
            return res.data;
        });
    },

    logOut() {
        return instance
            .delete("user/sessions")
            .then((res) => {
                localStorage.removeItem("isAuth");
                // localStorage.removeItem('token');
            })
            .catch((error) => console.log(error));
    },
};

const notificationsAPI = {
    getAllNotifycations() {
        return instance.get("notifications").then((res) => {
            return res.data.data.data
                .filter((notification) => notification.type === "Battle END")
                .filter(
                    (notification) =>
                        notification.data.indexOf("You won") !== -1
                );
        });
    },
};

const profileAPI = {
    getProfile() {
        return instance.get("user/profile").then((res) => {
            return res.data.data.data;
        });
    },

    editProfile(data) {
        return instance.put("user/profile", data).then((res) => {
            return res.data;
        });
    },

    loadOrder(tokens, currency, payment_type = "natural") {
        return instance
            .post("user/orders", { tokens, currency, payment_type })
            .then((res) => {
                return res.data;
            });
    },
};

const supportAPI = {
    getFaq(search = "") {
        return instance.get(`faq?search=${search}`).then((res) => {
            return res.data.data.data;
        });
    },
};

const videoAPI = {
    getComments(video_id) {
        return instance
            .get(`user/comments?track_id=${video_id}`)
            .then((res) => {
                return res.data.data.data;
            });
    },

    postComment(text, track_id) {
        return instance
            .post("user/comments", { text, track_id })
            .then((res) => {
                return res.data;
            });
    },

    answerComment(comment_id, text, track_id) {
        return instance
            .post("user/comments", { comment_id, text, track_id })
            .then((res) => {
                return res.data;
            });
    },

    postVote(comment_id, vote) {
        return instance
            .post("user/comment_user_votes", {
                comment_user_vote: { comment_id, vote },
            })
            .then((res) => {
                return res.data;
            });
    },

    postLike(battle_id, id) {
        return instance
            .post(`user/battle/${battle_id}/like/${id}`)
            .then((res) => {
                return res.data;
            })
    },

    postUnlike(battle_id) {
        return instance
            .post(`user/battle/${battle_id}/like`)
            .then((res) => {
                return res.data;
            })
    }
};

const playListAPI = {
    getAllPlayLists() {
        return instance.get("playlists").then((res) => {
            return res.data;
        });
    },

    editPlaylist({ id, name, tracks }) {
        return instance.put(`playlists/${id}`, { name, tracks }).then((res) => {
            return res.data;
        });
    },

    createPlaylist(name, tracks) {
        return instance.post("playlists", { name, tracks }).then((res) => {
            return res.data;
        });
    },
};

const contactAPI = {
    createContact(name, email, subject, message) {
        return instance
            .post("contact", { name, email, subject, message })
            .then((res) => {
                return res.data;
            });
    },

    sendMessage(nickname, email, message) {
        return instance
            .post("support/contact_us", { nickname, email, message })
            .then((res) => {
                return res.data;
            });
    },

    contactSupport(message) {
        return instance
            .post("user/support/support", { message })
            .then((res) => {
                return res.data;
            });
    },
    reportContent(url, reason, message) {
        return instance
            .post("user/support/report_content", { url, reason, message })
            .then((res) => {
                return res.data;
            });
    }
};

const tokenAPI = {
    getPaypal(sum) {
        return instance.get(`paypal/request?sum=${sum}`).then((res) => {
            return res.data;
        });
    },
};

const luckyApi = {
    getModels() {
        return instance.get(`user/lucky_spin/models`).then((res) => {
            return res.data.data;
        });
    },

    getUnauthModels() {
        return instance.get(`lucky_spin/models`).then((res) => {
            return res.data.data;
        });
    },

    postResult(data) {
        return instance.post(`user/lucky_spin`, data).then((res) => {
            return res.data;
        });
    },
};

const superwetApi = {
    getModels() {
        return instance.get(`user/superwet/models`).then((res) => {
            return res.data.data;
        });
    },

    getUnauthModels() {
        return instance.get(`superwet/models`).then((res) => {
            return res.data.data;
        });
    },

    postResult(id) {
        return instance.post(`user/superwet`, { artist_id: id }).then((res) => {
            return res.data;
        });
    },
};

const crowdfundingAPI = {
    getCrowdfunding() {
        return instance.get(
            !localStorage.getItem("isAuth") ?
                `crowdfunding_projects`
                :
                `user/crowdfunding_projects`
        )
            .then((res) => {
                return res.data
            })
    },
    getCrowdfundingById(id) {
        return instance.get(
            !localStorage.getItem("isAuth") ?
                `crowdfunding_projects/${id}`
                :
                `user/crowdfunding_projects/${id}`
        )
            .then((res) => {
                return res.data
            })
    },
    giveToken(id, amount) {
        return instance.post(`user/crowdfunding_projects/${id}/give`, {amount: amount})
        .then((res) => {
            return res.data
        })
    },
    getComments(video_id) {
        return instance
            .get(`user/comments?crowdfunding_project_id=${video_id}`)
            .then((res) => {
                return res.data.data.data;
            });
    },

    postComment(text, crowdfunding_project_id) {
        return instance
            .post("user/comments", { text, crowdfunding_project_id })
            .then((res) => {
                return res.data;
            });
    },

    answerComment(comment_id, text, crowdfunding_project_id) {
        return instance
            .post("user/comments", { comment_id, text, crowdfunding_project_id })
            .then((res) => {
                return res.data;
            });
    },

    postVote(comment_id, vote) {
        return instance
            .post("user/comment_user_votes", {
                comment_user_vote: { comment_id, vote },
            })
            .then((res) => {
                return res.data;
            });
    },
    
}

const musicAPI = {
    getMusic(params = []) {
        const queryString = params.map((param) => {
            if (param.value instanceof Array && param.value.length < 1) {
                return "";
            }
            return `${param.name}=${param.value}`;
        });
        return instance
            .get(`${apiPrefix}tracks?${queryString.join("&")}`)
            .then((res) => {
                return res.data;
            });
    },

    getCategories() {
        return instance.get(`${apiPrefix}categories`).then((res) => {
            return res.data.data.data.map((catRes) => {
                catRes.category_id = catRes.id;
                return catRes;
            });
        });
    },

    getTournaments() {
        return new Promise((resolve, reject) => {
            instance
                .get(`${apiPrefix}tournament/battles`)
                .then((response) => {
                    resolve(response.data?.map(({ data }) => data));
                })
                .catch(reject);
        });
    },

    // getCustomerMusic() {
    //     return instance.get('customer-tracks').then((res) => {
    //         return res.data;
    //     });
    // },
};

const statisticsAPI = {
    getTopPlayers(per, page) {
        //TODO:
        return instance
            .get(`statistics/top_players?per=${per}&page=${page}`)
            .then((res) => {
                return res.data;
            });
    },

    getTopNFTStatistic(onlyResults, per, page) {
        const mode = onlyResults ? "user" : "";
        return instance
            .get(`statistics/battle_results?per=${per}&page=${page}`)
            .then((res) => {
                return res.data;
            });
    },

    getTopGivers(per, page) {
        return instance
            .get(`statistics/top_givers?per=${per}&page=${page}`)
            .then((res) => {
                return res.data;
            })
    },

    getTopSponsors(per, page) {
        return instance
            .get(`statistics/top_sponsors?per=${per}&page=${page}`)
            .then((res) => {
                return res.data;
            })
    }
};

const battleAPI = {
    endAllBattles(battleId) {
        return new Promise((resolve) => {
            resolve();
        });
        // return instance.get(`battle/end-all-battles?id=${battleId}`).then((res) => {
        // }); TODO:
    },

    timeLeft(battleId) {
        return instance.get(`battle/time-left?id=${battleId}`).then((res) => {
            return res.data.data.time_left;
        });
    },

    addTrackToBattle(id, is_mixed) {
        return instance
            .post(`battle/start?track_id=${id}`, { is_mixed })
            .then((res) => {
                return res.data;
            });
    },

    //step - battle round: 1/2/3
    //status - battle state 0-battle start/ 1-battle completed
    showMyBattle() {
        //remove endpoint
        return instance.get(`battle/my-battle`).then((res) => {
            return res.data;
        });
    },


    getBattleById(battleId) {
        return instance.get(`user/battle/${battleId}`).then((res) => {
            return res.data.data;
        });
    },

    getArchiveBattle() {
        if (localStorage.getItem('isAuth')) {
            return instance.get("user/battle/archived").then((res) => {
                return res.data
            })
        } else {
            return instance.get("battle/archived").then((res) => {
                return res.data
            })
        }

    },



    getBattleByIdAnonim(battleId) {
        return instance.get(`battle/${battleId}`).then((res) => {
            return res.data.data;
        });
    },


    showBattleWithFilter(category_id, per, page) {
        const categoryFilter = !isNaN(category_id)
            ? `category_id=${category_id}`
            : "";
        return instance
            .get(
                `battle/all-battles?${categoryFilter}&step=1&status=0&per=${per}&page=${page}`
            )
            .then((res) => res.data);
    },

    sendOpenBattle(battle_id) {
        return instance.get(`user/battle/${battle_id}`).then((res) => res);
    },

    votesForCard(id, battleId, amount) {
        return instance
            .post(`user/battle/vote`, {
                battle_id: battleId,
                track_id: id,
                amount,
            })
            .then((res) => {
                return res.data;
            });
    },

    tipsForCard(battle_id, artist_id, amount) {
        return instance
            .post(`user/battle/${battle_id}/tip/${artist_id}`, {
                amount
            })
            .then((res) => {
                return res.data ;
            })
    },

    getBattleInfo(battle_id) {
        return instance.get(`battle/info/${battle_id}`).then((res) => {
            return res.data;
        });
    },

    getVotes(battle_id) {
        return instance.get(`battle/detail-votes/${battle_id}`).then((res) => {
            return res.data;
        });
    },

    getRandom(per, page) {
        return instance.get(`battle/all-battles?per=${per}&page=${page}`).then((res) => {
            return res.data;
        })
    },

    getRandomId(id) {
        return instance.get(`battle/${id}`).then((res) => {
            return res.data;
        })
    }
};

const userInfoAPI = {
    getUserName() {
        return instance.get(`statistics/customer`).then((res) => {
            return res.data;
        });
    },
};

const stockApi = {
    getStocks() {
        return instance.get('stock_exchanges').then((res) => {
            return res.data
        })
    },

    getStockById(id) {
        return instance.get(`stock_exchanges/${id}`).then((res) => {
            return res.data
        })
    },

    getPurchasedStock() {
        return instance.get(`user/stock_exchanges`).then((res) => {
            return res.data
        })
    },

    buyStocks(artist_id, quantity) {
        return instance.post('stock_exchanges', {artist_id, quantity}).then((res) => {
            return res.data
        })
    },

    sellStocks(artist_id, quantity) {
        return instance.post(`user/stock_exchanges/sale_stocks`, {artist_id, quantity}).then((res) => {
            return res.data
        })
    }

}

export {
    authAPI,
    videoAPI,
    supportAPI,
    profileAPI,
    playListAPI,
    notificationsAPI,
    contactAPI,
    tokenAPI,
    statisticsAPI,
    musicAPI,
    battleAPI,
    userInfoAPI,
    luckyApi,
    superwetApi,
    crowdfundingAPI,
    stockApi
};
