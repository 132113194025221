import { Reducer } from 'react';
import { ActionType, BooleanActionKind, NumberActionKind, StateType } from '../types';

export const makeInitialState = (id: string): StateType => {
    return {
        loading: false,
        
        playing: false,
        muted: false,
        pip: false,
        ready: false,
        
        actionsBarVisible: false,
        volumeVisible: false,
        
        duration: 0,
        played: Number(localStorage.getItem(`${id}-${NumberActionKind.PLAYED}`) ?? 0),
        seek: Number(localStorage.getItem(`${id}-${NumberActionKind.PLAYED}`) ?? 0),
        volume: Number(localStorage.getItem(`${id}-${NumberActionKind.VOLUME}`) ?? 1),
        playbackRate: Number(localStorage.getItem(`${id}-${NumberActionKind.PLAYBACK_RATE}`) ?? 1),
        quality: Number(localStorage.getItem(`${id}-${NumberActionKind.QUALITY}`) ?? -1)
    }
}

export const reducer: Reducer<StateType, ActionType> = (state, action) => {
    const { type, payload } = action
    switch (type) {
        case BooleanActionKind.LOADING:
            return {
                ...state,
                loading: payload ?? !state.loading
            }
        case BooleanActionKind.PLAYING:
            return {
                ...state,
                playing: payload ?? !state.playing
            }
        case BooleanActionKind.MUTED:
            const prevMuted = state.muted
            let volume: number
            
            if (!prevMuted) {
                sessionStorage.setItem('vol', `${ state.volume }`)
                volume = 0
            } else {
                volume = Number(sessionStorage.getItem('vol') ?? 0.5)
            }
            
            return {
                ...state,
                volume,
                muted: !prevMuted
            }
        case BooleanActionKind.PIP:
            return {
                ...state,
                pip: payload ?? !state.pip
            }
        
        case BooleanActionKind.READY:
            return {
                ...state,
                ready: payload ?? !state.ready
            }
        case BooleanActionKind.ACTIONS_BAR_VISIBLE:
            return {
                ...state,
                actionsBarVisible: payload ?? !state.actionsBarVisible
            }
        case BooleanActionKind.VOLUME_VISIBLE:
            return {
                ...state,
                volumeVisible: payload ?? !state.volumeVisible
            }
        case NumberActionKind.PLAYED:
            return {
                ...state,
                played: payload
            }
        case NumberActionKind.SEEK:
            return {
                ...state,
                seek: payload
            }
        case NumberActionKind.DURATION:
            return {
                ...state,
                duration: payload
            }
        case NumberActionKind.VOLUME:
            return {
                ...state,
                muted: payload === 0,
                volume: payload
            }
        case NumberActionKind.PLAYBACK_RATE:
            return {
                ...state,
                playbackRate: payload
            }
        case NumberActionKind.QUALITY:
            return {
                ...state,
                ready: false,
                quality: payload
            }
        default:
            return state
    }
}
