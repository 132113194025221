import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ProfileBtn } from '../Buttons/ProfileBtn/ProfileBtn';
import { ReactComponent as Setting } from '../../assets/svg/profile-main-btn-svg/setting.svg';
import { ReactComponent as Bolt } from '../../assets/svg/profile-main-btn-svg/bolt.svg';
import { ReactComponent as Mail } from '../../assets/svg/profile-main-btn-svg/mail.svg';
import { ReactComponent as Credit } from '../../assets/svg/profile-main-btn-svg/credit.svg';
import {ReactComponent as Balance} from '../../assets/svg/account_balance.svg'
import {ReactComponent as Stock} from '../../assets/svg/monitoring.svg'
import {ReactComponent as BalanceNew} from '../../assets/svg/workhistory.svg'


import styled from './ProfileMenu.module.scss';

const ProfileMenu = () => {
  const [activeBtn, setActiveBtn] = useState(0);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('settings')) setActiveBtn(0);
    if (location.pathname.includes('buy-tokens')) setActiveBtn(1);
    if (location.pathname.includes('battle-results')) setActiveBtn(2);
    if (location.pathname.includes('support')) setActiveBtn(3);
    if (location.pathname.includes('wallet')) setActiveBtn(4);
    if (location.pathname.includes('stocks_exchange')) setActiveBtn(5);
  }, [location.pathname]);

  const buttons = [
    { title: 'Profile settings', url: '/profile/settings', svg: <Setting /> },
    { title: 'Buy tokens', url: '/profile/buy-tokens', svg: <Credit /> },
    { title: 'Battle results', url: '/profile/battle-results', svg: <Bolt /> },
    { title: 'Support', url: '/profile/support', svg: <Mail /> },
    { title: 'Betting history', url: '/profile/wallet', svg: <BalanceNew /> },
    { title: 'Stock Exchange', url: '/profile/stocks_exchange', svg: <Stock /> },
  ];

  
  return (
    <div className={styled.container}>
      {buttons.map((el, index) => {
        return (
          <ProfileBtn
            url={el.url}
            key={index}
            index={index}
            setIsActive={setActiveBtn}
            isActive={activeBtn}
            title={el.title}
            svg={el.svg}
          />
        );
      })}
    </div>
  );
};

export { ProfileMenu };
