import { Search } from '../Search/Search';
import notification from '../../assets/notification.svg';
import styled from './HeaderGeneral.module.scss';
import { useMediaQuery } from 'react-responsive';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface IProps {
  title: string;
  style?: any
}

const HeaderGeneral: React.FC<IProps> = ({ title, style = {} }) => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 1201px)',
  });

  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();
  function onClickLoupe() {
    navigate(`/buy-nft?search=${searchValue}`)
  }

  return (
    <div className={styled.container} style={style}>
      {
        localStorage.getItem('isAuth') ?
        <h1 className={styled.title}>{title}</h1>
        :
        <h1 className={styled.title} style={isDesktop ? {marginTop: 70}:{marginTop: 30}}>{title}</h1>
      }

      {isDesktop && (
        <div className={styled.controlWrapper}>
        </div>
      )}
    </div>
  );
};

export { HeaderGeneral };
