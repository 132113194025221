import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { SongCard } from "../../components/SongCard/SongCard";
import { battleAPI, videoAPI } from "../../api/api";
import { useMediaQuery } from "react-responsive";
import vs from '../../assets/VS.png'
import styled from './BattleRandom.module.scss'
import Timer from "../../components/Timer";
import { Loading } from "../Loading/Loading";



const BattleRandomPage = () => {
 const [battle, setBattle] = useState<any>()
 const location = useLocation();
 const [loading, setLoading] = useState(false);
 const [videos, setVideos] = useState<any[]>([]);
 const isMobile = useMediaQuery({
  query: "(max-width: 992px)",
});
const withoutPlay = false

 let songRefs: any = [];
 const [isVoted, setISVoted] = useState(false);

 const searchString = location.search
const number = parseInt(searchString.split("=")[1]);
const loadBattle = () => {
 setLoading(true)
 localStorage.getItem('isAuth') ?
   battleAPI.getRandomId(number)
     .then(data => {
       setBattle(data)
     })
     .then(() => {
       setLoading(false)
     })
   :
   battleAPI.getBattleByIdAnonim(number)
     .then(data => {
       setBattle(data)
     })
     .then(() => {
     })
}

const { battle_customers } = battle?.data || {};
const colorsBorder = [
 { backgroundColor: "#C8102E", borderRadius: 15, border: "10px solid #C8102E", padding: 0 },
 { backgroundColor: "#1D428A", padding: 0, borderRadius: 15, border: "10px solid #1D428A" }]

useEffect(() => {
 if (battle) {
   const battleVideos = battle_customers
     ?.map(({ track, id, customer, likes }: { track: any[], id: number, customer: any[], likes: any }) => {
       return { ...track, id, battleId: battle.id, customer: { ...customer }, likes: likes };
     });

   setVideos(battleVideos);
 }
}, [battle, battle_customers]);

useEffect(() => {

   loadBattle()

}, []);


function like(video_id: any, battle_id: any) {
  return videoAPI
      .postLike(battle_id, video_id)
          .then(() => {
            loadBattle()
          })
      }

      if(loading) {
        return(<span></span>)
      }
 return(
  <>

<Timer date={battle?.data?.date_end} />
  <Row
  gutter={isMobile ? 0 : [20, 20]}
  style={{ padding: isMobile ? "0 15px" : "10px", margin: isMobile? "0" : '20px' }}
>
  {
    videos?.map((video: any, idx: number) => {
      return (
        <>
          <Col xs={24} lg={10} style={colorsBorder[idx]}>
            <SongCard
              songRef={(ref: any) => (songRefs[idx] = ref)}
              idxBattle={battle.id}
              currentBattleIdx={battle.data.id}
              video={video}
              key={video.id}
              setISVoted={setISVoted}
              idx={idx}
              battleId={battle.data.id}
              pick={() => { }}
              setPick={() => { }}
              withoutPlay={withoutPlay}
              type="random"
              likes={video.likes}
              battle={battle}
              handleLike={() => like(video.id, battle.data.id )}
              onBattleUpdate={loadBattle}
              />
          </Col>
          {
            idx === 0 && !withoutPlay && (
              <Col xs={24} lg={4} style={{
                display: "flex",
                flexDirection: "column",
                alignItems: isMobile ? "flex-end" : "center",
                height: isMobile ? 85 : "auto",
                justifyContent: "center"
              }}>
                <>
                  <div></div>
                  <div style={{ marginBottom: isMobile ? 30 : 60, marginTop: isMobile ? 30 : 60, marginLeft: 'auto', marginRight: 'auto' }} >
                    <div className={styled.animatedText} >
                    <img className={styled.vs_icon} src={vs} alt="vs" />
                                    </div>
                  </div>
                </>
              </Col>
            )
          }
        </>
      )
    })
  }
</Row>
  </>
 )
}

export default BattleRandomPage;
