import { IProps } from "ahooks/lib/useWhyDidYouUpdate";
import React, { useEffect, useMemo, useState } from "react";
import styled from "./TopProducers.module.scss";
import userExample from '../../../assets/profile/cover.png';
import { ReactComponent as Coin } from '../../../assets/paid_FILL0_wght300_GRAD0_opsz24.svg';
import { ReactComponent as Trophy } from '../../../assets/trophy.svg';

import { Divider, List, Row } from "antd";
import { count } from "console";
import InfiniteScroll from "react-infinite-scroll-component";
import topPlayers from "../../../store/topPlayers";
import { statisticsAPI } from "../../../api/api";


const TopProducers = () => {
  const [per, setPer] = useState<number>(10)
  const [page, setPage] = useState<number>(1)
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [topGivers, setTopGivers] = useState([]);

  const loadMoreData = (per: number, page: number) => {
    if (loading) {
      return;
    }
    setPage(page + 1)
    setLoading(true);

    statisticsAPI
      .getTopGivers(per, page)
      .then((res) => {
        setCount(res.count || 10)
        setTopGivers(res.data)
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false)
      })
  }

  useEffect(() => {
    loadMoreData(per, page);
  }, [per, page]);



  return(
    <div
      id="scrollableDiv"
      style={{
        height: topPlayers.length > 3 ? '80vh' : "",
        overflow: "auto",
        // padding: "0 16px",
      }}
    >
      <InfiniteScroll
        dataLength={topPlayers.length}
        next={() => {}}
        // next={() => loadMoreData(per, page)}
        hasMore={topPlayers.length < count}
        // hasMore={topPlayers.length < count}

        loader={<div></div>}
        endMessage={<Divider plain>to be continued...</Divider>}
        scrollableTarget="scrollableDiv"
      >
        <List
          dataSource={topGivers}
          renderItem={(el: any, index: number) => {

            return (
              <Player
                key={el.id}
                index={index}
                name={el.user.nickname}
                avatar={el.user.avatar}
                prducerMoney={el.amount}
                user={el}
                
              />
            )
          }}
        />
      </InfiniteScroll>
    </div>
  )
}


const Player: React.FC<IProps> = ({
  index,
  name,
  avatar,
  // win_count,
  prducerMoney,
  user
}) => {
  const displayAvatar = useMemo(() => {
    return avatar ? `${avatar}` : userExample;
  }, [avatar]);
  var color
  var trophy
  const status = () => {
    if(user.user.giver_status === 'bronze') {
      color ='#D2B48C'
      trophy = "flex"
      return(
        <span className={styled.status}>Bronze Producer</span>
      )
    }
    if(user.user.giver_status === 'silver') {
      color ='#C0C0C0'
      trophy = "block"
      return(
        <span className={styled.status}>Silver Producer</span>
      )
    }
    if(user.user.giver_status === "gold") {
      color ='#FFD700'
      trophy = "block"
      return(
        <span className={styled.status}>Gold Producer</span>
      )
  }
  if(user.user.giver_status === null) {
    trophy = "none"
    return(
      <span></span>
    )
  }
  if(user.user.giver_status === "diamond") {

    trophy = "block"
    color = "#7851A9"
    return(
      <span className={styled.status}>Diamond Producer</span>)
  } 
}
  return (
    <li className={styled.wrapper}>
      <div className={styled.coverWrapper}>
        <img className={styled.cover} src={displayAvatar} alt='cover' />
        <div className={styled.positionWrapper}>
          <div className={styled.place}>{index + 1}</div>
        </div>
      </div>
      <div>
        <div style={{ marginBottom: 10 }}>
          <p className={styled.name} style={{ fontSize: 14, wordBreak: "break-word"  }}>{name} </p>
          <div style={{display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', justifyContent: "flex-end"}}>
          <p>{status()}</p>
          <Trophy style={{ display: `${trophy}`, transform: "scale(1)", fill: `${color}`, marginRight: 5 }} />  
          </div>
    
        </div>
        
        <div className={styled.pointsWrapper}>
          <Coin style={{ transform: "scale(1)", fill: "#C8102E" }} />
          <div className={styled.points}>{prducerMoney || 1}$</div>
        </div>
       
      </div>
    </li>
  );
};

export default TopProducers;
