import { getHotkeyHandler, useTimeout } from '@mantine/hooks';
import { MouseEvent, useCallback, useEffect, useReducer, useRef } from 'react';
import ReactPlayer from 'react-player';
import { OnProgressProps } from 'react-player/base';
import { BooleanActionKind, BooleanActionType, NumberActionKind } from '../types';
import { makeInitialState, reducer } from './reducer';

export const usePlayer = (id: string) => {
    const initialState = makeInitialState(id);
    const player = useRef<ReactPlayer>(null)
    
    const [state, dispatch] = useReducer(reducer, initialState)
    
    const onReady = useCallback(() => {
        if (!state.ready) {
            const storedTime = localStorage.getItem(`${id}-${NumberActionKind.PLAYED}`)
            player.current?.seekTo(storedTime ? +storedTime - 2 : 0, 'seconds');
            dispatch({ type: BooleanActionKind.READY, payload: true })
        }
    }, [id, state.ready]);
    
    const getToggleHandler = (action: BooleanActionType) => (e?: MouseEvent<HTMLElement>) => {
        e?.stopPropagation()
        dispatch(action)
    }
    
    const onProgress = ({ playedSeconds }: OnProgressProps) => {
        dispatch({ type: NumberActionKind.PLAYED, payload: playedSeconds })
        localStorage.setItem(`${id}-${NumberActionKind.PLAYED}`, `${ playedSeconds }`)
    }
    
    const seekTo = (sec: number) => {
        dispatch({ type: NumberActionKind.PLAYED, payload: sec })
        dispatch({ type: NumberActionKind.SEEK, payload: sec })
        
        player.current?.seekTo(sec, 'seconds')
    }
    
    const onBuffer = () => {
        dispatch({ type: BooleanActionKind.LOADING, payload: true })
    }
    
    const onBufferEnd = () => {
        dispatch({ type: BooleanActionKind.LOADING, payload: false })
    }
    
    const setDuration = (payload: number) => {
        dispatch({ type: NumberActionKind.DURATION, payload })
    }
    
    const changeVolume = (payload: number) => {
        dispatch({ type: NumberActionKind.VOLUME, payload })
        localStorage.setItem(`${id}-${NumberActionKind.VOLUME}`, `${ payload }`)
    }
    
    const changePlaybackRate = (payload: number) => {
        dispatch({ type: NumberActionKind.PLAYBACK_RATE, payload })
        localStorage.setItem(`${id}-${NumberActionKind.PLAYBACK_RATE}`, `${ payload }`)
    }
    
    const changeQuality = (payload: number) => {
        dispatch({ type: NumberActionKind.QUALITY, payload })
        localStorage.setItem(`${id}-${NumberActionKind.QUALITY}`, `${ payload }`)
    }
    
    const onDisablePIP = () => {
        getToggleHandler({ type: BooleanActionKind.PIP, payload: false })()
    }
    
    const showVolume = getToggleHandler({ type: BooleanActionKind.VOLUME_VISIBLE, payload: true })
    const closeVolume = getToggleHandler({ type: BooleanActionKind.VOLUME_VISIBLE, payload: false })
    
    const keyboardPressHandler = getHotkeyHandler([
        ['ArrowLeft', () => seekTo(state.played - 10)],
        ['ArrowRight', () => seekTo(state.played + 10)],
        ['ArrowUp', () => {
            const vol = state.volume + 0.05
            changeVolume(vol > 1 ? 1 : vol)
        }],
        ['ArrowDown', () => {
            const vol = state.volume - 0.05
            changeVolume(vol < 0 ? 0 : vol)
            
        }],
        ['Space', () => getToggleHandler({ type: BooleanActionKind.PLAYING })()],
        ['m', () => getToggleHandler({ type: BooleanActionKind.MUTED })()],
    ])
    
    const { start, clear } = useTimeout(() => closeVolume(), 1000);
    
    useEffect(() => {
        showVolume()
        start()
        return () => clear()
    }, [state.volume])
    
    return {
        player,
        state,
        getToggleHandler,
        onProgress,
        seekTo,
        onBuffer,
        onBufferEnd,
        setDuration,
        changeVolume,
        changePlaybackRate,
        changeQuality,
        keyboardPressHandler,
        onDisablePIP,
        onReady
    }
}