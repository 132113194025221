import { useState } from "react";
import { Button, Col, Input, Modal, Row, Slider, Switch } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64'

import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { ProfileLayout } from "../ProfileLayout/ProfileLayout";
import DetailsProfile from "../Profile/DetailsProfile";

import { profileAPI } from "../../api/api";
import { setUserData } from "../../store/playerSlice";

import s from './BuyTokens.module.scss'
import { useMediaQuery } from "react-responsive";

const BuyTokens = () => {

	const [isOpenModal, setIsOpenModal] = useState(false)
	const [iframeSrc, setIframeSrc] = useState("")
	const [tokenCount, setTokenCount] = useState<number>(10)
	const [isLoading, setIsLoading] = useState(false)

	const [currency, setCurrency] = useState<"USD" | "EUR">("USD")

	const dispatch = useAppDispatch();
	const { nickname, player_status, giver_status, sponsor_status } = useAppSelector((state) => state.player);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value: inputValue } = e.target;
		const reg = /^-?\d*(\.\d*)?$/;
		if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
			+inputValue <= 5000 ? setTokenCount(+inputValue) : setTokenCount(5000)
		}
	};

	const buttons = [10, 20, 50, 100, 200, 500]
	const centered = { display: "flex", justifyContent: "center", alignItems: "center" }

	const handleCloseModal = () => {
		profileAPI
			.getProfile()
			.then((res) => res)
			.then((res) => {
				setIframeSrc("")
				setIsOpenModal(false)

				dispatch(
					setUserData({
						first_name: res.first_name,
						last_name: res.last_name,
						nickname: res.nickname,
						email: res.email,
						gender: res.gender,
						phone: res.phone,
						id: res.id,
						balance: res.amount,
						avatar: res?.avatar,
						first_voted: res?.data?.first_voted,
						income: res?.transaction_sums.income,
						expense: res?.transaction_sums.expense,
						transactions: res?.videobet_transactions,
						stocks: res?.stocks
					})
				)
			});
	}

	const handleCreateOrder = () => {
		setIsLoading(true)
		profileAPI
			.loadOrder(tokenCount, currency)
			.then((res) => {
				setIframeSrc(getSignature(res.amount, currency, res.guid))
				setIsOpenModal(true)
				setIsLoading(false)
			})
			.catch((error) => {
				console.error(error)
				setIsLoading(false)
			})
	}

	const isDesktop = useMediaQuery({
		query: "(min-width: 992px)",
	});

	const status = (status: any) => {

		if (status === "bronze") {
			return (<span style={{ color: "#D2B48C" }}>Bronze</span>)
		} else if (status === "silver") {
			return (<span style={{ color: "#C0C0C0" }}>Silver</span>)
		} else if (status === "gold") {
			return (<span style={{ color: "#FFD700" }}>Gold</span>)
		} else if (status === "diamond") {
			return (<span style={{ color: "#7851A9" }}>Diamond</span>)
		}
	}

		return (
			<>
				<ProfileLayout>
					<Row gutter={[20, 20]}>
						<Col span={24}>
							{

								isDesktop ?

									<div>
										<h2 className={s.accountName}>{nickname}</h2>
										{player_status ? <h3>{status(player_status)} Player</h3> : <></>}
										{sponsor_status ? <h3>{status(sponsor_status)} Sponsor</h3> : <></>}
										{giver_status ? <h3>{status(giver_status)} Producer</h3> : <></>}
									</div> : <></>
							}
						</Col>
						<Col span={24}>
							<DetailsProfile />
						</Col>
					</Row>
					<Row gutter={[20, 20]}>
						<Col xs={24} lg={10} className={s.slider_block}>
							<Row gutter={[10, 10]}>
								<Col span={24}>
									<p>Select currency:</p>
								</Col>
								<Col>
									<Switch
										checkedChildren="$"
										unCheckedChildren="€"
										defaultChecked
										onChange={e => !!e ? setCurrency("USD") : setCurrency("EUR")}
									/>
								</Col>
							</Row>
							<p>Select or enter amount:</p>
							<div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between" }}>
								{
									buttons.map((value) => (
										<Button type={"link"} onClick={() => setTokenCount(value)}>
											<span style={{ color: "#000" }}>{value} TKN</span>
										</Button>
									))
								}
							</div>
							<Row>
							</Row>
							<Row gutter={[20, 20]}>
								<Col flex="20px" style={{ ...centered, paddingLeft: 25, paddingRight: 0 }}>10</Col>
								<Col flex="auto">
									<Slider
										defaultValue={10}
										onChange={setTokenCount}
										step={1}
										value={tokenCount}
										min={10}
										max={5000}
									/>
								</Col >
								<Col flex="30px" style={{ ...centered, paddingLeft: 0, paddingRight: 25 }}>5000</Col>
							</Row>

							<div>
								<Input
									onChange={handleChange}
									value={tokenCount}
								/>
							</div>
							<div style={{ textAlign: "center" }}>
								<Button
									onClick={handleCreateOrder}
									type={"default"}
									disabled={isLoading}
								>
									<span>CONFIRM PAYMENT</span>
									{isLoading && <LoadingOutlined />}
								</Button>
							</div>
						</Col>
					</Row >

				</ProfileLayout >

				<Modal
					open={isOpenModal && !!iframeSrc}
					title=""
					onCancel={handleCloseModal}
					footer={false}
					maskClosable={false}
					centered
					styles={{ content: { padding: 0 } }}
				>
					<iframe
						title="Title"
						src={iframeSrc}
						style={{
							borderRadius: 7,
							width: "100%",
							height: "95vh",
							border: "none",
						}}
					>
					</iframe>
				</Modal>
			</>
		)
	}

	export default BuyTokens;

	const getSignature = (transAmount, currency, transRefNum) => {
		const merchantNumber = process.env.REACT_APP_MID_MERCHANT_ID
		const PersonalHashKey = process.env.REACT_APP_HASH_KEY
		const notificationUrl = process.env.REACT_APP_BE_BASE_URL + '/' + process.env.REACT_APP_NOTIFICATION_URL

		const transComment = ""
		const payFor = "FANTASY+BATTLES"
		const dispLng = "en-US"
		const Brand = ""
		const urlRedirect = ""
		const transType = "0"
		const dispPaymentType = ""
		const dispRecurring = "0"
		const dispMobile = "auto"
		const transInstallments = "1"
		const uiVersion = "6"

		const signature = sha256(merchantNumber + urlRedirect + notificationUrl + transComment + transRefNum + Brand + transInstallments
			+ uiVersion + transType + transAmount + currency + dispPaymentType + payFor + dispRecurring + dispLng + dispMobile + PersonalHashKey)

		const Base64Siganture = signature.toString(Base64);

		const hash = encodeURIComponent(Base64Siganture);

		const src = "https://uiservices.aureavia.com/hosted/?merchantID=" + merchantNumber + "&url_redirect=" + urlRedirect + "&notification_url="
			+ notificationUrl + "&trans_comment=" + transComment + "&trans_refNum=" + encodeURIComponent(transRefNum) + "&Brand=" + Brand + "&trans_installments=" + transInstallments
			+ "&ui_version=" + uiVersion + "&trans_type=" + transType + "&trans_amount=" + transAmount + "&trans_currency=" + currency + "&disp_paymentType="
			+ dispPaymentType + "&disp_payFor=" + encodeURIComponent(payFor) + "&disp_recurring=" + dispRecurring + "&disp_lng=" + dispLng + "&disp_mobile="
			+ dispMobile + "&signature=" + hash

		return src
	}
