import { forwardRef, ReactNode } from 'react';

import { ActionIcon } from '@mantine/core';

type IconButtonProps = {
    onClick: () => void;
    children: ReactNode;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>((props: IconButtonProps, ref) => {
    const {
        children,
        onClick,
        onMouseEnter,
        onMouseLeave
    } = props;
    
    return (
        <ActionIcon
            ref={ ref }
            onMouseEnter={ onMouseEnter }
            onMouseLeave={ onMouseLeave }
            ml={ 12 }
            variant="transparent"
            aria-label="Play"
            color={ 'white' }
            onClick={ onClick }
        >
            { children }
        </ActionIcon>
    )
})