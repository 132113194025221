import { toast } from 'react-toastify';

import { Button, Form, Input, Radio } from 'antd';
import { AnimateBtn } from '../../components/Buttons/AnimateBtn/AnimateBtn';
import { HeaderGeneral } from '../../components/HeaderGeneral/HeaderGeneral';
import { NotificationT } from '../../components/ToastifyNot/NotificationToastify';
import 'react-toastify/dist/ReactToastify.css';
import { contactAPI } from '../../api/api';
import * as validation from '../../utils/Validation';
import { TextError } from './TextError/TextError';

import styled from './Contacts.module.scss';
import { useMediaQuery } from 'react-responsive';
import { ChangeEvent, useEffect, useState } from 'react';
import MaskInput from 'antd-mask-input';



const { TextArea } = Input;

interface IValue {
  nickname: string;
  email: string;
  message: string;
}

const initialValues = {
  name: '',
  email: '',
  subject: '',
  message: '',
};

const Contacts = () => {
  const [form] = Form.useForm();

  const [disabled, setDisabled] = useState<boolean>(false)

  function sendMessage(value: IValue) {
    setDisabled(true)
    contactAPI
      .sendMessage(value.nickname, value.email, value.message)
      .then((res) => {
        toast.success('Success');
        form.resetFields();
        setDisabled(false)
      })
      .catch((error) => {
        toast.error("Something wrong");
        setDisabled(false)
      });
  }

  const onFinish = (values: any) => {
    sendMessage(values)
  };

  const isMobile = useMediaQuery({
    query: '(max-width: 430px)',
  });
  const additionalStyles = isMobile ? { height: "45px", width: "255px" } : {};

  return (
    <>
      <HeaderGeneral title='Contact us' />
      <div style={{ padding: isMobile ? "25px 15px 20px 18px" : "0px 100px 0px 18px" }}>
        <p className={styled.description}>Please write to us your questions, suggestions and comments</p>
        <NotificationT />
        <div className={styled.container}>
          <div className={styled.boxContainer}>
            <div>
              {/* <h1 className={styled.title}>We are here to help you</h1>
            <p className={styled.text}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eu
            egestas elit. Pellentesque aliquet blandit ligula, vel vehicula tellus.
          </p> */}
              <div className={styled.inputs}>

                <Form
                  form={form}
                  initialValues={{ layout: 'horizontal' }}
                  layout='horizontal'
                  className={styled.form}
                  onFinish={onFinish}
                >
                  {/* <div className={styled.short}> */}


                  <Form.Item className={styled.large_input} style={{ fontSize: 16 }} name={'nickname'} required label="Your nickname">
                    <Input
                      className={styled.input} />
                  </Form.Item>
                  <Form.Item className={styled.large_input} name={'email'} required label="Your email">
                    <Input className={styled.input} type='email' />
                  </Form.Item>
                  {/* </div> */}

                  <Form.Item className={styled.large_input} name={'message'} required labelCol={{ span: 24 }} label="Type your message here:">
                    <TextArea className={styled.input} autoSize={{ minRows: 6, maxRows: 8 }} />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      disabled={disabled}
                      className={styled.send_btn} htmlType='submit' type="default">Send</Button>
                  </Form.Item>
                </Form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { Contacts };
