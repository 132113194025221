import { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, Row, Switch, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import get from "lodash/get";

import { profileAPI } from '../../api/api';
import { ProfileLayout } from '../ProfileLayout/ProfileLayout';
import DetailsProfile from '../Profile/DetailsProfile';

import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';

import styled from './ProfileSettings.module.scss';
import { putToS3 } from '../../utils/imgUploader';
import { setUserData } from '../../store/playerSlice';
import { useMediaQuery } from 'react-responsive';

const initialProfile = {
  avatar: '',
  username: '',
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  nickname: '',
  notification_battles: 1,
  notification_message: 1,
  gender: 1,
};

const getBase64 = (img: any, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const ProfileSettings = () => {
  const dispatch = useAppDispatch();
  const { userId, firstName, lastName, nickname, email, gender, phone, balance, avatar, sponsor_status, giver_status, player_status } = useAppSelector((state) => state.player);

  const [isOpenChange, setIsOpenChange] = useState(false)
  const [imageUrl, setImageUrl] = useState(avatar);
  const [loading, setLoading] = useState(false);

  const [isFileMode, setIsFileMode] = useState<boolean>(true);

  const confirm = useConfirm();

  function editProfile(data: any) {
    profileAPI.editProfile({
      ...data,
      avatar: imageUrl
    })
      .then(() => setIsOpenChange(false))
      .then(() => {
        profileAPI
          .getProfile()
          .then((res) => {
            dispatch(
              setUserData({
                first_name: res.first_name,
                last_name: res.last_name,
                nickname: res.nickname,
                email: res.email,
                gender: res.gender,
                phone: res.phone,
                id: res.id,
                balance: res.amount,
                avatar: res?.avatar,
                first_voted: res?.data?.first_voted,
                income: res?.transaction_sums.income,
                expense: res?.transaction_sums.expense,
                transactions: res?.videobet_transactions,
                giver_status: res?.giver_status,
                player_status: res?.player_status,
                sponsor_status: res?.sponsor_status,
                stocks: res?.stocks
              })
            )
          })
      })
      .catch((error) => console.log(error));
  }

  function customRequest(info: object) {
    const file = get(info, "file", {});
    setImageUrl("")
    if (file) {
      getBase64(file, (imageUrl: any) => {
        setLoading(true);

        putToS3(imageUrl)
          .then((imgUrl: string) => {
            setLoading(false);
            setImageUrl(imgUrl);
          })
          .catch((error: any) => {
            setLoading(false);
            console.error(error);
          });
      });
    }
  }

  function handleDelete() {
    confirm(() => setImageUrl(""), "Delete");
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  useEffect(() => {
    if (!!avatar) {
      setImageUrl(avatar)
    }
  }, [avatar, setImageUrl])

  const isDesktop = useMediaQuery({
    query: "(min-width: 992px)",
  });

  const status = (status: any) => {

    if (status === "bronze") {
      return (<span style={{ color: "#D2B48C" }}>Bronze</span>)
    } else if (status === "silver") {
      return (<span style={{ color: "#C0C0C0" }}>Silver</span>)
    } else if (status === "gold") {
      return (<span style={{ color: "#FFD700" }}>Gold</span>)
    } else if (status === "diamond") {
      return (<span style={{ color: "#7851A9" }}>Diamond</span>)
    }

  }

  return (
    <>
      <ProfileLayout>
        <Row>
          <Col xs={24} lg={16} xl={12}>
            <Row gutter={[20, 20]}>
              <Col span={24}>
                {

                  isDesktop ?

                    <div>
                      <h2 className={styled.accountName}>{nickname}</h2>
                      {player_status ? <h3>{status(player_status)} Player</h3> : <></>}
                      {sponsor_status ? <h3>{status(sponsor_status)} Sponsor</h3> : <></>}
                      {giver_status ? <h3>{status(giver_status)} Producer</h3> : <></>}
                    </div> : <></>
                }


              </Col>
              <Col span={24}>
                <DetailsProfile />
              </Col>
              <Col span={24} className={styled.editButton}>
                {!!userId && <Form
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  layout="vertical"
                  onFinish={editProfile}
                  onFinishFailed={onFinishFailed}
                  disabled={!isOpenChange}
                  initialValues={{
                    id: userId,
                    first_name: firstName,
                    last_name: lastName,
                    nickname,
                    email,
                    gender,
                    phone,
                    avatar
                  }}
                >

                  <Form.Item
                    label={"Avatar"}
                  >
                    <Row gutter={[20, 20]}>
                      {isOpenChange && <Col span={24}>
                        <Switch
                          onChange={(e) => setIsFileMode(e)}
                          checkedChildren="File"
                          unCheckedChildren="Link"
                          defaultChecked
                          size={"default"}
                        />
                      </Col>}
                      <Col span={24}>
                        {
                          isFileMode ? (
                            <>
                              <Upload
                                listType="picture-card"
                                showUploadList={false}
                                customRequest={customRequest}
                                className={styled.upload}
                              >
                                {
                                  imageUrl
                                    ? <img src={imageUrl} alt="avatar" style={{ height: "100%", width: 110, borderRadius: "50%", objectFit: "cover", left: 0, }} />
                                    : uploadButton
                                }
                              </Upload>
                              {imageUrl && <Button className={styled.avatarDelete} style={{ position: "absolute", height: 35, width: 35, left: 0, padding: "0", margin: 0, opacity: 1, borderRadius: "50%" }} onClick={handleDelete}><DeleteOutlined style={{ padding: 0, margin: "0 auto", }} size={10} rev={"d"} /></Button>}
                            </>
                          ) : (
                            <>
                              <Input onChange={e => setImageUrl(e.target.value)} value={imageUrl} />
                              {
                                <img src={imageUrl} alt="avatar" width={110} height={110} className={styled.avatar}/>
                              }
                            </>
                          )
                        }
                      </Col>
                    </Row>
                  </Form.Item>

                  <Form.Item
                    label="Nickname"
                    required
                    name={"nickname"}
                    rules={[{ required: true, message: "type nickname" }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Email"
                    required
                    name={"email"}
                    rules={[
                      {
                        required: true,
                        type: "email",
                        message: "type name",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  {!!isOpenChange && (
                    <Form.Item>
                      <Row gutter={[20, 20]} justify={"center"}>
                        <Col>
                          <Button type="primary" htmlType="submit" disabled={loading}>
                            Save
                          </Button>
                        </Col>
                        <Col>
                          <Button type="primary" onClick={() => setIsOpenChange(false)} disabled={loading}>
                            Close
                          </Button>
                        </Col>
                      </Row>
                    </Form.Item>
                  )}

                </Form>}
                {
                  !isOpenChange && (
                    <Row justify={"center"}>
                      <Button onClick={() => setIsOpenChange(true)} type={"default"}>
                        Edit
                      </Button>
                    </Row>
                  )
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </ProfileLayout>
    </>
  );
};

export { ProfileSettings };

const defaultType = "delete";

function useConfirm() {

  const confirmTypse: any = {
    delete: {
      title: "Want to delete an image?",
      okText: "Delete",
      content: ""
    },
    action: {},
  };


  const confirm = (onSuccess: () => void, content?: any, type?: string) => (
    Modal.confirm({
      title: confirmTypse[type ?? defaultType]["title"],
      // icon: <ExclamationCircleOutlined />,
      content: content ?? confirmTypse[type ?? defaultType]["content"],
      okText: confirmTypse[type ?? defaultType]["okText"],
      cancelText: "Close",
      onOk() {
        onSuccess?.();
        return;
      }
    })
  );

  return confirm;
}