import { useDidUpdate, useTimeout } from '@mantine/hooks';
import {
    IconPlayerPauseFilled,
    IconPlayerPlayFilled,
    IconVolume,
    IconVolume2
} from '@tabler/icons-react';
import { useRef, useState } from 'react';
import { MotionEffect } from './MotionEffect';
import { PlaybackEffect } from './PlaybackEffect';
import { VolumeEffect } from './VolumeEffect';

type EffectsOverlayProps = {
    playing: boolean;
    volume: number;
    seek: number;
}

export const EffectsOverlay = (props: EffectsOverlayProps) => {
    const { playing, volume, seek } = props;
    
    const volRef = useRef(volume)
    const seekRef = useRef(seek)
    
    const [affected, setAffected] = useState('')
    const { start, clear } = useTimeout(() => setAffected(''), 500);
    
    const VolumeIcon = volRef.current > volume ? IconVolume2 : IconVolume
    
    useDidUpdate(() => {
        !affected && setAffected('playback')
        start()
        return () => {
            clear()
        }
    }, [playing])
    
    useDidUpdate(() => {
        !affected && setAffected('volume')
        start()
        return () => {
            clear()
            volRef.current = volume
        }
    }, [volume])
    
    useDidUpdate(() => {
        !affected && setAffected('motion')
        start()
        return () => {
            clear()
            seekRef.current = seek
        }
    }, [seek])
    
    return (
        <>
            <PlaybackEffect
                affected={ !playing || affected === 'playback' }
                PlaybackIcon={ IconPlayerPlayFilled }
            />
            <VolumeEffect
                affected={ affected === 'volume' }
                volume={ volume }
                VolumeIcon={ VolumeIcon }
            />
            <MotionEffect
                affected={ affected === 'motion' }
                direction={ seekRef.current > seek ? 'left' : 'right' }
            />
        </>
    )
}